import React, { useState, useEffect } from 'react';
import { Avatar, AvatarImage, AvatarFallback } from "./ui/avatar";
import { Card, CardContent } from "./ui/card";
import { Button } from "./ui/button";
import AddBrand from './AddBrand';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "./ui/dialog";
import BrandStatistics from './BrandStatistics';
import TonWallet from './TonWallet';
import { TonConnectButton, useTonAddress, useTonWallet } from '@tonconnect/ui-react';
import ShippingAddress from './ShippingAddress';
import ProfileSettings from './ProfileSettings';
import BoringAvatar from 'boring-avatars';
import { Camera, RotateCcw } from 'lucide-react';

// Создаем основной компонент
const UserProfile = ({ user: initialUser, onProfileUpdate }) => {
  const [user, setUser] = useState(initialUser?.user || initialUser); // Исправляем здесь
  const [showAddBrandForm, setShowAddBrandForm] = useState(false);
  const [userBrands, setUserBrands] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deletingBrandId, setDeletingBrandId] = useState(null);
  const [editingBrandId, setEditingBrandId] = useState(null);
  const [error, setError] = useState(null);
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [levelProgress, setLevelProgress] = useState(0);
  const [isAvatarUploadOpen, setIsAvatarUploadOpen] = useState(false);

  // Получаем данные кошелька
  const address = useTonAddress();
  const wallet = useTonWallet();
  const walletAddress = useTonAddress();

const colors = ["#c5ff89", "#b8fcff", "#63526e", "#5db5ff", "#fdb9ff"];

  useEffect(() => {
    const fetchUserBrands = async () => {
      try {
        setError(null);
        const response = await axios.get(`/api/brands/user/${user._id}`);
        if (response.data && Array.isArray(response.data)) {
          setUserBrands(response.data);
        } else if (response.data && typeof response.data === 'object') {
          const brandsArray = Object.values(response.data).find(Array.isArray);
          if (brandsArray) {
            setUserBrands(brandsArray);
          } else {
            throw new Error('Unable to find brands array in response');
          }
        } else {
          throw new Error('Invalid response format');
        }
      } catch (error) {
        console.error('Error fetching user brands:', error);
        setError(error.message);
        setUserBrands([]);
      }
    };

    if (user && user._id) {
      fetchUserBrands();
    }
  }, [user]);

  useEffect(() => {
    const calculateLevelProgress = () => {
      const battles = user?.battles || 0;
      const currentLevel = user?.level || 1;
      const battlesForCurrentLevel = currentLevel === 1 ? 0 : 1000 + (currentLevel - 2) * 200;
      const battlesForNextLevel = currentLevel === 1 ? 1000 : 1000 + (currentLevel - 1) * 200;
      const battlesInCurrentLevel = battles - battlesForCurrentLevel;
      const progress = Math.min(100, Math.max(0, (battlesInCurrentLevel / (battlesForNextLevel - battlesForCurrentLevel)) * 100));
      setLevelProgress(progress);
    };

    calculateLevelProgress();
  }, [user]);

  const handleAddBrand = async (brandData) => {
    try {
      const response = await axios.post('/api/brands/user', brandData);
      if (response.data.brand) {
        setUserBrands(prevBrands => [...prevBrands, response.data.brand]);
        setShowAddBrandForm(false);
        toast.success('Brand added successfully!');
      }
    } catch (error) {
      console.error('Error adding brand:', error);
      toast.error(error.response?.data?.message || 'Error adding brand');
    }
  };

  const handleDeleteBrand = (brandId) => {
    setDeletingBrandId(brandId);
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    if (deletingBrandId) {
      try {
        await axios.delete(`/api/brands/${deletingBrandId}`);
        setUserBrands(prevBrands => prevBrands.filter(brand => brand.id !== deletingBrandId));
        toast.success('Brand deleted successfully!');
      } catch (error) {
        toast.error('Error deleting brand');
      }
      setIsDeleteDialogOpen(false);
      setDeletingBrandId(null);
    }
  };

  const handleEditBrand = async (brandId, updatedBrandData) => {
    if (!updatedBrandData) {
      setEditingBrandId(brandId);
      return;
    }

    try {
      const response = await axios.put(`/api/brands/${brandId}`, updatedBrandData);
      if (response.data) {
        setUserBrands(prevBrands =>
          prevBrands.map(brand =>
            brand.id === brandId ? { ...brand, ...response.data } : brand
          )
        );
        setEditingBrandId(null);
        toast.success('Brand updated successfully!');
      }
    } catch (error) {
      toast.error('Error updating brand');
    }
  };

  const handleShowStatistics = (brandId) => {
    setSelectedBrandId(prevId => prevId === brandId ? null : brandId);
  };

  const handleAddBrandClick = () => {
    try {
      // Используем существующие данные пользователя из props
      const userData = user; // user уже есть в компоненте как props

      const missingData = [];

      // Проверка профиля
      if (!userData.displayName || !userData.email || !userData.isEmailVerified) {
        missingData.push('complete profile');
      }

      // Проверка кошелька
      if (!userData.tonWallet || !userData.tonWallet.address) {
        missingData.push('connect TON wallet');
      }

      // Проверка адреса доставки
      if (!userData.defaultShipping || !userData.defaultShipping.fullName ||
        !userData.defaultShipping.phone || !userData.defaultShipping.country ||
        !userData.defaultShipping.city || !userData.defaultShipping.address ||
        !userData.defaultShipping.postalCode) {
        missingData.push('add shipping address');
      }

      // Если есть отсутствующие данные
      if (missingData.length > 0) {
        const missingDataText = missingData.join(', ');
        toast.error(`Please ${missingDataText} in your profile before adding a brand`);
        const profileSection = document.querySelector('#profile-settings');
        if (profileSection) {
          profileSection.scrollIntoView({ behavior: 'smooth' });
        }
        return;
      }

      // Если все данные заполнены, показываем форму
      setShowAddBrandForm(true);

    } catch (error) {
      console.error('Error checking user data:', error);
      toast.error('Error checking profile data. Please try again.');
    }
  };

  // Добавим функцию для обработки загрузки аватара
const handleAvatarUpload = async (event) => {
  const file = event.target.files[0];
  if (!file) return;

  try {
    const formData = new FormData();
    formData.append('avatar', file);

    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('Authentication required');
      return;
    }

    console.log('Uploading avatar...', {
      fileSize: file.size,
      fileType: file.type
    });

    const response = await axios.post(`/api/users/${user._id}/avatar`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        'X-Telegram-Init-Data': localStorage.getItem('telegramInitData')
      }
    });

    console.log('Upload response:', response.data);

    if (response.data.success) {
      const newAvatarUrl = response.data.avatarUrl;
      console.log('New avatar URL:', newAvatarUrl);

      // Обновляем локальное состояние с новым URL
      setUser(prev => ({
        ...prev,
        avatar: newAvatarUrl
      }));

      // Если есть функция обновления профиля, вызываем её
      if (typeof onProfileUpdate === 'function') {
        onProfileUpdate({
          ...user,
          avatar: newAvatarUrl
        });
      }

      // Создаем событие для обновления UI во всем приложении
      window.dispatchEvent(new CustomEvent('userUpdate', {
        detail: {
          userId: user._id,
          avatar: newAvatarUrl
        }
      }));

      toast.success('Avatar updated successfully');
    } else {
      throw new Error(response.data.message || 'Failed to update avatar');
    }
  } catch (error) {
    console.error('Error uploading avatar:', error);
    toast.error(error.message || 'Failed to upload avatar');
  } finally {
    setIsAvatarUploadOpen(false);
  }
};

  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase();
  };

  useEffect(() => {
    // Отладочный вывод
    console.log('User Profile - Full user object:', user);
    console.log('User Profile - DefaultShipping:', user?.defaultShipping);
  }, [user]);

  useEffect(() => {
    if (initialUser?.user) {
      setUser(initialUser.user);
    } else if (initialUser) {
      setUser(initialUser);
    }
  }, [initialUser]);

  console.log('Current user state:', user); // Отладочный лог

  const StatCard = ({ title, value }) => (
    <Card className="bg-[#1a1a1a] rounded-[20px]">
      <CardContent className="p-4">
        <h3 className="text-sm font-semibold text-[#e0ff89]">{title}</h3>
        <p className="text-xl font-bold text-white">{value}</p>
      </CardContent>
    </Card>
  );

  return (
    <div className="bg-black text-white min-h-screen flex flex-col">
      <div className="flex-1 overflow-y-auto p-6">
        <div className="max-w-3xl mx-auto pb-20">
          {/* Профиль пользователя */}
          <div className="flex flex-col justify-between p-4 bg-cover bg-center relative rounded-[30px] overflow-hidden mb-8" style={{
            backgroundImage: `url(${user.backgroundImage || '/default-background.jpg'})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}>
            <div className="absolute inset-0 bg-black opacity-60"></div>
<div className="relative z-10 flex justify-between items-start w-full mb-4">
  <div className="flex items-center space-x-4">
    <div className="relative group !overflow-visible">
      {/* Основной контейнер аватара */}
      <div className="relative w-16 h-16">
        <Avatar className="w-full h-full !overflow-visible">
          {user?.avatar ? (
            <>
              <AvatarImage
                src={user.avatar}
                alt="User Avatar"
                className="object-cover w-full h-full"
                onError={(e) => {
                  console.error('Error loading avatar:', e);
                  e.target.style.display = 'none';
                }}
              />
            </>
          ) : (
            <div className="w-full h-full">
              <BoringAvatar
                size={64}
                name={user?.displayName || user?.firstName || user?.username || 'User'}
                variant="beam" 
                colors={["#6c788e", "#a6aec1", "#cfd5e1", "#ededf2", "#fcfdff"]}
              />
            </div>
          )}
          <AvatarFallback>
            <div className="w-full h-full">
              <BoringAvatar
                size={64} 
                name={user?.displayName || user?.firstName || user?.username || 'User'}
                variant="beam"
                colors={["#6c788e", "#a6aec1", "#cfd5e1", "#ededf2", "#fcfdff"]}
              />
            </div>
          </AvatarFallback>
        </Avatar>

        {/* Бейдж перемещен на уровень выше */}
{user.profileCompletionReward?.claimed && (
  <div className="absolute -top-2 -right-2 w-7 h-7 bg-[#e0ff89] rounded-full flex items-center justify-center transform rotate-12 z-[100] drop-shadow-[0_0px_10px_rgba(0,0,0,0.5)] border-2 border-black/20">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="transform -rotate-12 drop-shadow-sm"
    >
      <path d="M18 12.5V10a2 2 0 0 0-2-2v0a2 2 0 0 0-2 2v1.4" />
      <path d="M14 11V9a2 2 0 1 0-4 0v2" />
      <path d="M10 10.5V5a2 2 0 1 0-4 0v9" />
      <path d="m7 15-1.76-1.76a2 2 0 0 0-2.83 2.82l3.6 3.6C7.5 21.14 9.2 22 12 22h2a8 8 0 0 0 8-8V7a2 2 0 1 0-4 0v5" />
    </svg>
  </div>
)}

        {/* Оверлей для загрузки фото */}
        <div 
          className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer"
          onClick={() => setIsAvatarUploadOpen(true)}
        >
          <div className="w-8 h-8 bg-black bg-opacity-50 rounded-full flex items-center justify-center">
            <Camera className="w-4 h-4 text-white" />
          </div>
        </div>
      </div>
    </div>

    <div className="flex-grow">
      <h1 className="text-2xl font-bold text-white">
        {user.displayName || user.firstName || user.username || 'User'}
      </h1>
      <p className="text-sm text-gray-300 mt-1">{user.rank || 'Kettle'}</p>
      <p className="text-xl font-bold text-white flex items-center mt-1">
        {user.coins !== undefined ? (
          <>
            {Math.floor(user.coins)}
            <img src="/images/general/coin.svg" alt="coin" className="w-6 h-6 ml-1" />
          </>
        ) : '0'}
      </p>
    </div>
  </div>
</div>
            <div className="relative z-10 w-full bg-black rounded-full h-3 mt-2">
              <div className="absolute left-0 top-0 bottom-0 bg-[#e0ff89] rounded-full transition-all duration-300 ease-in-out" style={{ width: `${levelProgress}%` }}></div>
              <div className="absolute inset-0 flex items-center justify-center text-xs font-medium">
                <span className="text-white mix-blend-difference">Level {user.level}: {Math.floor(levelProgress)}%</span>
              </div>
            </div>
          </div>

{/* Статистика */}
<div className="grid grid-cols-2 gap-4 mb-8">
  <StatCard title="Referral Users" value={user.referrals?.length || 0} />
  <StatCard title="Total Battles" value={user.battles || 0} />
  
  {!user.profileCompletionReward?.claimed && (
    <div className="col-span-2">
      <Card className="bg-gradient-to-r from-[#e0ff89] to-[#b8fcff] text-black rounded-[20px]">
        <CardContent className="p-4">
          <div className="flex justify-between items-center">
            <div>
              <h3 className="text-lg font-bold">Complete Your Profile</h3>
              <p className="text-sm">Fill out your profile to earn a reward!</p>
            </div>
            <div className="flex items-center bg-black text-[#e0ff89] px-3 py-1 rounded-full">
              <span className="font-bold mr-1">200</span>
              <img src="/images/general/coin.svg" alt="coin" className="w-4 h-4" />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  )}
</div>

          {/* Настройки профиля */}
          <div className="mb-8" id="profile-settings">
            <ProfileSettings
              user={user}
              onProfileUpdate={(updatedUser) => {
                setUser(prev => ({
                  ...prev,
                  ...updatedUser
                }));
              }}
            />
          </div>

          {/* Кошелек TON */}
          <div className="mb-8">
            <TonWallet userId={user._id} />
          </div>

          {/* Адрес доставки */}
          <div className="mb-8">
            <ShippingAddress
              userId={user?._id}
              defaultAddress={user?.defaultShipping}
              userProfile={user}
              onAddressUpdate={(address) => {
                console.log('Updating shipping address:', address); // Отладочный лог
                setUser(prev => ({
                  ...prev,
                  defaultShipping: address
                }));
              }}
            />
          </div>

          {/* Секция брендов */}
          <div className="mb-8">
            <h3 className="text-2xl font-bold mb-4">My Brands</h3>
            {error ? (
              <p className="text-red-500">{error}</p>
            ) : userBrands.length > 0 ? (
              <div className="space-y-4">
                {userBrands.map((brand) => (
                  <div key={brand.id}>
                    <div className="bg-[#1a1a1a] rounded-[20px] p-4 flex items-center justify-between">
                      <div className="flex flex-col items-center mr-4">
                        <div className="w-16 h-16 rounded-full overflow-hidden mb-2">
                          <img
                            src={brand.logo || '/default-brand-logo.png'}
                            alt={brand.name}
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <h4 className="font-semibold text-sm text-white text-center">{brand.name}</h4>
                      </div>
                      <div className="space-y-2 flex-grow">
                        <div className="flex space-x-2 mb-2">
                          <Button
                            className="flex-1 bg-white text-black hover:bg-gray-200 rounded-[30px] py-1 px-4"
                            disabled={brand.status === 'pending'}
                            onClick={() => handleEditBrand(brand.id)}
                          >
                            Edit
                          </Button>
                          <Button
                            className="flex-1 bg-white text-black hover:bg-gray-200 rounded-[30px] py-1 px-4"
                            disabled={brand.status === 'pending'}
                            onClick={() => handleDeleteBrand(brand.id)}
                          >
                            Delete
                          </Button>
                        </div>
                        <Button
                          className={`w-full ${selectedBrandId === brand.id ? 'bg-[#d1ef7a]' : 'bg-[#e0ff89]'} text-black hover:bg-[#d1ef7a] rounded-[30px] py-1 px-4`}
                          disabled={brand.status === 'pending'}
                          onClick={() => handleShowStatistics(brand.id)}
                        >
                          {selectedBrandId === brand.id ? 'Hide Statistics' : 'Statistics'}
                        </Button>
                        <p className="text-sm text-gray-400 text-center mt-1">
                          {brand.status === 'pending' ? 'Pending Approval' : brand.status || 'Status: Unknown'}
                        </p>
                      </div>
                    </div>
                    {selectedBrandId === brand.id && (
                      <BrandStatistics brandId={brand.id} />
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-400">No brands added yet.</p>
            )}
            <Button
              onClick={handleAddBrandClick}
              className="mt-4 w-full bg-[#e0ff89] text-black px-4 py-1 h-[34px] hover:bg-[#d1ef7a] rounded-[30px] text-sm"
            >
              Add Brand
            </Button>
          </div>

          {/* Форма добавления бренда */}
          {showAddBrandForm && (
            <AddBrand
              onSave={handleAddBrand}
              onCancel={() => setShowAddBrandForm(false)}
              connectedWalletAddress={walletAddress}
            />
          )}
        </div>
      </div>

      {/* Диалог подтверждения удаления */}
      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent className="bg-black text-white rounded-[30px] p-6">
          <DialogHeader>
            <DialogTitle className="text-xl font-bold">Confirm Deletion</DialogTitle>
          </DialogHeader>
          <p className="text-[#d3d3d3]">
            Are you sure you want to delete this brand? This action cannot be undone.
          </p>
          <div className="mt-6 flex flex-row justify-between items-center">
            <Button
              onClick={confirmDelete}
              className="w-[48%] bg-red-500 text-white hover:bg-red-600 rounded-[30px] py-2 px-4"
            >
              Delete
            </Button>
            <Button
              onClick={() => setIsDeleteDialogOpen(false)}
              className="w-[48%] bg-white text-black hover:bg-gray-200 rounded-[30px] py-2 px-4"
            >
              Cancel
            </Button>
          </div>
        </DialogContent>
      </Dialog>
<Dialog open={isAvatarUploadOpen} onOpenChange={setIsAvatarUploadOpen}>
  <DialogContent className="bg-black text-white rounded-[30px] p-4 z-[999]">
    <DialogHeader className="mb-2">
      <DialogTitle className="text-xl font-bold">Update Profile Photo</DialogTitle>
      <DialogDescription className="text-gray-400">
        Choose a new photo for your profile
        <br />
        <span className="text-sm">Max 300x300px, 100kb, jpeg/webP</span>
      </DialogDescription>
    </DialogHeader>
    
    <div className="flex flex-col items-center">
      <div className="w-32 h-32 mb-4">
        {user?.avatar ? (
          <Avatar className="w-32 h-32">
            <AvatarImage src={user.avatar} className="object-cover" />
            <AvatarFallback>
              <BoringAvatar
                size={128}
                name={user?.displayName || user?.firstName || user?.username || 'User'}
                variant="beam"
                colors={["#6c788e", "#a6aec1", "#cfd5e1", "#ededf2", "#fcfdff"]}
              />
            </AvatarFallback>
          </Avatar>
        ) : (
          <BoringAvatar
            size={128}
            name={user?.displayName || user?.firstName || user?.username || 'User'}
            variant="beam"
            colors={["#6c788e", "#a6aec1", "#cfd5e1", "#ededf2", "#fcfdff"]}
          />
        )}
      </div>

      <div className="flex gap-2 w-full">
        <label className="flex-1">
          <div className="bg-[#e0ff89] text-black px-4 py-2 rounded-[30px] text-center cursor-pointer hover:bg-[#d1ef7a] transition-colors">
            Choose Photo
          </div>
          <input
            type="file"
            className="hidden"
            accept="image/jpeg,image/webp"
            onChange={handleAvatarUpload}
          />
        </label>
        {user?.avatar && (
          <button
            className="flex-1 bg-white text-black border border-white hover:bg-zinc-900 px-4 py-2 rounded-[30px] transition-colors"
            onClick={async () => {
              try {
                const response = await axios.post(
                  `/api/users/${user._id}/avatar/reset`,
                  {},
                  {
                    headers: {
                      'Authorization': `Bearer ${localStorage.getItem('token')}`,
                      'X-Telegram-Init-Data': localStorage.getItem('telegramInitData')
                    }
                  }
                );
                
                if (response.data.success) {
                  const updatedUser = {
                    ...user,
                    avatar: null,
                    defaultAvatar: null
                  };
                  setUser(updatedUser);
                  
                  if (typeof onProfileUpdate === 'function') {
                    onProfileUpdate(updatedUser);
                  }
                  
                  // Отправляем событие обновления для всех компонентов
                  window.dispatchEvent(new CustomEvent('userUpdate', {
                    detail: {
                      userId: user._id,
                      avatar: null,
                      defaultAvatar: null
                    }
                  }));
                  
                  setIsAvatarUploadOpen(false);
                  toast.success('Avatar reset successfully');
                }
              } catch (error) {
                console.error('Error resetting avatar:', error);
                toast.error('Failed to reset avatar');
              }
            }}
          >
            Reset to Default
          </button>
        )}
      </div>
    </div>
  </DialogContent>
</Dialog>
    </div>
  );
};

export default UserProfile;