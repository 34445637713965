import React, { useEffect, useRef } from 'react';

const AnimatedBackground = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        let animationFrameId;
        let frameCount = 0;

        const resizeCanvas = () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
        };

        window.addEventListener('resize', resizeCanvas);
        resizeCanvas();

        const columns = Math.floor(canvas.width / 20);
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
            + '你好世界こんにちは안녕하세요'
            + '春夏秋冬金木水火土日月星'
            + 'ありがとうございます감사합니다';
        const drops = Array(columns).fill(null).map(() => -Math.floor(Math.random() * canvas.height));

        ctx.fillStyle = 'rgba(0, 0, 0, 1)';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        const animate = () => {
            frameCount++;

            if (frameCount % 5 === 0) {
                ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
                ctx.fillRect(0, 0, canvas.width, canvas.height);

                ctx.fillStyle = 'rgba(90, 90, 90, 0.75)';
                ctx.font = '15px monospace';

                for (let i = 0; i < drops.length; i++) {
                    const text = chars[Math.floor(Math.random() * chars.length)];
                    ctx.fillText(text, i * 20, drops[i] * 20);

                    if (drops[i] * 20 > canvas.height && Math.random() > 0.99) {
                        drops[i] = 0;
                    }
                    drops[i]++;
                }
            }

            animationFrameId = requestAnimationFrame(animate);
        };

        animate();

        return () => {
            cancelAnimationFrame(animationFrameId);
            window.removeEventListener('resize', resizeCanvas);
        };
    }, []);

    return <canvas ref={canvasRef} className="fixed top-0 left-0 w-full h-full" style={{ zIndex: -1 }} />;
};

export default AnimatedBackground;