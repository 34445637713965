import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Card, CardContent } from "../ui/card";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogFooter } from "../ui/dialog";
import { Label } from "../ui/label";
import { Pencil, Trash2, Upload, ChevronLeft, ChevronRight, X } from "lucide-react";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useDropzone } from 'react-dropzone';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { toast } from 'react-hot-toast';  // Добавьте эту строку

const ImageUpload = ({ onImageUpload }) => {
    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            onImageUpload(file);
        }
    }, [onImageUpload]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/*',
        multiple: false
    });

    return (
        <div
            {...getRootProps()}
            className={`border-2 border-dashed border-gray-300 rounded-lg p-6 text-center cursor-pointer
        ${isDragActive ? 'border-blue-500 bg-blue-50' : 'hover:border-gray-400'}`}
        >
            <input {...getInputProps()} />
            <Upload className="mx-auto mb-4 text-gray-400" size={48} />
            <p className="text-sm text-gray-500">
                {isDragActive ? 'Drop the image here' : 'Drag & drop an image here, or click to select one'}
            </p>
        </div>
    );
};

const TicketForm = ({ ticket, onSave, onCancel }) => {
    const [formData, setFormData] = useState(ticket || {
    name: '',
    description: '',
    prizes: [],
    sponsor: '',
    saleStartDate: '',
    saleEndDate: '',
    drawDate: '',
    status: 'upcoming',
    price: 0,
    link: '',
    totalTickets: -1  // Добавлено новое поле
});
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(ticket?.backgroundImage || '');

    const handlePrizeChange = (index, field, value) => {
        const newPrizes = [...formData.prizes];
        newPrizes[index] = { ...newPrizes[index], [field]: value };
        setFormData(prev => ({ ...prev, prizes: newPrizes }));
    };

    const addPrize = () => {
        setFormData(prev => ({
            ...prev,
            prizes: [...prev.prizes, { type: 'money', value: '', quantity: 1 }]
        }));
    };

    const removePrize = (index) => {
        setFormData(prev => ({
            ...prev,
            prizes: prev.prizes.filter((_, i) => i !== index)
        }));
    };

    useEffect(() => {
        if (ticket) {
            setFormData({
                ...ticket,
                saleStartDate: ticket.saleStartDate ? new Date(ticket.saleStartDate).toISOString().slice(0, 16) : '',
                saleEndDate: ticket.saleEndDate ? new Date(ticket.saleEndDate).toISOString().slice(0, 16) : '',
                drawDate: ticket.drawDate ? new Date(ticket.drawDate).toISOString().slice(0, 16) : '',
            });
        }
    }, [ticket]);

const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
        ...prev,
        [name]: ['price', 'winningTicketsCount', 'prizeMoney', 'prizePerTicketMoney', 'totalTickets'].includes(name)
            ? Number(value)
            : value
    }));
};

    const handleImageUpload = (file) => {
        setImageFile(file);

        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result);
            setFormData(prev => ({ ...prev, backgroundImage: reader.result }));
        };
        reader.readAsDataURL(file);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(formData, imageFile);
    };

    const inputClasses = "bg-black text-[#d3d3d3] border-[#282828] border-[3px] placeholder-[#d3d3d3]";

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <Label htmlFor="name" className="text-[#d3d3d3]">Name</Label>
                    <Input id="name" name="name" value={formData.name} onChange={handleChange} required className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="description" className="text-[#d3d3d3]">Description</Label>
                    <Input id="description" name="description" value={formData.description} onChange={handleChange} required className={inputClasses} />
                </div>
                <div className="col-span-2">
                    <Label className="text-[#d3d3d3]">Prizes</Label>
                    {formData.prizes.map((prize, index) => (
                        <div key={index} className="flex space-x-2 mt-2">
                            <Select
                                value={prize.type}
                                onValueChange={(value) => handlePrizeChange(index, 'type', value)}
                            >
                                <SelectTrigger className={inputClasses}>
                                    <SelectValue placeholder="Select prize type" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="money">Money</SelectItem>
                                    <SelectItem value="item">Item</SelectItem>
                                </SelectContent>
                            </Select>
                            <Input
                                placeholder={prize.type === 'money' ? 'Amount' : 'Item name'}
                                value={prize.value}
                                onChange={(e) => handlePrizeChange(index, 'value', e.target.value)}
                                className={inputClasses}
                            />
                            <Input
                                type="number"
                                placeholder="Quantity"
                                value={prize.quantity}
                                onChange={(e) => handlePrizeChange(index, 'quantity', parseInt(e.target.value))}
                                className={inputClasses}
                            />
                            <Button type="button" onClick={() => removePrize(index)} className="p-2 bg-red-500 text-white rounded-full">
                                <X size={16} />
                            </Button>
                        </div>
                    ))}
                    <Button type="button" onClick={addPrize} className="mt-2 bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-2 px-6">
                        Add Prize
                    </Button>
                </div>
                <div>
                    <Label htmlFor="price" className="text-[#d3d3d3]">Price (in coins)</Label>
                    <Input id="price" name="price" type="number" value={formData.price} onChange={handleChange} required className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="link" className="text-[#d3d3d3]">Link</Label>
                    <Input id="link" name="link" type="url" value={formData.link} onChange={handleChange} className={inputClasses} placeholder="https://example.com" />
                </div>
                <div>
                    <Label htmlFor="sponsor" className="text-[#d3d3d3]">Sponsor</Label>
                    <Input id="sponsor" name="sponsor" value={formData.sponsor} onChange={handleChange} className={inputClasses} />
                </div>
                <div>
    <Label htmlFor="totalTickets" className="text-[#d3d3d3]">Total Tickets (-1 for unlimited)</Label>
    <Input 
        id="totalTickets" 
        name="totalTickets" 
        type="number" 
        value={formData.totalTickets} 
        onChange={handleChange} 
        className={inputClasses}
    />
</div>
                <div>
                    <Label htmlFor="saleStartDate" className="text-[#d3d3d3]">Sale Start Date</Label>
                    <Input id="saleStartDate" name="saleStartDate" type="datetime-local" value={formData.saleStartDate} onChange={handleChange} required className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="saleEndDate" className="text-[#d3d3d3]">Sale End Date</Label>
                    <Input id="saleEndDate" name="saleEndDate" type="datetime-local" value={formData.saleEndDate} onChange={handleChange} required className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="drawDate" className="text-[#d3d3d3]">Draw Date</Label>
                    <Input id="drawDate" name="drawDate" type="datetime-local" value={formData.drawDate} onChange={handleChange} required className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="status" className="text-[#d3d3d3]">Status</Label>
                    <Select name="status" value={formData.status} onValueChange={(value) => setFormData(prev => ({ ...prev, status: value }))}>
                        <SelectTrigger className={inputClasses}>
                            <SelectValue placeholder="Select status" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="upcoming">Upcoming</SelectItem>
                            <SelectItem value="active">Active</SelectItem>
                            <SelectItem value="completed">Completed</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
            </div>
            <div>
                <Label className="text-[#d3d3d3]">Background Image</Label>
                <div className="flex flex-col space-y-2">
                    <ImageUpload onImageUpload={handleImageUpload} />
                </div>
                {imagePreview && (
                    <div className="relative mt-2">
                        <img src={imagePreview} alt="Background preview" className="h-32 w-full object-cover rounded" />
                        <Button
                            type="button"
                            onClick={() => {
                                setImagePreview('');
                                setFormData(prev => ({ ...prev, backgroundImage: '' }));
                            }}
                            className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full"
                        >
                            X
                        </Button>
                    </div>
                )}
            </div>
            <div className="flex justify-end space-x-2">
                <Button type="button" onClick={onCancel} variant="outline" className="bg-[#282828] text-white hover:bg-[#3a3a3a] transition-colors duration-200 rounded-[30px] py-2 px-6">
                    Cancel
                </Button>
                <Button type="submit" className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-2 px-6">
                    Save Ticket
                </Button>
            </div>
        </form>
    );
};

const TicketsManagement = () => {
    const [tickets, setTickets] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [editingTicket, setEditingTicket] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [ticketsPerPage, setTicketsPerPage] = useState(10);
    const [totalTickets, setTotalTickets] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [deletingTicketId, setDeletingTicketId] = useState(null);

    const fetchTickets = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`/api/tickets?page=${currentPage}&limit=${ticketsPerPage}`);
            console.log('Fetched tickets:', response.data);
            if (Array.isArray(response.data)) {
                setTickets(response.data);
                setTotalTickets(response.data.length);
            } else if (response.data && Array.isArray(response.data.tickets)) {
                setTickets(response.data.tickets);
                setTotalTickets(response.data.totalTickets || 0);
            } else {
                console.error('Invalid response format:', response.data);
                setTickets([]);
                setTotalTickets(0);
            }
        } catch (error) {
            console.error('Error fetching tickets:', error);
            setTickets([]);
            setTotalTickets(0);
        } finally {
            setIsLoading(false);
        }
    }, [currentPage, ticketsPerPage]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleTicketsPerPageChange = (value) => {
        setTicketsPerPage(Number(value));
        setCurrentPage(1);
    };

    useEffect(() => {
        fetchTickets();
    }, [fetchTickets]);

const handleSave = async (ticketData, imageFile) => {
    try {
        const formData = new FormData();
        Object.keys(ticketData).forEach(key => {
            if (key !== 'backgroundImage') {
                if (key === 'prizes') {
                    formData.append(key, JSON.stringify(ticketData[key]));
                } else if (key === 'saleStartDate' || key === 'saleEndDate' || key === 'drawDate') {
                    formData.append(key, new Date(ticketData[key]).toISOString());
                } else if (key === 'price' || key === 'totalTickets') {
                    formData.append(key, Number(ticketData[key]));
                } else {
                    formData.append(key, ticketData[key]);
                }
            }
        });

        if (imageFile) {
            formData.append('backgroundImage', imageFile);
        }

        console.log('Sending data:', Object.fromEntries(formData));

        let response;
        if (editingTicket) {
            response = await axios.put(`/api/tickets/${editingTicket._id}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
        } else {
            response = await axios.post('/api/tickets', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
        }
        console.log('Ticket saved:', response.data);
        fetchTickets();
        setIsDialogOpen(false);
        setEditingTicket(null);
        toast.success('Ticket saved successfully!');
    } catch (error) {
        console.error('Error saving ticket:', error);
        toast.error(`Error saving ticket: ${error.response?.data?.message || error.message}`);
    }
};

    const handleDelete = (ticketId) => {
        setDeletingTicketId(ticketId);
        setIsDeleteDialogOpen(true);
    };

    const confirmDelete = async () => {
        if (deletingTicketId) {
            try {
                await axios.delete(`/api/tickets/${deletingTicketId}`);
                fetchTickets();
            } catch (error) {
                console.error('Error deleting ticket:', error);
            }
            setIsDeleteDialogOpen(false);
            setDeletingTicketId(null);
        }
    };

    return (
        <Card className="bg-transparent">
            <CardContent className="p-4">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold text-[#eff0f2]">Tickets</h2>
                    <Button onClick={() => setIsDialogOpen(true)} className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4">Add New Ticket</Button>
                </div>
                <div className="grid grid-cols-2 gap-4 overflow-y-auto max-h-[calc(100vh-300px)]">
                    {isLoading ? (
                        <p className="text-[#d3d3d3] text-center">Loading tickets...</p>
                    ) : (
                        tickets.map((ticket) => (
                            <Card key={ticket._id} className="relative overflow-hidden rounded-lg shadow-lg bg-[#1c1f26]">
    <div className="relative z-10 p-6 bg-black bg-opacity-60 h-full flex flex-col justify-between">
        <div>
            <h3 className="text-2xl font-bold text-[#d3d3d3]">{ticket.name}</h3>
            <p className="text-sm text-[#a0a0a0] mt-1">{ticket.description}</p>
            <p className="text-sm text-[#e0ff89] mt-2">Sponsor: {ticket.sponsor}</p>
            <p className="text-sm text-[#d3d3d3] mt-2">Clicks: {ticket.clickCount || 0}</p>
                                        <div className="mt-2">
                                            {ticket.prizes.map((prize, index) => (
                                                <p key={index} className="text-sm text-[#d3d3d3]">
                                                    Prize {index + 1}: {prize.type === 'money' ? `${prize.value} coins` : prize.value} (Quantity: {prize.quantity})
                                                </p>
                                            ))}
                                        </div>
                                        <div className="mt-2">
                                            <p className="text-sm text-[#d3d3d3]">Total Winning Tickets: {ticket.prizes.reduce((sum, prize) => sum + prize.quantity, 0)}</p>
                                        </div>
                                        <div className="mt-2">
                                            <p className="text-sm text-[#d3d3d3]">Sale Start: {new Date(ticket.saleStartDate).toLocaleString()}</p>
                                            <p className="text-sm text-[#d3d3d3]">Sale End: {new Date(ticket.saleEndDate).toLocaleString()}</p>
                                            <p className="text-sm text-[#d3d3d3]">Draw Date: {new Date(ticket.drawDate).toLocaleString()}</p>
                                        </div>
                                        <p className={`text-sm mt-2 ${ticket.status === 'active' ? 'text-[#e0ff89]' : ticket.status === 'completed' ? 'text-red-500' : 'text-yellow-500'}`}>
                                            Status: {ticket.status}
                                        </p>
                                    </div>
                                    <div className="mt-4 flex justify-end space-x-2">
                                        <Button onClick={() => { setEditingTicket(ticket); setIsDialogOpen(true); }} className="p-2 bg-black text-white rounded-[30px]">
                                            <Pencil size={16} />
                                        </Button>
                                        <Button onClick={() => handleDelete(ticket._id)} className="p-2 bg-black text-white rounded-[30px]">
                                            <Trash2 size={16} />
                                        </Button>
                                    </div>
                                </div>
                                {ticket.backgroundImage && (
                                    <img
                                        src={ticket.backgroundImage}
                                        alt={ticket.name}
                                        className="absolute inset-0 w-full h-full object-cover opacity-30"
                                    />
                                )}
                            </Card>
                        ))
                    )}
                </div>
                <div className="mt-4 flex justify-between items-center">
                    <div className="flex items-center space-x-2">
                        <span className="text-[#d3d3d3]">Tickets per page:</span>
                        <Select value={ticketsPerPage.toString()} onValueChange={handleTicketsPerPageChange}>
                            <SelectTrigger className="w-[70px] bg-black text-[#d3d3d3]">
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent className="bg-black text-[#d3d3d3]">
                                {[10, 20, 50, 100].map(value => (
                                    <SelectItem key={value} value={value.toString()}>{value}</SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <div className="flex items-center space-x-2">
                        <span className="text-[#d3d3d3]">Total Tickets: {totalTickets} </span>
                        <Button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-full p-2"
                        >
                            <ChevronLeft size={16} />
                        </Button>
                        <span className="text-[#d3d3d3]">Page {currentPage} of {Math.ceil(totalTickets / ticketsPerPage)}</span>
                        <Button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === Math.ceil(totalTickets / ticketsPerPage)}
                            className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-full p-2"
                        >
                            <ChevronRight size={16} />
                        </Button>
                    </div>
                </div>
            </CardContent>
            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                <DialogContent className="max-h-[80vh] overflow-y-auto bg-[#15171c]">
                    <DialogHeader>
                        <DialogTitle className="text-[#eff0f2]">{editingTicket ? 'Edit Ticket' : 'Add New Ticket'}</DialogTitle>
                        <DialogDescription className="text-[#d3d3d3]">
                            {editingTicket ? 'Edit the details of the ticket.' : 'Enter the details of the new ticket.'}
                        </DialogDescription>
                    </DialogHeader>
                    <TicketForm
                        ticket={editingTicket}
                        onSave={handleSave}
                        onCancel={() => setIsDialogOpen(false)}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
                <DialogContent className="bg-black text-white rounded-[30px] p-6">
                    <DialogHeader>
                        <DialogTitle className="text-xl font-bold">Confirm Deletion</DialogTitle>
                        <DialogDescription className="text-[#d3d3d3]">
                            Are you sure you want to delete this ticket? This action cannot be undone.
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter className="mt-6 flex justify-end space-x-4">
                        <Button
                            onClick={() => setIsDeleteDialogOpen(false)}
                            className="bg-white text-black hover:bg-gray-200 rounded-[30px] py-1 px-4"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={confirmDelete}
                            className="bg-red-500 text-white hover:bg-red-600 rounded-[30px] py-1 px-4"
                        >
                            Delete
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </Card>
    );
};

export default TicketsManagement;