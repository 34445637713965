// Массив стран с телефонными кодами и регионами
export const COUNTRIES = [
    { value: 'AF', label: 'Afghanistan', icon: '🇦🇫', region: 'Asia', phoneCode: '+93' },
    { value: 'AL', label: 'Albania', icon: '🇦🇱', region: 'Europe', phoneCode: '+355' },
    { value: 'DZ', label: 'Algeria', icon: '🇩🇿', region: 'Africa', phoneCode: '+213' },
    { value: 'AS', label: 'American Samoa', icon: '🇦🇸', region: 'Oceania', phoneCode: '+1684' },
    { value: 'AD', label: 'Andorra', icon: '🇦🇩', region: 'Europe', phoneCode: '+376' },
    { value: 'AO', label: 'Angola', icon: '🇦🇴', region: 'Africa', phoneCode: '+244' },
    { value: 'AI', label: 'Anguilla', icon: '🇦🇮', region: 'North America', phoneCode: '+1264' },
    { value: 'AQ', label: 'Antarctica', icon: '🇦🇶', region: 'Antarctica', phoneCode: '+672' },
    { value: 'AG', label: 'Antigua and Barbuda', icon: '🇦🇬', region: 'North America', phoneCode: '+1268' },
    { value: 'AR', label: 'Argentina', icon: '🇦🇷', region: 'South America', phoneCode: '+54' },
    { value: 'AM', label: 'Armenia', icon: '🇦🇲', region: 'Asia', phoneCode: '+374' },
    { value: 'AW', label: 'Aruba', icon: '🇦🇼', region: 'North America', phoneCode: '+297' },
    { value: 'AU', label: 'Australia', icon: '🇦🇺', region: 'Oceania', phoneCode: '+61' },
    { value: 'AT', label: 'Austria', icon: '🇦🇹', region: 'Europe', phoneCode: '+43' },
    { value: 'AZ', label: 'Azerbaijan', icon: '🇦🇿', region: 'Asia', phoneCode: '+994' },
    { value: 'BS', label: 'Bahamas', icon: '🇧🇸', region: 'North America', phoneCode: '+1242' },
    { value: 'BH', label: 'Bahrain', icon: '🇧🇭', region: 'Asia', phoneCode: '+973' },
    { value: 'BD', label: 'Bangladesh', icon: '🇧🇩', region: 'Asia', phoneCode: '+880' },
    { value: 'BB', label: 'Barbados', icon: '🇧🇧', region: 'North America', phoneCode: '+1246' },
    { value: 'BY', label: 'Belarus', icon: '🇧🇾', region: 'Europe', phoneCode: '+375' },
    { value: 'BE', label: 'Belgium', icon: '🇧🇪', region: 'Europe', phoneCode: '+32' },
    { value: 'BZ', label: 'Belize', icon: '🇧🇿', region: 'North America', phoneCode: '+501' },
    { value: 'BJ', label: 'Benin', icon: '🇧🇯', region: 'Africa', phoneCode: '+229' },
    { value: 'BM', label: 'Bermuda', icon: '🇧🇲', region: 'North America', phoneCode: '+1441' },
    { value: 'BT', label: 'Bhutan', icon: '🇧🇹', region: 'Asia', phoneCode: '+975' },
    { value: 'BO', label: 'Bolivia', icon: '🇧🇴', region: 'South America', phoneCode: '+591' },
    { value: 'BA', label: 'Bosnia and Herzegovina', icon: '🇧🇦', region: 'Europe', phoneCode: '+387' },
    { value: 'BW', label: 'Botswana', icon: '🇧🇼', region: 'Africa', phoneCode: '+267' },
    { value: 'BV', label: 'Bouvet Island', icon: '🇧🇻', region: 'Antarctica', phoneCode: '+47' },
    { value: 'BR', label: 'Brazil', icon: '🇧🇷', region: 'South America', phoneCode: '+55' },
    { value: 'IO', label: 'British Indian Ocean Territory', icon: '🇮🇴', region: 'Asia', phoneCode: '+246' },
    { value: 'BN', label: 'Brunei Darussalam', icon: '🇧🇳', region: 'Asia', phoneCode: '+673' },
    { value: 'BG', label: 'Bulgaria', icon: '🇧🇬', region: 'Europe', phoneCode: '+359' },
    { value: 'BF', label: 'Burkina Faso', icon: '🇧🇫', region: 'Africa', phoneCode: '+226' },
    { value: 'BI', label: 'Burundi', icon: '🇧🇮', region: 'Africa', phoneCode: '+257' },
    { value: 'KH', label: 'Cambodia', icon: '🇰🇭', region: 'Asia', phoneCode: '+855' },
    { value: 'CM', label: 'Cameroon', icon: '🇨🇲', region: 'Africa', phoneCode: '+237' },
    { value: 'CA', label: 'Canada', icon: '🇨🇦', region: 'North America', phoneCode: '+1' },
    { value: 'CV', label: 'Cape Verde', icon: '🇨🇻', region: 'Africa', phoneCode: '+238' },
    { value: 'KY', label: 'Cayman Islands', icon: '🇰🇾', region: 'North America', phoneCode: '+1345' },
    { value: 'CF', label: 'Central African Republic', icon: '🇨🇫', region: 'Africa', phoneCode: '+236' },
    { value: 'TD', label: 'Chad', icon: '🇹🇩', region: 'Africa', phoneCode: '+235' },
    { value: 'CL', label: 'Chile', icon: '🇨🇱', region: 'South America', phoneCode: '+56' },
    { value: 'CN', label: 'China', icon: '🇨🇳', region: 'Asia', phoneCode: '+86' },
    { value: 'CX', label: 'Christmas Island', icon: '🇨🇽', region: 'Asia', phoneCode: '+61' },
    { value: 'CC', label: 'Cocos (Keeling) Islands', icon: '🇨🇨', region: 'Asia', phoneCode: '+61' },
    { value: 'CO', label: 'Colombia', icon: '🇨🇴', region: 'South America', phoneCode: '+57' },
    { value: 'KM', label: 'Comoros', icon: '🇰🇲', region: 'Africa', phoneCode: '+269' },
    { value: 'CG', label: 'Congo', icon: '🇨🇬', region: 'Africa', phoneCode: '+242' },
    { value: 'CD', label: 'Congo, Democratic Republic', icon: '🇨🇩', region: 'Africa', phoneCode: '+243' },
    { value: 'CK', label: 'Cook Islands', icon: '🇨🇰', region: 'Oceania', phoneCode: '+682' },
    { value: 'CR', label: 'Costa Rica', icon: '🇨🇷', region: 'North America', phoneCode: '+506' },
    { value: 'CI', label: "Côte d'Ivoire", icon: '🇨🇮', region: 'Africa', phoneCode: '+225' },
    { value: 'HR', label: 'Croatia', icon: '🇭🇷', region: 'Europe', phoneCode: '+385' },
    { value: 'CU', label: 'Cuba', icon: '🇨🇺', region: 'North America', phoneCode: '+53' },
    { value: 'CY', label: 'Cyprus', icon: '🇨🇾', region: 'Europe', phoneCode: '+357' },
    { value: 'CZ', label: 'Czech Republic', icon: '🇨🇿', region: 'Europe', phoneCode: '+420' },
    { value: 'DK', label: 'Denmark', icon: '🇩🇰', region: 'Europe', phoneCode: '+45' },
    { value: 'DJ', label: 'Djibouti', icon: '🇩🇯', region: 'Africa', phoneCode: '+253' },
    { value: 'DM', label: 'Dominica', icon: '🇩🇲', region: 'North America', phoneCode: '+1767' },
    { value: 'DO', label: 'Dominican Republic', icon: '🇩🇴', region: 'North America', phoneCode: '+1849' },
    { value: 'EC', label: 'Ecuador', icon: '🇪🇨', region: 'South America', phoneCode: '+593' },
    { value: 'EG', label: 'Egypt', icon: '🇪🇬', region: 'Africa', phoneCode: '+20' },
    { value: 'SV', label: 'El Salvador', icon: '🇸🇻', region: 'North America', phoneCode: '+503' },
    { value: 'GQ', label: 'Equatorial Guinea', icon: '🇬🇶', region: 'Africa', phoneCode: '+240' },
    { value: 'ER', label: 'Eritrea', icon: '🇪🇷', region: 'Africa', phoneCode: '+291' },
    { value: 'EE', label: 'Estonia', icon: '🇪🇪', region: 'Europe', phoneCode: '+372' },
    { value: 'ET', label: 'Ethiopia', icon: '🇪🇹', region: 'Africa', phoneCode: '+251' },
    { value: 'FK', label: 'Falkland Islands', icon: '🇫🇰', region: 'South America', phoneCode: '+500' },
    { value: 'FO', label: 'Faroe Islands', icon: '🇫🇴', region: 'Europe', phoneCode: '+298' },
    { value: 'FJ', label: 'Fiji', icon: '🇫🇯', region: 'Oceania', phoneCode: '+679' },
    { value: 'FI', label: 'Finland', icon: '🇫🇮', region: 'Europe', phoneCode: '+358' },
    { value: 'FR', label: 'France', icon: '🇫🇷', region: 'Europe', phoneCode: '+33' },
    { value: 'GF', label: 'French Guiana', icon: '🇬🇫', region: 'South America', phoneCode: '+594' },
    { value: 'PF', label: 'French Polynesia', icon: '🇵🇫', region: 'Oceania', phoneCode: '+689' },
    { value: 'TF', label: 'French Southern Territories', icon: '🇹🇫', region: 'Antarctica', phoneCode: '+262' },
    { value: 'GA', label: 'Gabon', icon: '🇬🇦', region: 'Africa', phoneCode: '+241' },
    { value: 'GM', label: 'Gambia', icon: '🇬🇲', region: 'Africa', phoneCode: '+220' },
    { value: 'GE', label: 'Georgia', icon: '🇬🇪', region: 'Asia', phoneCode: '+995' },
    { value: 'DE', label: 'Germany', icon: '🇩🇪', region: 'Europe', phoneCode: '+49' },
    { value: 'GH', label: 'Ghana', icon: '🇬🇭', region: 'Africa', phoneCode: '+233' },
    { value: 'GI', label: 'Gibraltar', icon: '🇬🇮', region: 'Europe', phoneCode: '+350' },
    { value: 'GR', label: 'Greece', icon: '🇬🇷', region: 'Europe', phoneCode: '+30' },
    { value: 'GL', label: 'Greenland', icon: '🇬🇱', region: 'North America', phoneCode: '+299' },
    { value: 'GD', label: 'Grenada', icon: '🇬🇩', region: 'North America', phoneCode: '+1473' },
    { value: 'GP', label: 'Guadeloupe', icon: '🇬🇵', region: 'North America', phoneCode: '+590' },
    { value: 'GU', label: 'Guam', icon: '🇬🇺', region: 'Oceania', phoneCode: '+1671' },
    { value: 'GT', label: 'Guatemala', icon: '🇬🇹', region: 'North America', phoneCode: '+502' },
    { value: 'GG', label: 'Guernsey', icon: '🇬🇬', region: 'Europe', phoneCode: '+44' },
    { value: 'GN', label: 'Guinea', icon: '🇬🇳', region: 'Africa', phoneCode: '+224' },
    { value: 'GW', label: 'Guinea-Bissau', icon: '🇬🇼', region: 'Africa', phoneCode: '+245' },
    { value: 'GY', label: 'Guyana', icon: '🇬🇾', region: 'South America', phoneCode: '+592' },
    { value: 'HT', label: 'Haiti', icon: '🇭🇹', region: 'North America', phoneCode: '+509' },
    { value: 'HM', label: 'Heard Island and McDonald Islands', icon: '🇭🇲', region: 'Antarctica', phoneCode: '+672' },
    { value: 'VA', label: 'Holy See (Vatican City State)', icon: '🇻🇦', region: 'Europe', phoneCode: '+379' },
    { value: 'HN', label: 'Honduras', icon: '🇭🇳', region: 'North America', phoneCode: '+504' },
    { value: 'HK', label: 'Hong Kong', icon: '🇭🇰', region: 'Asia', phoneCode: '+852' },
    { value: 'HU', label: 'Hungary', icon: '🇭🇺', region: 'Europe', phoneCode: '+36' },
    { value: 'IS', label: 'Iceland', icon: '🇮🇸', region: 'Europe', phoneCode: '+354' },
    { value: 'IN', label: 'India', icon: '🇮🇳', region: 'Asia', phoneCode: '+91' },
    { value: 'ID', label: 'Indonesia', icon: '🇮🇩', region: 'Asia', phoneCode: '+62' },
    { value: 'IR', label: 'Iran', icon: '🇮🇷', region: 'Asia', phoneCode: '+98' },
    { value: 'IQ', label: 'Iraq', icon: '🇮🇶', region: 'Asia', phoneCode: '+964' },
    { value: 'IE', label: 'Ireland', icon: '🇮🇪', region: 'Europe', phoneCode: '+353' },
    { value: 'IM', label: 'Isle of Man', icon: '🇮🇲', region: 'Europe', phoneCode: '+44' },
    { value: 'IL', label: 'Israel', icon: '🇮🇱', region: 'Asia', phoneCode: '+972' },
    { value: 'IT', label: 'Italy', icon: '🇮🇹', region: 'Europe', phoneCode: '+39' },
    { value: 'JM', label: 'Jamaica', icon: '🇯🇲', region: 'North America', phoneCode: '+1876' },
    { value: 'JP', label: 'Japan', icon: '🇯🇵', region: 'Asia', phoneCode: '+81' },
    { value: 'JE', label: 'Jersey', icon: '🇯🇪', region: 'Europe', phoneCode: '+44' },
    { value: 'JO', label: 'Jordan', icon: '🇯🇴', region: 'Asia', phoneCode: '+962' },
    { value: 'KZ', label: 'Kazakhstan', icon: '🇰🇿', region: 'Asia', phoneCode: '+7' },
    { value: 'KE', label: 'Kenya', icon: '🇰🇪', region: 'Africa', phoneCode: '+254' },
    { value: 'KI', label: 'Kiribati', icon: '🇰🇮', region: 'Oceania', phoneCode: '+686' },
    { value: 'KP', label: 'Korea, North', icon: '🇰🇵', region: 'Asia', phoneCode: '+850' },
    { value: 'KR', label: 'Korea, South', icon: '🇰🇷', region: 'Asia', phoneCode: '+82' },
    { value: 'KW', label: 'Kuwait', icon: '🇰🇼', region: 'Asia', phoneCode: '+965' },
    { value: 'KG', label: 'Kyrgyzstan', icon: '🇰🇬', region: 'Asia', phoneCode: '+996' },
    { value: 'LA', label: 'Laos', icon: '🇱🇦', region: 'Asia', phoneCode: '+856' },
    { value: 'LV', label: 'Latvia', icon: '🇱🇻', region: 'Europe', phoneCode: '+371' },
    { value: 'LB', label: 'Lebanon', icon: '🇱🇧', region: 'Asia', phoneCode: '+961' },
    { value: 'LS', label: 'Lesotho', icon: '🇱🇸', region: 'Africa', phoneCode: '+266' },
    { value: 'LR', label: 'Liberia', icon: '🇱🇷', region: 'Africa', phoneCode: '+231' },
    { value: 'LY', label: 'Libya', icon: '🇱🇾', region: 'Africa', phoneCode: '+218' },
    { value: 'LI', label: 'Liechtenstein', icon: '🇱🇮', region: 'Europe', phoneCode: '+423' },
    { value: 'LT', label: 'Lithuania', icon: '🇱🇹', region: 'Europe', phoneCode: '+370' },
    { value: 'LU', label: 'Luxembourg', icon: '🇱🇺', region: 'Europe', phoneCode: '+352' },
    { value: 'MO', label: 'Macao', icon: '🇲🇴', region: 'Asia', phoneCode: '+853' },
    { value: 'MK', label: 'Macedonia', icon: '🇲🇰', region: 'Europe', phoneCode: '+389' },
    { value: 'MG', label: 'Madagascar', icon: '🇲🇬', region: 'Africa', phoneCode: '+261' },
    { value: 'MW', label: 'Malawi', icon: '🇲🇼', region: 'Africa', phoneCode: '+265' },
    { value: 'MY', label: 'Malaysia', icon: '🇲🇾', region: 'Asia', phoneCode: '+60' },
    { value: 'MV', label: 'Maldives', icon: '🇲🇻', region: 'Asia', phoneCode: '+960' },
    { value: 'ML', label: 'Mali', icon: '🇲🇱', region: 'Africa', phoneCode: '+223' },
    { value: 'MT', label: 'Malta', icon: '🇲🇹', region: 'Europe', phoneCode: '+356' },
    { value: 'MH', label: 'Marshall Islands', icon: '🇲🇭', region: 'Oceania', phoneCode: '+692' },
    { value: 'MQ', label: 'Martinique', icon: '🇲🇶', region: 'North America', phoneCode: '+596' },
    { value: 'MR', label: 'Mauritania', icon: '🇲🇷', region: 'Africa', phoneCode: '+222' },
    { value: 'MU', label: 'Mauritius', icon: '🇲🇺', region: 'Africa', phoneCode: '+230' },
    { value: 'YT', label: 'Mayotte', icon: '🇾🇹', region: 'Africa', phoneCode: '+262' },
    { value: 'MX', label: 'Mexico', icon: '🇲🇽', region: 'North America', phoneCode: '+52' },
    { value: 'FM', label: 'Micronesia', icon: '🇫🇲', region: 'Oceania', phoneCode: '+691' },
    { value: 'MD', label: 'Moldova', icon: '🇲🇩', region: 'Europe', phoneCode: '+373' },
    { value: 'MC', label: 'Monaco', icon: '🇲🇨', region: 'Europe', phoneCode: '+377' },
    { value: 'MN', label: 'Mongolia', icon: '🇲🇳', region: 'Asia', phoneCode: '+976' },
    { value: 'ME', label: 'Montenegro', icon: '🇲🇪', region: 'Europe', phoneCode: '+382' },
    { value: 'MS', label: 'Montserrat', icon: '🇲🇸', region: 'North America', phoneCode: '+1664' },
    { value: 'MA', label: 'Morocco', icon: '🇲🇦', region: 'Africa', phoneCode: '+212' },
    { value: 'MZ', label: 'Mozambique', icon: '🇲🇿', region: 'Africa', phoneCode: '+258' },
    { value: 'MM', label: 'Myanmar', icon: '🇲🇲', region: 'Asia', phoneCode: '+95' },
    { value: 'NA', label: 'Namibia', icon: '🇳🇦', region: 'Africa', phoneCode: '+264' },
    { value: 'NR', label: 'Nauru', icon: '🇳🇷', region: 'Oceania', phoneCode: '+674' },
    { value: 'NP', label: 'Nepal', icon: '🇳🇵', region: 'Asia', phoneCode: '+977' },
    { value: 'NL', label: 'Netherlands', icon: '🇳🇱', region: 'Europe', phoneCode: '+31' },
    { value: 'NC', label: 'New Caledonia', icon: '🇳🇨', region: 'Oceania', phoneCode: '+687' },
    { value: 'NZ', label: 'New Zealand', icon: '🇳🇿', region: 'Oceania', phoneCode: '+64' },
    { value: 'NI', label: 'Nicaragua', icon: '🇳🇮', region: 'North America', phoneCode: '+505' },
    { value: 'NE', label: 'Niger', icon: '🇳🇪', region: 'Africa', phoneCode: '+227' },
    { value: 'NG', label: 'Nigeria', icon: '🇳🇬', region: 'Africa', phoneCode: '+234' },
    { value: 'NU', label: 'Niue', icon: '🇳🇺', region: 'Oceania', phoneCode: '+683' },
    { value: 'NF', label: 'Norfolk Island', icon: '🇳🇫', region: 'Oceania', phoneCode: '+672' },
    { value: 'MP', label: 'Northern Mariana Islands', icon: '🇲🇵', region: 'Oceania', phoneCode: '+1670' },
    { value: 'NO', label: 'Norway', icon: '🇳🇴', region: 'Europe', phoneCode: '+47' },
    { value: 'OM', label: 'Oman', icon: '🇴🇲', region: 'Asia', phoneCode: '+968' },
    { value: 'PK', label: 'Pakistan', icon: '🇵🇰', region: 'Asia', phoneCode: '+92' },
    { value: 'PW', label: 'Palau', icon: '🇵🇼', region: 'Oceania', phoneCode: '+680' },
    { value: 'PS', label: 'Palestine', icon: '🇵🇸', region: 'Asia', phoneCode: '+970' },
    { value: 'PA', label: 'Panama', icon: '🇵🇦', region: 'North America', phoneCode: '+507' },
    { value: 'PG', label: 'Papua New Guinea', icon: '🇵🇬', region: 'Oceania', phoneCode: '+675' },
    { value: 'PY', label: 'Paraguay', icon: '🇵🇾', region: 'South America', phoneCode: '+595' },
    { value: 'PE', label: 'Peru', icon: '🇵🇪', region: 'South America', phoneCode: '+51' },
    { value: 'PH', label: 'Philippines', icon: '🇵🇭', region: 'Asia', phoneCode: '+63' },
    { value: 'PN', label: 'Pitcairn', icon: '🇵🇳', region: 'Oceania', phoneCode: '+64' },
    { value: 'PL', label: 'Poland', icon: '🇵🇱', region: 'Europe', phoneCode: '+48' },
    { value: 'PT', label: 'Portugal', icon: '🇵🇹', region: 'Europe', phoneCode: '+351' },
    { value: 'PR', label: 'Puerto Rico', icon: '🇵🇷', region: 'North America', phoneCode: '+1939' },
    { value: 'QA', label: 'Qatar', icon: '🇶🇦', region: 'Asia', phoneCode: '+974' },
    { value: 'RE', label: 'Reunion', icon: '🇷🇪', region: 'Africa', phoneCode: '+262' },
    { value: 'RO', label: 'Romania', icon: '🇷🇴', region: 'Europe', phoneCode: '+40' },
    { value: 'RU', label: 'Russian Federation', icon: '🇷🇺', region: 'Europe', phoneCode: '+7' },
    { value: 'RW', label: 'Rwanda', icon: '🇷🇼', region: 'Africa', phoneCode: '+250' },
    { value: 'BL', label: 'Saint Barthélemy', icon: '🇧🇱', region: 'North America', phoneCode: '+590' },
    { value: 'SH', label: 'Saint Helena', icon: '🇸🇭', region: 'Africa', phoneCode: '+290' },
    { value: 'KN', label: 'Saint Kitts and Nevis', icon: '🇰🇳', region: 'North America', phoneCode: '+1869' },
    { value: 'LC', label: 'Saint Lucia', icon: '🇱🇨', region: 'North America', phoneCode: '+1758' },
    { value: 'MF', label: 'Saint Martin (French part)', icon: '🇲🇫', region: 'North America', phoneCode: '+590' },
    { value: 'PM', label: 'Saint Pierre and Miquelon', icon: '🇵🇲', region: 'North America', phoneCode: '+508' },
    { value: 'VC', label: 'Saint Vincent and the Grenadines', icon: '🇻🇨', region: 'North America', phoneCode: '+1784' },
    { value: 'WS', label: 'Samoa', icon: '🇼🇸', region: 'Oceania', phoneCode: '+685' },
    { value: 'SM', label: 'San Marino', icon: '🇸🇲', region: 'Europe', phoneCode: '+378' },
    { value: 'ST', label: 'Sao Tome and Principe', icon: '🇸🇹', region: 'Africa', phoneCode: '+239' },
    { value: 'SA', label: 'Saudi Arabia', icon: '🇸🇦', region: 'Asia', phoneCode: '+966' },
    { value: 'SN', label: 'Senegal', icon: '🇸🇳', region: 'Africa', phoneCode: '+221' },
    { value: 'RS', label: 'Serbia', icon: '🇷🇸', region: 'Europe', phoneCode: '+381' },
    { value: 'SC', label: 'Seychelles', icon: '🇸🇨', region: 'Africa', phoneCode: '+248' },
    { value: 'SL', label: 'Sierra Leone', icon: '🇸🇱', region: 'Africa', phoneCode: '+232' },
    { value: 'SG', label: 'Singapore', icon: '🇸🇬', region: 'Asia', phoneCode: '+65' },
    { value: 'SX', label: 'Sint Maarten', icon: '🇸🇽', region: 'North America', phoneCode: '+1721' },
    { value: 'SK', label: 'Slovakia', icon: '🇸🇰', region: 'Europe', phoneCode: '+421' },
    { value: 'SI', label: 'Slovenia', icon: '🇸🇮', region: 'Europe', phoneCode: '+386' },
    { value: 'SB', label: 'Solomon Islands', icon: '🇸🇧', region: 'Oceania', phoneCode: '+677' },
    { value: 'SO', label: 'Somalia', icon: '🇸🇴', region: 'Africa', phoneCode: '+252' },
    { value: 'ZA', label: 'South Africa', icon: '🇿🇦', region: 'Africa', phoneCode: '+27' },
    { value: 'GS', label: 'South Georgia', icon: '🇬🇸', region: 'Antarctica', phoneCode: '+500' },
    { value: 'SS', label: 'South Sudan', icon: '🇸🇸', region: 'Africa', phoneCode: '+211' },
    { value: 'ES', label: 'Spain', icon: '🇪🇸', region: 'Europe', phoneCode: '+34' },
    { value: 'LK', label: 'Sri Lanka', icon: '🇱🇰', region: 'Asia', phoneCode: '+94' },
    { value: 'SD', label: 'Sudan', icon: '🇸🇩', region: 'Africa', phoneCode: '+249' },
    { value: 'SR', label: 'Suriname', icon: '🇸🇷', region: 'South America', phoneCode: '+597' },
    { value: 'SJ', label: 'Svalbard and Jan Mayen', icon: '🇸🇯', region: 'Europe', phoneCode: '+47' },
    { value: 'SZ', label: 'Swaziland', icon: '🇸🇿', region: 'Africa', phoneCode: '+268' },
    { value: 'SE', label: 'Sweden', icon: '🇸🇪', region: 'Europe', phoneCode: '+46' },
    { value: 'CH', label: 'Switzerland', icon: '🇨🇭', region: 'Europe', phoneCode: '+41' },
    { value: 'SY', label: 'Syria', icon: '🇸🇾', region: 'Asia', phoneCode: '+963' },
    { value: 'TW', label: 'Taiwan', icon: '🇹🇼', region: 'Asia', phoneCode: '+886' },
    { value: 'TJ', label: 'Tajikistan', icon: '🇹🇯', region: 'Asia', phoneCode: '+992' },
    { value: 'TZ', label: 'Tanzania', icon: '🇹🇿', region: 'Africa', phoneCode: '+255' },
    { value: 'TH', label: 'Thailand', icon: '🇹🇭', region: 'Asia', phoneCode: '+66' },
    { value: 'TL', label: 'Timor-Leste', icon: '🇹🇱', region: 'Asia', phoneCode: '+670' },
    { value: 'TG', label: 'Togo', icon: '🇹🇬', region: 'Africa', phoneCode: '+228' },
    { value: 'TK', label: 'Tokelau', icon: '🇹🇰', region: 'Oceania', phoneCode: '+690' },
    { value: 'TO', label: 'Tonga', icon: '🇹🇴', region: 'Oceania', phoneCode: '+676' },
    { value: 'TT', label: 'Trinidad and Tobago', icon: '🇹🇹', region: 'North America', phoneCode: '+1868' },
    { value: 'TN', label: 'Tunisia', icon: '🇹🇳', region: 'Africa', phoneCode: '+216' },
    { value: 'TR', label: 'Turkey', icon: '🇹🇷', region: 'Asia', phoneCode: '+90' },
    { value: 'TM', label: 'Turkmenistan', icon: '🇹🇲', region: 'Asia', phoneCode: '+993' },
    { value: 'TC', label: 'Turks and Caicos Islands', icon: '🇹🇨', region: 'North America', phoneCode: '+1649' },
    { value: 'TV', label: 'Tuvalu', icon: '🇹🇻', region: 'Oceania', phoneCode: '+688' },
    { value: 'UG', label: 'Uganda', icon: '🇺🇬', region: 'Africa', phoneCode: '+256' },
    { value: 'UA', label: 'Ukraine', icon: '🇺🇦', region: 'Europe', phoneCode: '+380' },
    { value: 'AE', label: 'United Arab Emirates', icon: '🇦🇪', region: 'Asia', phoneCode: '+971' },
    { value: 'GB', label: 'United Kingdom', icon: '🇬🇧', region: 'Europe', phoneCode: '+44' },
    { value: 'US', label: 'United States', icon: '🇺🇸', region: 'North America', phoneCode: '+1' },
    { value: 'UM', label: 'United States Minor Outlying Islands', icon: '🇺🇲', region: 'North America', phoneCode: '+1' },
    { value: 'UY', label: 'Uruguay', icon: '🇺🇾', region: 'South America', phoneCode: '+598' },
    { value: 'UZ', label: 'Uzbekistan', icon: '🇺🇿', region: 'Asia', phoneCode: '+998' },
    { value: 'VU', label: 'Vanuatu', icon: '🇻🇺', region: 'Oceania', phoneCode: '+678' },
    { value: 'VE', label: 'Venezuela', icon: '🇻🇪', region: 'South America', phoneCode: '+58' },
    { value: 'VN', label: 'Vietnam', icon: '🇻🇳', region: 'Asia', phoneCode: '+84' },
    { value: 'VG', label: 'Virgin Islands, British', icon: '🇻🇬', region: 'North America', phoneCode: '+1284' },
    { value: 'VI', label: 'Virgin Islands, U.S.', icon: '🇻🇮', region: 'North America', phoneCode: '+1340' },
    { value: 'WF', label: 'Wallis and Futuna', icon: '🇼🇫', region: 'Oceania', phoneCode: '+681' },
    { value: 'EH', label: 'Western Sahara', icon: '🇪🇭', region: 'Africa', phoneCode: '+212' },
    { value: 'YE', label: 'Yemen', icon: '🇾🇪', region: 'Asia', phoneCode: '+967' },
    { value: 'ZM', label: 'Zambia', icon: '🇿🇲', region: 'Africa', phoneCode: '+260' },
    { value: 'ZW', label: 'Zimbabwe', icon: '🇿🇼', region: 'Africa', phoneCode: '+263' }
];

// Получение страны по коду
export const getCountryByCode = (code) => {
    return COUNTRIES.find(country => country.value === code);
};

// Получение названия страны по коду
export const getCountryLabel = (code) => {
    const country = getCountryByCode(code);
    return country ? country.label : code;
};

// Проверка поддержки страны
export const isCountrySupported = (code) => {
    return COUNTRIES.some(country => country.value === code);
};

// Получение списка стран по региону
export const getCountriesByRegion = (region) => {
    return COUNTRIES.filter(country => country.region === region);
};

// Получение всех доступных регионов
export const getAvailableRegions = () => {
    const regions = new Set(COUNTRIES.map(country => country.region));
    return Array.from(regions).sort();
};

// Поиск стран по запросу
export const searchCountries = (query) => {
    const searchTerm = query.toLowerCase();
    return COUNTRIES.filter(country => 
        country.label.toLowerCase().includes(searchTerm) || 
        country.value.toLowerCase().includes(searchTerm) ||
        country.phoneCode.toLowerCase().includes(searchTerm)
    );
};

// Группировка стран по региону
export const groupCountriesByRegion = () => {
    const grouped = {};
    COUNTRIES.forEach(country => {
        if (!grouped[country.region]) {
            grouped[country.region] = [];
        }
        grouped[country.region].push(country);
    });
    return grouped;
};

// Получение списка популярных стран
export const getPopularCountries = () => {
    const popularCountryCodes = ['US', 'GB', 'DE', 'FR', 'IT', 'ES', 'CN', 'JP', 'KR', 'RU'];
    return COUNTRIES.filter(country => popularCountryCodes.includes(country.value));
};

// Функции для работы с телефонными кодами

// Получение телефонного кода страны по коду страны
export const getPhoneCodeByCountry = (countryCode) => {
    const country = getCountryByCode(countryCode);
    return country ? country.phoneCode : null;
};

// Маски для телефонных номеров всех стран
const PHONE_MASKS = {
    // A
    'AF': '+93 ### ### ####',      // Afghanistan
    'AL': '+355 (###) ### ###',    // Albania
    'DZ': '+213 ### ### ###',      // Algeria
    'AS': '+1684 ### ####',        // American Samoa
    'AD': '+376 ### ###',          // Andorra
    'AO': '+244 ### ### ###',      // Angola
    'AI': '+1264 ### ####',        // Anguilla
    'AQ': '+672 ### ###',          // Antarctica
    'AG': '+1268 ### ####',        // Antigua and Barbuda
    'AR': '+54 (###) ### ####',    // Argentina
    'AM': '+374 ## ### ###',       // Armenia
    'AW': '+297 ### ####',         // Aruba
    'AU': '+61 ### ### ###',       // Australia
    'AT': '+43 ### ### ####',      // Austria
    'AZ': '+994 ## ### ## ##',     // Azerbaijan

    // B
    'BS': '+1242 ### ####',        // Bahamas
    'BH': '+973 #### ####',        // Bahrain
    'BD': '+880 ## ### ###',       // Bangladesh
    'BB': '+1246 ### ####',        // Barbados
    'BY': '+375 ## ### ## ##',     // Belarus
    'BE': '+32 ### ### ###',       // Belgium
    'BZ': '+501 ### ####',         // Belize
    'BJ': '+229 ## ### ###',       // Benin
    'BM': '+1441 ### ####',        // Bermuda
    'BT': '+975 # ### ###',        // Bhutan
    'BO': '+591 # ### ####',       // Bolivia
    'BA': '+387 ## ### ###',       // Bosnia and Herzegovina
    'BW': '+267 ## ### ###',       // Botswana
    'BV': '+47 ### ## ###',        // Bouvet Island
    'BR': '+55 (##) #### ####',    // Brazil
    'IO': '+246 ### ####',         // British Indian Ocean Territory
    'BN': '+673 ### ####',         // Brunei
    'BG': '+359 (###) ### ###',    // Bulgaria
    'BF': '+226 ## ## ####',       // Burkina Faso
    'BI': '+257 ## ## ####',       // Burundi

    // C
    'KH': '+855 ## ### ###',       // Cambodia
    'CM': '+237 #### ####',        // Cameroon
    'CA': '+1 (###) ### ####',     // Canada
    'CV': '+238 ### ####',         // Cape Verde
    'KY': '+1345 ### ####',        // Cayman Islands
    'CF': '+236 ## ## ####',       // Central African Republic
    'TD': '+235 ## ## ## ##',      // Chad
    'CL': '+56 # #### ####',       // Chile
    'CN': '+86 ### #### ####',     // China
    'CX': '+61 # #### ####',       // Christmas Island
    'CC': '+61 # #### ####',       // Cocos Islands
    'CO': '+57 ### ### ####',      // Colombia
    'KM': '+269 ## #####',         // Comoros
    'CG': '+242 ## ### ####',      // Congo
    'CD': '+243 ### ### ###',      // Congo, Democratic Republic
    'CK': '+682 ## ###',           // Cook Islands
    'CR': '+506 #### ####',        // Costa Rica
    'CI': '+225 ## ### ###',       // Côte d'Ivoire
    'HR': '+385 ## ### ####',      // Croatia
    'CU': '+53 # ### ####',        // Cuba
    'CY': '+357 ## ### ###',       // Cyprus
    'CZ': '+420 ### ### ###',      // Czech Republic

    // D
    'DK': '+45 ## ## ## ##',       // Denmark
    'DJ': '+253 ## ## ## ##',      // Djibouti
    'DM': '+1767 ### ####',        // Dominica
    'DO': '+1849 ### ####',        // Dominican Republic

    // E
    'EC': '+593 ## ### ####',      // Ecuador
    'EG': '+20 ### ### ####',      // Egypt
    'SV': '+503 #### ####',        // El Salvador
    'GQ': '+240 ## ### ####',      // Equatorial Guinea
    'ER': '+291 # ### ###',        // Eritrea
    'EE': '+372 #### ####',        // Estonia
    'ET': '+251 ## ### ####',      // Ethiopia

    // F
    'FK': '+500 #####',            // Falkland Islands
    'FO': '+298 ### ###',          // Faroe Islands
    'FJ': '+679 ### ####',         // Fiji
    'FI': '+358 ### ### ####',     // Finland
    'FR': '+33 ### ### ###',       // France
    'GF': '+594 ##### ####',       // French Guiana
    'PF': '+689 ## ## ##',         // French Polynesia
    'TF': '+262 ##### ####',       // French Southern Territories

    // G
    'GA': '+241 # ## ## ##',       // Gabon
    'GM': '+220 ### ####',         // Gambia
    'GE': '+995 ### ### ###',      // Georgia
    'DE': '+49 ### ### ####',      // Germany
    'GH': '+233 ### ### ###',      // Ghana
    'GI': '+350 ### #####',        // Gibraltar
    'GR': '+30 ### ### ####',      // Greece
    'GL': '+299 ## ## ##',         // Greenland
    'GD': '+1473 ### ####',        // Grenada
    'GP': '+590 ### ### ###',      // Guadeloupe
    'GU': '+1671 ### ####',        // Guam
    'GT': '+502 #### ####',        // Guatemala
    'GG': '+44 #### ######',       // Guernsey
    'GN': '+224 ## ### ###',       // Guinea
    'GW': '+245 # ######',         // Guinea-Bissau
    'GY': '+592 ### ####',         // Guyana

    // H
    'HT': '+509 #### ####',        // Haiti
    'HM': '+672 ### ###',          // Heard Island and McDonald Islands
    'VA': '+379 ### ### ###',      // Holy See (Vatican)
    'HN': '+504 #### ####',        // Honduras
    'HK': '+852 #### ####',        // Hong Kong
    'HU': '+36 ### ### ###',       // Hungary

    // I
    'IS': '+354 ### ####',         // Iceland
    'IN': '+91 #### ### ###',      // India
    'ID': '+62 ### ### ####',      // Indonesia
    'IR': '+98 ### ### ####',      // Iran
    'IQ': '+964 ### ### ####',     // Iraq
    'IE': '+353 ## ### ####',      // Ireland
    'IM': '+44 #### ######',       // Isle of Man
    'IL': '+972 ### ### ####',     // Israel
    'IT': '+39 ### ### ####',      // Italy

    // J
    'JM': '+1876 ### ####',        // Jamaica
    'JP': '+81 ### ### ####',      // Japan
    'JE': '+44 #### ######',       // Jersey
    'JO': '+962 # #### ####',      // Jordan

    // K
    'KZ': '+7 ### ### ## ##',      // Kazakhstan
    'KE': '+254 ### ### ###',      // Kenya
    'KI': '+686 ## ###',           // Kiribati
    'KP': '+850 ### ### ###',      // Korea, North
    'KR': '+82 ## ### ####',       // Korea, South
    'KW': '+965 #### ####',        // Kuwait
    'KG': '+996 ### ### ###',      // Kyrgyzstan

    // L
    'LA': '+856 ## ### ###',       // Laos
    'LV': '+371 ## ### ###',       // Latvia
    'LB': '+961 # ### ###',        // Lebanon
    'LS': '+266 # ### ####',       // Lesotho
    'LR': '+231 ### ### ###',      // Liberia
    'LY': '+218 ## ### ###',       // Libya
    'LI': '+423 ### ### ###',      // Liechtenstein
    'LT': '+370 ### #####',        // Lithuania
    'LU': '+352 ### ### ###',      // Luxembourg

    // M
    'MO': '+853 #### ####',        // Macao
    'MK': '+389 ## ### ###',       // Macedonia
    'MG': '+261 ## ## #####',      // Madagascar
    'MW': '+265 # ### ###',        // Malawi
    'MY': '+60 ## ### ####',       // Malaysia
    'MV': '+960 ### ####',         // Maldives
    'ML': '+223 ## ## ####',       // Mali
    'MT': '+356 #### ####',        // Malta
    'MH': '+692 ### ####',         // Marshall Islands
    'MQ': '+596 ### ## ## ##',     // Martinique
    'MR': '+222 ## ## ####',       // Mauritania
    'MU': '+230 ### ####',         // Mauritius
    'YT': '+262 ### ### ###',      // Mayotte
    'MX': '+52 ### ### ####',      // Mexico
    'FM': '+691 ### ####',         // Micronesia
    'MD': '+373 #### ####',        // Moldova
    'MC': '+377 ## ### ###',       // Monaco
    'MN': '+976 ## ## ####',       // Mongolia
    'ME': '+382 ## ### ###',       // Montenegro
    'MS': '+1664 ### ####',        // Montserrat
    'MA': '+212 ## #### ###',      // Morocco
    'MZ': '+258 ## ### ###',       // Mozambique
    'MM': '+95 ## ### ###',        // Myanmar

    // N
    'NA': '+264 ## ### ####',      // Namibia
    'NR': '+674 ### ####',         // Nauru
    'NP': '+977 ## ### ###',       // Nepal
    'NL': '+31 ## ### ####',       // Netherlands
    'NC': '+687 ## ####',          // New Caledonia
    'NZ': '+64 ## ### ####',       // New Zealand
    'NI': '+505 #### ####',        // Nicaragua
    'NE': '+227 ## ## ####',       // Niger
    'NG': '+234 ### ### ####',     // Nigeria
    'NU': '+683 ####',             // Niue
    'NF': '+672 3## ###',          // Norfolk Island
    'MP': '+1670 ### ####',        // Northern Mariana Islands
    'NO': '+47 ### ## ###',        // Norway

    // O
    'OM': '+968 ## ### ###',       // Oman

    // P
    'PK': '+92 ### ### ####',      // Pakistan
    'PW': '+680 ### ####',         // Palau
    'PS': '+970 ## ### ####',      // Palestine
    'PA': '+507 #### ####',        // Panama
    'PG': '+675 ### ####',         // Papua New Guinea
    'PY': '+595 ### ### ###',      // Paraguay
    'PE': '+51 ### ### ###',       // Peru
    'PH': '+63 ### ### ####',      // Philippines
    'PN': '+64 ### ####',          // Pitcairn
    'PL': '+48 ### ### ###',       // Poland
    'PT': '+351 ## ### ####',      // Portugal
    'PR': '+1939 ### ####',        // Puerto Rico

    // Q
    'QA': '+974 #### ####',        // Qatar

    // R
    'RE': '+262 ### ### ###',      // Reunion
    'RO': '+40 ## ### ####',       // Romania
    'RU': '+7 (###) ### ## ##',    // Russian Federation
    'RW': '+250 ### ### ###',      // Rwanda

    // S
    'BL': '+590 ### ### ###',      // Saint Barthélemy
    'SH': '+290 ####',             // Saint Helena
    'KN': '+1869 ### ####',        // Saint Kitts and Nevis
    'LC': '+1758 ### ####',        // Saint Lucia
    'MF': '+590 ### ### ###',      // Saint Martin
    'PM': '+508 ## ####',          // Saint Pierre and Miquelon
    'VC': '+1784 ### ####',        // Saint Vincent and the Grenadines
    'WS': '+685 ## ####',          // Samoa
    'SM': '+378 #### ######',      // San Marino
    'ST': '+239 ## #####',         // Sao Tome and Principe
    'SA': '+966 # ### ####',       // Saudi Arabia
    'SN': '+221 ## ### ####',      // Senegal
    'RS': '+381 ## ### ####',      // Serbia
    'SC': '+248 # ### ###',        // Seychelles
    'SL': '+232 ## ### ###',       // Sierra Leone
    'SG': '+65 #### ####',         // Singapore
    'SX': '+1721 ### ####',        // Sint Maarten
    'SK': '+421 ### ### ###',      // Slovakia
    'SI': '+386 ## ### ###',       // Slovenia
    'SB': '+677 ### ####',         // Solomon Islands
    'SO': '+252 ## ### ###',       // Somalia
    'ZA': '+27 ## ### ####',       // South Africa
    'GS': '+500 #####',            // South Georgia
    'SS': '+211 ## ### ####',      // South Sudan
    'ES': '+34 ### ### ###',       // Spain
    'LK': '+94 ## ### ####',       // Sri Lanka
    'SD': '+249 ## ### ####',      // Sudan
    'SR': '+597 ### ####',         // Suriname
    'SJ': '+47 ### ## ###',        // Svalbard and Jan Mayen
    'SZ': '+268 ## ## ####',       // Swaziland
    'SE': '+46 ## ### ####',       // Sweden
    'CH': '+41 ## ### ####',       // Switzerland
    'SY': '+963 ## ### ####',      // Syria

    // T
    'TW': '+886 ### ### ###',      // Taiwan
    'TJ': '+992 ## ### ####',      // Tajikistan
    'TZ': '+255 ## ### ####',      // Tanzania
    'TH': '+66 ## ### ####',       // Thailand
    'TL': '+670 ### ####',         // Timor-Leste
    'TG': '+228 ## ### ###',       // Togo
    'TK': '+690 ####',             // Tokelau
    'TO': '+676 #####',            // Tonga
    'TT': '+1868 ### ####',        // Trinidad and Tobago
    'TN': '+216 ## ### ###',       // Tunisia
    'TR': '+90 ### ### ####',      // Turkey
    'TM': '+993 # ### ####',       // Turkmenistan
    'TC': '+1649 ### ####',        // Turks and Caicos Islands
    'TV': '+688 ## ###',           // Tuvalu

    // U
    'UG': '+256 ### ### ###',      // Uganda
    'UA': '+380 ## ### ####',      // Ukraine
    'AE': '+971 ## ### ####',      // United Arab Emirates
    'GB': '+44 #### ######',       // United Kingdom
    'US': '+1 (###) ### ####',     // United States
    'UM': '+1 ### ### ####',       // United States Minor Outlying Islands
    'UY': '+598 # ### ## ##',      // Uruguay
    'UZ': '+998 ## ### ####',      // Uzbekistan

    // V
    'VU': '+678 ## ###',           // Vanuatu
    'VE': '+58 ### ### ####',      // Venezuela
    'VN': '+84 ## #### ###',       // Vietnam
    'VG': '+1284 ### ####',        // Virgin Islands, British
    'VI': '+1340 ### ####',        // Virgin Islands, U.S.

    // W
    'WF': '+681 ## ####',          // Wallis and Futuna
    'EH': '+212 ## #### ###',      // Western Sahara

    // Y
    'YE': '+967 ### ### ###',      // Yemen

    // Z
    'ZM': '+260 ## ### ####',      // Zambia
    'ZW': '+263 # ### ####'        // Zimbabwe
};

// Обновленная функция получения маски для номера телефона
export const getPhoneNumberMask = (countryCode) => {
    const country = getCountryByCode(countryCode);
    if (!country) return '+# (###) ###-##-##'; // дефолтная маска

    return PHONE_MASKS[countryCode] || '+# (###) ###-##-##';
};

// Функция для валидации номера телефона с использованием маски
export const validatePhoneNumberWithMask = (phoneNumber, countryCode) => {
    const mask = getPhoneNumberMask(countryCode);
    if (!mask) return false;

    // Удаляем все не цифры из номера
    const cleanNumber = phoneNumber.replace(/\D/g, '');
    
    // Считаем количество # в маске (ожидаемое количество цифр)
    const expectedLength = (mask.match(/#/g) || []).length;
    
    // Проверяем длину номера
    return cleanNumber.length === expectedLength;
};

// Функция для форматирования номера телефона по маске
export const formatPhoneNumberWithMask = (phoneNumber, countryCode) => {
    const mask = getPhoneNumberMask(countryCode);
    if (!mask) return phoneNumber;

    const cleanNumber = phoneNumber.replace(/\D/g, '');
    let formattedNumber = mask;
    let numberIndex = 0;

    // Заменяем каждый # в маске на соответствующую цифру из номера
    for (let i = 0; i < mask.length && numberIndex < cleanNumber.length; i++) {
        if (mask[i] === '#') {
            formattedNumber = formattedNumber.replace('#', cleanNumber[numberIndex]);
            numberIndex++;
        }
    }

    // Убираем оставшиеся # если номер короче маски
    formattedNumber = formattedNumber.replace(/#/g, '');

    return formattedNumber;
};