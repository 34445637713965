import React, { useState, useEffect } from 'react';

const BattleTimer = ({ remainingBattles, battleLimit }) => {
  const [timeRemaining, setTimeRemaining] = useState('');

  useEffect(() => {
    const calculateNextUpdate = () => {
      const now = new Date();
      const utcNow = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
      const utcReset = new Date(utcNow);
      utcReset.setUTCHours(0, 0, 0, 0);

      if (utcNow >= utcReset) {
        utcReset.setDate(utcReset.getDate() + 1);
      }

      return utcReset.getTime() - utcNow.getTime();
    };

    const formatTimeRemaining = (milliseconds) => {
      if (!milliseconds) return '';
      const hours = Math.floor(milliseconds / (1000 * 60 * 60));
      const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const updateTimer = () => {
      if (remainingBattles === 0) {
        const timeLeft = calculateNextUpdate();
        setTimeRemaining(formatTimeRemaining(timeLeft));
      }
    };

    const timer = setInterval(updateTimer, 1000);
    updateTimer();

    return () => clearInterval(timer);
  }, [remainingBattles]);

  return (
    <div className="w-full bg-gray-400 rounded-full h-6 relative">
      <div
        className="bg-white h-6 rounded-full transition-all duration-300"
        style={{ width: `${(remainingBattles / battleLimit) * 100}%` }}
      ></div>
      <div className="absolute inset-0 flex items-center justify-center text-xs text-black font-medium">
        {remainingBattles > 0 ? (
          `${remainingBattles} / ${battleLimit} Battles Available`
        ) : (
          <div className="flex items-center space-x-1">
            <span>Next battles in:</span>
            <span className="font-bold">{timeRemaining}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(BattleTimer);