import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent } from '../ui/card';
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Label } from "../ui/label";

const Settings = () => {
  const [settings, setSettings] = useState({
    projectName: '',
    adminEmail: '',
    telegramBotToken: '',
    maintenanceMode: false,
  });

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const response = await axios.get('/api/settings');
      setSettings(response.data);
    } catch (error) {
      console.error('Error fetching settings:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put('/api/settings', settings);
      alert('Settings saved successfully');
    } catch (error) {
      console.error('Error saving settings:', error);
      alert('Failed to save settings');
    }
  };

  return (
    <Card className="bg-transparent">
      <CardContent className="p-6">
        <h2 className="text-2xl font-bold text-[#eff0f2] mb-4">Project Settings</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <Label htmlFor="projectName" className="text-[#d3d3d3]">Project Name</Label>
            <Input
              id="projectName"
              name="projectName"
              value={settings.projectName}
              onChange={handleChange}
              className="bg-[#15171c] text-[#d3d3d3]"
            />
          </div>
          <div>
            <Label htmlFor="adminEmail" className="text-[#d3d3d3]">Admin Email</Label>
            <Input
              id="adminEmail"
              name="adminEmail"
              type="email"
              value={settings.adminEmail}
              onChange={handleChange}
              className="bg-[#15171c] text-[#d3d3d3]"
            />
          </div>
          <div>
            <Label htmlFor="telegramBotToken" className="text-[#d3d3d3]">Telegram Bot Token</Label>
            <Input
              id="telegramBotToken"
              name="telegramBotToken"
              value={settings.telegramBotToken}
              onChange={handleChange}
              className="bg-[#15171c] text-[#d3d3d3]"
            />
          </div>
          <div className="flex items-center">
            <Input
              id="maintenanceMode"
              name="maintenanceMode"
              type="checkbox"
              checked={settings.maintenanceMode}
              onChange={handleChange}
              className="mr-2"
            />
            <Label htmlFor="maintenanceMode" className="text-[#d3d3d3]">Maintenance Mode</Label>
          </div>
          <Button type="submit" className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4">
            Save Settings
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};

export default Settings;