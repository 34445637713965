import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { Card, CardHeader, CardContent } from '../ui/card';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { Edit, Trash2, Plus } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '../ui/dialog';
import { Label } from '../ui/label';

const UserForm = ({ user, onSave, onCancel }) => {
  const [formData, setFormData] = useState(user || {
    username: '',
    level: '',
    rankStatus: '',
    battleLimit: '',
    totalBattles: '',
    coinsEarned: '',
    referrals: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {Object.entries(formData).map(([key, value]) => (
        key !== '_id' && key !== 'avatar' && (
          <div key={key}>
            <Label htmlFor={key} className="text-[#d3d3d3]">{key}</Label>
            <Input
              id={key}
              name={key}
              value={value}
              onChange={handleChange}
              className="bg-[#15171c] text-[#d3d3d3]"
            />
          </div>
        )
      ))}
      <div className="flex justify-end space-x-2">
        <Button type="button" onClick={onCancel} variant="outline" className="bg-black text-white rounded-[30px] py-1 px-4">Cancel</Button>
        <Button type="submit" className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4">Save User</Button>
      </div>
    </form>
  );
};

const UsersManagement = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [editingUser, setEditingUser] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get('/api/users');
      setUsers(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Failed to fetch users. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const filteredUsers = useMemo(() => {
    return users.filter(user =>
      Object.values(user).some(value =>
        value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [users, searchTerm]);

  const handleSave = async (userData) => {
    try {
      let response;
      if (editingUser?._id) {
        response = await axios.put(`/api/users/${editingUser._id}`, userData);
      } else {
        response = await axios.post('/api/users', userData);
      }
      fetchUsers();
      setIsDialogOpen(false);
      setEditingUser(null);
    } catch (error) {
      console.error('Error saving user:', error.response ? error.response.data : error.message);
      alert('Failed to save user. Please try again.');
    }
  };

  const handleDelete = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await axios.delete(`/api/users/${userId}`);
        fetchUsers();
      } catch (error) {
        console.error('Error deleting user:', error);
        alert('Failed to delete user. Please try again.');
      }
    }
  };

  if (isLoading) {
    return <div className="text-[#d3d3d3]">Loading users...</div>;
  }

  if (error) {
    return <div className="text-[#d3d3d3]">Error: {error}</div>;
  }

  return (
    <Card className="bg-transparent">
      <CardHeader>
        <h3 className="text-2xl font-bold text-[#eff0f2]">User</h3>
        <div className="flex justify-between items-center">
          <Input
            placeholder="Search users..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="max-w-sm bg-[#15171c] text-[#d3d3d3]"
          />
          <Button onClick={() => { setEditingUser(null); setIsDialogOpen(true); }} className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4">
            <Plus size={16} className="mr-2" />
            Add User
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="text-[#d3d3d3]">Avatar</TableHead>
              <TableHead className="text-[#d3d3d3]">Username</TableHead>
              <TableHead className="text-[#d3d3d3]">Level</TableHead>
              <TableHead className="text-[#d3d3d3]">Rank Status</TableHead>
              <TableHead className="text-[#d3d3d3]">Battle Limit</TableHead>
              <TableHead className="text-[#d3d3d3]">Total Battles</TableHead>
              <TableHead className="text-[#d3d3d3]">Coins Earned</TableHead>
              <TableHead className="text-[#d3d3d3]">Referrals</TableHead>
              <TableHead className="text-[#d3d3d3]">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredUsers.map((user) => (
              <TableRow key={user._id} className="hover:bg-black">
                <TableCell>
                  <Avatar>
                    <AvatarImage src={user.avatar} alt={user.username} />
                    <AvatarFallback>{user.username ? user.username[0] : ''}</AvatarFallback>
                  </Avatar>
                </TableCell>
                <TableCell className="text-[#d3d3d3]">{user.username}</TableCell>
                <TableCell className="text-[#d3d3d3]">{user.level}</TableCell>
                <TableCell className="text-[#d3d3d3]">{user.rankStatus}</TableCell>
                <TableCell className="text-[#d3d3d3]">{user.battleLimit}</TableCell>
                <TableCell className="text-[#d3d3d3]">{user.totalBattles}</TableCell>
                <TableCell className="text-[#d3d3d3]">{user.coinsEarned}</TableCell>
                <TableCell className="text-[#d3d3d3]">{user.referrals}</TableCell>
                <TableCell>
                  <div className="flex space-x-2">
                    <Button onClick={() => { setEditingUser(user); setIsDialogOpen(true); }} className="p-2 bg-black text-white rounded-[30px]">
                      <Edit size={16} />
                    </Button>
                    <Button onClick={() => handleDelete(user._id)} className="p-2 bg-black text-white rounded-[30px]">
                      <Trash2 size={16} />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="bg-[#15171c]">
          <DialogHeader>
            <DialogTitle className="text-[#eff0f2]">{editingUser ? 'Edit User' : 'Add New User'}</DialogTitle>
          </DialogHeader>
          <UserForm
            user={editingUser}
            onSave={handleSave}
            onCancel={() => setIsDialogOpen(false)}
          />
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default UsersManagement;