import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { BarChart } from "../components/ui/BarChart";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { getBrandStatistics } from '../services/brandService';
import { Star, StarHalf, BarChart2, Globe, LineChart, Share2 } from 'lucide-react';

const BrandStatistics = ({ brandId }) => {
  // Состояния остаются те же
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedComparisonBrand, setSelectedComparisonBrand] = useState('');
  const [selectedComparisonCountry, setSelectedComparisonCountry] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('all');

  // Эффект для загрузки данных остается тот же
  useEffect(() => {
    const fetchStats = async () => {
      if (!brandId) {
        setLoading(false);
        return;
      }

      try {
        const data = await getBrandStatistics(brandId);
        if (!data) throw new Error('No data received');

        const statsData = {
          totalBattles: data.totalBattles || 0,
          totalWins: data.totalWins || 0,
          rating: data.rating || 0,
          websiteClicks: data.websiteClicks || 0,
          socialClicks: data.socialClicks || [],
          comparisons: data.comparisons || [],
          countryStats: data.countryStats || []
        };

        setStats(statsData);
        if (statsData.comparisons.length > 0) {
          setSelectedComparisonBrand(statsData.comparisons[0].competitorBrand);
        }
        setError(null);
      } catch (error) {
        console.error('Error fetching brand statistics:', error);
        setError('Failed to fetch brand statistics. Please try again later.');
        setStats(null);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [brandId]);

  if (loading) return <div className="text-center py-4 text-white">Loading...</div>;
  if (error) return <div className="text-center py-4 text-red-500">{error}</div>;
  if (!stats) return <div className="text-center py-4 text-white">No statistics available for this brand.</div>;

  const selectedComparison = stats.comparisons.find(c => c.competitorBrand === selectedComparisonBrand);

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < fullStars) {
        stars.push(<Star key={i} className="text-[#e0ff89] inline w-4 h-4" />);
      } else if (i === fullStars && hasHalfStar) {
        stars.push(<StarHalf key={i} className="text-[#e0ff89] inline w-4 h-4" />);
      } else {
        stars.push(<Star key={i} className="text-gray-400 inline w-4 h-4" />);
      }
    }
    return stars;
  };

  return (
    <Card className="bg-[#1a1a1a] text-white rounded-[20px] overflow-hidden">
      <CardHeader className="bg-[#282828] px-6 py-4">
        <CardTitle className="text-xl font-bold flex items-center gap-2">
          <BarChart2 className="w-5 h-5 text-[#e0ff89]" />
          Brand Statistics
        </CardTitle>
      </CardHeader>
      
      <CardContent className="p-6 space-y-6">
        {/* Общая статистика и выбор страны */}
        <div className="flex justify-between items-start">
          <div className="space-y-1">
            <p className="text-[#e0ff89] text-sm flex items-center gap-2">
              <Globe className="w-4 h-4" />
              Region Filter
            </p>
            <Select
              value={selectedCountry}
              onValueChange={setSelectedCountry}
            >
              <SelectTrigger className="w-40 bg-black text-white border-gray-600">
                <SelectValue placeholder="All Countries" />
              </SelectTrigger>
              <SelectContent className="bg-[#1a1a1a] border-gray-600">
                <SelectItem value="all">All Countries</SelectItem>
                {stats.countryStats.map((stat) => (
                  <SelectItem key={stat.country} value={stat.country}>
                    <div className="flex items-center gap-2">
                      <span>{stat.country}</span>
                    </div>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          
          <div className="text-center">
            <p className="text-[#e0ff89] text-sm mb-1">Overall Rating</p>
            <div className="flex items-center gap-1">
              {renderStars(stats.rating)}
            </div>
            <p className="text-2xl font-bold mt-1">{stats.rating.toFixed(2)}</p>
          </div>
        </div>

        {/* Основные показатели */}
        <div className="grid grid-cols-3 gap-4">
          <div className="bg-[#282828] p-4 rounded-xl">
            <p className="text-[#e0ff89] text-sm mb-2">Total Battles</p>
            <p className="text-2xl font-bold">{stats.totalBattles}</p>
          </div>
          <div className="bg-[#282828] p-4 rounded-xl">
            <p className="text-[#e0ff89] text-sm mb-2">Total Wins</p>
            <p className="text-2xl font-bold">{stats.totalWins}</p>
          </div>
          <div className="bg-[#282828] p-4 rounded-xl">
            <p className="text-[#e0ff89] text-sm mb-2">Win Rate</p>
            <p className="text-2xl font-bold">
              {((stats.totalWins / stats.totalBattles) * 100).toFixed(1)}%
            </p>
          </div>
        </div>

        {/* Сравнение с другими брендами */}
        <div>
          <p className="text-[#e0ff89] text-sm flex items-center gap-2 mb-3">
            <LineChart className="w-4 h-4" />
            Brand Comparison
          </p>
          
          <Select
            value={selectedComparisonBrand}
            onValueChange={(brand) => {
              setSelectedComparisonBrand(brand);
              setSelectedComparisonCountry('');
            }}
          >
            <SelectTrigger className="w-full bg-black text-white border-gray-600 mb-4">
              <SelectValue placeholder="Select a brand to compare" />
            </SelectTrigger>
            <SelectContent className="bg-[#1a1a1a] border-gray-600">
              {stats.comparisons.map((comp) => (
                <SelectItem key={comp.competitorBrand} value={comp.competitorBrand}>
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center gap-2">
                      {comp.competitorLogo && (
                        <img
                          src={comp.competitorLogo}
                          alt={comp.competitorBrand}
                          className="w-6 h-6 rounded-full"
                        />
                      )}
                      <span>{comp.competitorBrand}</span>
                    </div>
                    <div className="text-[#e0ff89]">
                      W: {comp.wins} L: {comp.losses}
                    </div>
                  </div>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          {selectedComparison && (
            <div className="space-y-4">
              {/* Общая статистика сравнения */}
              <div className="bg-[#282828] p-4 rounded-xl">
                <div className="flex justify-between items-center mb-4">
                  <div>
                    <h4 className="font-medium">{selectedComparison.competitorBrand}</h4>
                    <p className="text-[#e0ff89] text-sm">
                      Win Rate: {((selectedComparison.wins / (selectedComparison.wins + selectedComparison.losses)) * 100).toFixed(1)}%
                    </p>
                  </div>
                  <div className="text-right">
                    <p className="text-sm">
                      W: <span className="text-[#e0ff89]">{selectedComparison.wins}</span>
                    </p>
                    <p className="text-sm">
                      L: <span className="text-red-400">{selectedComparison.losses}</span>
                    </p>
                  </div>
                </div>
                <div className="h-48">
                  <BarChart
                    data={[
                      { name: 'Wins', value: selectedComparison.wins },
                      { name: 'Losses', value: selectedComparison.losses }
                    ]}
                  />
                </div>
              </div>

              {/* Статистика по странам */}
              {selectedComparison.countryStats && selectedComparison.countryStats.length > 0 && (
                <div className="bg-[#282828] p-4 rounded-xl">
                  <div className="flex justify-between items-center mb-4">
                    <p className="text-[#e0ff89] text-sm flex items-center gap-2">
                      <Globe className="w-4 h-4" />
                      Country Statistics
                    </p>
                    <Select
                      value={selectedComparisonCountry}
                      onValueChange={setSelectedComparisonCountry}
                    >
                      <SelectTrigger className="w-40 bg-black text-white border-gray-600">
                        <SelectValue placeholder="Select country" />
                      </SelectTrigger>
                      <SelectContent className="bg-[#1a1a1a] border-gray-600">
                        {selectedComparison.countryStats.map((stat) => (
                          <SelectItem key={stat.country} value={stat.country}>
                            <div className="flex items-center justify-between w-full">
                              <span>{stat.country}</span>
                              <div className="text-[#e0ff89]">
                                W: {stat.wins} L: {stat.losses}
                              </div>
                            </div>
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>

                  {selectedComparisonCountry && (
                    <div>
                      <div className="h-48">
                        <BarChart
                          data={[
                            {
                              name: 'Wins',
                              value: selectedComparison.countryStats.find(
                                s => s.country === selectedComparisonCountry
                              )?.wins || 0
                            },
                            {
                              name: 'Losses',
                              value: selectedComparison.countryStats.find(
                                s => s.country === selectedComparisonCountry
                              )?.losses || 0
                            }
                          ]}
                        />
                      </div>
                      <p className="text-center text-[#e0ff89] text-sm mt-2">
                        Win Rate: {((selectedComparison.countryStats.find(
                          s => s.country === selectedComparisonCountry
                        )?.wins / (selectedComparison.countryStats.find(
                          s => s.country === selectedComparisonCountry
                        )?.wins + selectedComparison.countryStats.find(
                          s => s.country === selectedComparisonCountry
                        )?.losses)) * 100).toFixed(1)}%
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        {/* Статистика социальных сетей */}
        <div>
          <p className="text-[#e0ff89] text-sm flex items-center gap-2 mb-3">
            <Share2 className="w-4 h-4" />
            Social Media Activity
          </p>
          <div className="bg-[#282828] p-4 rounded-xl">
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <p className="text-sm mb-1">Website Clicks</p>
                <p className="text-2xl font-bold">{stats.websiteClicks}</p>
              </div>
              {stats.socialClicks.map((social, index) => (
                <div key={index}>
                  <p className="text-sm mb-1">{social.platform}</p>
                  <p className="text-2xl font-bold">{social.clicks}</p>
                </div>
              ))}
            </div>
            <div className="h-48">
              <BarChart
                data={[
                  { name: 'Website', value: stats.websiteClicks },
                  ...stats.socialClicks.map(sm => ({
                    name: sm.platform,
                    value: sm.clicks
                  }))
                ]}
              />
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default BrandStatistics;