import * as React from "react"
import { BarChart as TremorBarChart } from "@tremor/react"

export function BarChart({ data }) {
  return (
    <div className="w-full h-[200px]">
      <TremorBarChart
        className="h-full"
        data={data}
        index="name"
        categories={["value"]}
        colors={["pink"]}
        yAxisWidth={48}
        showLegend={false}
        showXAxis={true}
        showYAxis={true}
        showGridLines={false}
        startEndOnly={true}
        valueFormatter={(value) => `${value}`}
        cornerRadius={8}  // Добавляем закругление углов
      />
    </div>
  )
}