import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import authReducer from './reducers/authReducer';
import { composeWithDevTools } from 'redux-devtools-extension';

// Создаем middleware для логирования
const logger = store => next => action => {
  console.group(action.type);
  console.info('dispatching', action);
  let result = next(action);
  console.log('next state', store.getState());
  console.groupEnd();
  return result;
};

const rootReducer = combineReducers({
  auth: authReducer,
  // Добавьте здесь другие редьюсеры, если они есть
});

// Начальное состояние
const initialState = {
  auth: {
    user: null,
    loading: false,
    error: null
  }
};

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunk, logger))
);

export default store;