import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BarChart2, Box, Grid, Globe, Users, CheckSquare, Star, HelpCircle, UserCircle, Settings, ChevronDown, Ticket } from 'lucide-react';
import { useSelector } from 'react-redux';

const Sidebar = () => {
  const location = useLocation();
  const [expandedMenuItem, setExpandedMenuItem] = useState(null);
  const currentUser = useSelector((state) => state.auth.user);

  const menuItems = [
    { name: 'Dashboard', icon: <BarChart2 size={20} />, path: '/admin' },
    { name: 'Brands', icon: <Box size={20} />, path: '/admin/brands' },
    { name: 'Categories', icon: <Grid size={20} />, path: '/admin/categories' },
    { name: 'Countries', icon: <Globe size={20} />, path: '/admin/countries' },
    {
      name: 'Tasks',
      icon: <CheckSquare size={20} />,
      path: '/admin/tasks',
      subItems: [
        { name: 'All Tasks', path: '/admin/tasks' },
        { name: 'Video', path: '/admin/tasks/video' },
        { name: 'Social', path: '/admin/tasks/social' },
        { name: 'Special', path: '/admin/tasks/special' },
        { name: 'Referrals', path: '/admin/tasks/referrals' },
      ],
    },
    { name: 'Ranks', icon: <Star size={20} />, path: '/admin/ranks' },
    { name: 'FAQ', icon: <HelpCircle size={20} />, path: '/admin/faq' },
    {
      name: 'Accounts',
      icon: <UserCircle size={20} />,
      path: '/admin/accounts',
      subItems: [
        { name: 'Users', path: '/admin/accounts/users' },
        { name: 'Admins', path: '/admin/accounts/admins' },
        { name: 'Brand Users', path: '/admin/accounts/brand-users' },
      ],
    },
    { name: 'Tickets', icon: <Ticket size={20} />, path: '/admin/tickets' },
    { name: 'Settings', icon: <Settings size={20} />, path: '/admin/settings' },
  ];

  const toggleSubMenu = (menuItemName) => {
    setExpandedMenuItem(expandedMenuItem === menuItemName ? null : menuItemName);
  };

  const isMenuItemActive = (path) => {
    return location.pathname === path;
  };

 return (
  <div className="bg-black bg-opacity-80 backdrop-filter backdrop-blur-lg text-white p-6 rounded-2xl fixed top-[50px] bottom-[50px] left-[50px] w-[300px] overflow-hidden">
    <div className="h-full flex flex-col">
      <Link to="/admin" className="text-2xl font-bold mb-8 block">
        battle brands.
      </Link>
      <nav className="flex-1 overflow-y-auto pr-2">
        {menuItems.map((item, index) => (
          <div key={index} className="mb-2">
            <Link
              to={item.path}
              className={`flex items-center justify-between p-3 rounded hover:bg-white hover:bg-opacity-10 ${isMenuItemActive(item.path) ? 'bg-white bg-opacity-10' : ''
                }`}
              onClick={() => toggleSubMenu(item.name)}
            >
              <div className="flex items-center">
                {item.icon}
                <span className="ml-3">{item.name}</span>
              </div>
              {item.subItems && <ChevronDown size={20} className={expandedMenuItem === item.name ? 'transform rotate-180' : ''} />}
            </Link>
            {item.subItems && expandedMenuItem === item.name && (
              <div className="ml-6 mt-2">
                {item.subItems.map((subItem, subIndex) => (
                  <Link
                    key={subIndex}
                    to={subItem.path}
                    className={`block p-3 rounded hover:bg-white hover:bg-opacity-10 ${isMenuItemActive(subItem.path) ? 'bg-white bg-opacity-10' : ''}`}
                  >
                    {subItem.name}
                  </Link>
                ))}
              </div>
            )}
          </div>
        ))}
      </nav>
      <div className="mt-auto">
        <Link
          to="/admin/profile"
          className="flex items-center p-4 bg-white bg-opacity-10 rounded-lg hover:bg-opacity-20"
        >
          {currentUser && currentUser.avatar ? (
            <img src={currentUser.avatar} alt="Admin" className="w-6 h-6 rounded-full mr-3" />
          ) : (
            <UserCircle size={24} className="mr-3" />
          )}
          <span>{currentUser ? currentUser.name : 'Admin'}</span>
        </Link>
      </div>
    </div>
  </div>
);
};

export default Sidebar;