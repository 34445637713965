import React, { useMemo, useState, useEffect } from 'react';

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color;
  do {
    color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
  } while (color === '#000000' || parseInt(color.slice(1), 16) < 0x333333);
  return color;
};

const BrandAvatar = ({ brand, isSelected, onClick, remainingBattles, children }) => {
  const gradientColors = useMemo(() => {
    return [getRandomColor(), getRandomColor(), getRandomColor()];
  }, []);

  // Генерируем уникальный ID
  const uniqueId = useMemo(() => Math.random().toString(36).substr(2, 9), []);

  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    if (isClicked) {
      const timer = setTimeout(() => setIsClicked(false), 500);
      return () => clearTimeout(timer);
    }
  }, [isClicked]);

  const handleClick = () => {
    setIsClicked(true);
    onClick();
  };

  return (
    <div
      className={`brand-avatar-container ${isSelected ? 'selected' : ''} ${remainingBattles === 0 ? 'opacity-50 pointer-events-none' : ''}`}
      onClick={handleClick}
    >
      <svg width="100%" height="100%" viewBox="0 0 100 100">
        <defs>
          <linearGradient id={`gradient-${uniqueId}`} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor={gradientColors[0]}>
              <animate attributeName="offset" values="0;1;0" dur="5s" repeatCount="indefinite" />
            </stop>
            <stop offset="50%" stopColor={gradientColors[1]}>
              <animate attributeName="offset" values="0;1;0" dur="5s" repeatCount="indefinite" />
            </stop>
            <stop offset="100%" stopColor={gradientColors[2]}>
              <animate attributeName="offset" values="0;1;0" dur="5s" repeatCount="indefinite" />
            </stop>
          </linearGradient>
          <filter id={`glow-${uniqueId}`}>
            <feGaussianBlur stdDeviation="2" result="coloredBlur" />
            <feMerge>
              <feMergeNode in="coloredBlur" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
        <g filter={`url(#glow-${uniqueId})`}>
          <rect
            x="5"
            y="5"
            width="90"
            height="90"
            fill="none"
            stroke={`url(#gradient-${uniqueId})`}
            strokeWidth="5"
            rx="45"
            ry="45"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 50 50"
              to="360 50 50"
              dur="10s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="stroke-width"
              values="5;6;5"
              dur="1.5s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
      </svg>
      <div className="brand-avatar">
        {children}
      </div>
    </div>
  );
};

export default BrandAvatar;