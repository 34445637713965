import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../animations/loading.json';

const LoadingScreen = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#000000',
      zIndex: 9999,
      pointerEvents: 'none',
      opacity: 1,
      transition: 'opacity 0.5s ease-in-out'
    }}>
      <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
        <img 
          src="/images/general/logo-loading.svg" 
          alt="Logo" 
          style={{ 
            width: '100px', 
            height: '100px', 
            objectFit: 'contain'
          }} 
        />
      </div>
      <div style={{ 
  marginBottom: '0px',
  width: '100vw',
  display: 'flex',
  justifyContent: 'center',
  overflow: 'hidden'
}}>
  <Lottie 
    options={defaultOptions} 
    height={300} 
    width={'100%'} 
    style={{
      minWidth: '100vw',
      objectFit: 'cover'
    }}
  />
</div>
    </div>
  );
};

export default LoadingScreen;