const telegramService = {
  initDataUnsafe: {},

  init() {
    if (window.Telegram && window.Telegram.WebApp) {
      this.initDataUnsafe = window.Telegram.WebApp.initDataUnsafe || {};
      window.Telegram.WebApp.ready();
    }
  },

  getInitData() {
    if (window.Telegram?.WebApp?.initData) {
      return window.Telegram.WebApp.initData;
    }
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('tgWebAppData') || '';
  },

  getUserInfo() {
    return this.initDataUnsafe.user || null;
  },

  getStartParam() {
    if (window.Telegram?.WebApp?.initDataUnsafe?.start_param) {
      return window.Telegram.WebApp.initDataUnsafe.start_param;
    }
    return '';
  },

  close() {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.close();
    }
  }
};

export default telegramService;