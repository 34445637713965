import {
    UPDATE_ADMIN_PROFILE_REQUEST,
    UPDATE_ADMIN_PROFILE_SUCCESS,
    UPDATE_ADMIN_PROFILE_FAILURE,
    FETCH_ADMIN_PROFILE_REQUEST,
    FETCH_ADMIN_PROFILE_SUCCESS,
    FETCH_ADMIN_PROFILE_FAILURE
} from '../actions/adminActions';

const initialState = {
    user: (() => {
        try {
            const userData = localStorage.getItem('user');
            return userData ? JSON.parse(userData) : null;
        } catch (error) {
            console.error('Error parsing user data from localStorage:', error);
            return null;
        }
    })(),
    loading: false,
    error: null
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ADMIN_PROFILE_REQUEST:
        case UPDATE_ADMIN_PROFILE_REQUEST:
            return { ...state, loading: true };
        case FETCH_ADMIN_PROFILE_SUCCESS:
        case UPDATE_ADMIN_PROFILE_SUCCESS:
            try {
                localStorage.setItem('user', JSON.stringify(action.payload));
            } catch (error) {
                console.error('Error saving user data to localStorage:', error);
            }
            return { ...state, loading: false, user: action.payload, error: null };
        case FETCH_ADMIN_PROFILE_FAILURE:
        case UPDATE_ADMIN_PROFILE_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default authReducer;