import React from 'react';

const Switch = React.forwardRef(({ checked, onCheckedChange, className, ...props }, ref) => (
  <button
    type="button"
    role="switch"
    aria-checked={checked}
    className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none ${
      checked ? 'bg-[#e0ff89]' : 'bg-gray-200'
    } ${className}`}
    onClick={() => onCheckedChange(!checked)}
    ref={ref}
    {...props}
  >
    <span
      className={`inline-block h-4 w-4 transform rounded-full bg-black transition-transform ${
        checked ? 'translate-x-6' : 'translate-x-1'
      }`}
    />
  </button>
));

Switch.displayName = 'Switch';

export { Switch };