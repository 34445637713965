import React, { useState, useRef, useEffect } from 'react';

// Создаем кэш для загруженных изображений
const imageCache = new Set();

// Функция для предварительной загрузки изображения
const preloadImage = (src) => {
  return new Promise((resolve, reject) => {
    if (imageCache.has(src)) {
      resolve();
      return;
    }

    const img = new Image();
    img.src = src;
    img.onload = () => {
      imageCache.add(src);
      sessionStorage.setItem(`image-${src}`, 'loaded');
      resolve();
    };
    img.onerror = reject;
  });
};

const OptimizedImage = ({ src, alt, className, priority = false }) => {
  const isInitiallyLoaded = imageCache.has(src) || sessionStorage.getItem(`image-${src}`) === 'loaded';
  const [isLoaded, setIsLoaded] = useState(isInitiallyLoaded);
  const imageRef = useRef();

  useEffect(() => {
    // Если изображение уже загружено, не делаем ничего
    if (isInitiallyLoaded) {
      return;
    }

    const loadImage = async () => {
      try {
        await preloadImage(src);
        setIsLoaded(true);
      } catch (error) {
        console.error('Error loading image:', error);
      }
    };

    if (priority) {
      loadImage();
    } else {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            loadImage();
            observer.unobserve(entry.target);
          }
        },
        {
          rootMargin: '100px',
          threshold: 0.1
        }
      );

      if (imageRef.current) {
        observer.observe(imageRef.current);
      }

      return () => {
        if (imageRef.current) {
          observer.unobserve(imageRef.current);
        }
      };
    }
  }, [src, priority, isInitiallyLoaded]);

  const imageStyle = {
    opacity: isInitiallyLoaded || isLoaded ? 1 : 0,
    filter: isInitiallyLoaded || isLoaded ? 'none' : 'blur(10px)',
    transform: isInitiallyLoaded || isLoaded ? 'scale(1)' : 'scale(1.1)',
    transition: isInitiallyLoaded ? 'none' : 'opacity 0.5s ease, filter 0.5s ease, transform 0.5s ease'
  };

  const placeholderStyle = {
    backgroundColor: '#1a1a1a',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: isInitiallyLoaded || isLoaded ? 0 : 1,
    transition: isInitiallyLoaded ? 'none' : 'opacity 0.5s ease'
  };

  return (
    <div ref={imageRef} className={`${className} relative overflow-hidden`}>
      <div style={placeholderStyle} />
      <img
        src={src}
        alt={alt}
        className="w-full h-full object-cover"
        style={imageStyle}
        loading={priority ? "eager" : "lazy"}
      />
    </div>
  );
};

export const PreloadImages = ({ images = [] }) => {
  useEffect(() => {
    const preloadAll = async () => {
      try {
        const unloadedImages = images.filter(
          src => !imageCache.has(src) && !sessionStorage.getItem(`image-${src}`)
        );
        
        await Promise.all(
          unloadedImages.map(src => preloadImage(src))
        );
      } catch (error) {
        console.error('Error preloading images:', error);
      }
    };

    preloadAll();
  }, [images]);

  return null;
};

export default OptimizedImage;