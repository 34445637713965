import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import { Card, CardContent } from "../ui/card";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "../ui/dialog";
import { Pencil, Trash2 } from "lucide-react";
import { Label } from "../ui/label";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const FAQForm = ({ faq, onSave, onCancel }) => {
    const [formData, setFormData] = useState(faq || {
        id: Date.now(),
        question: '',
        answer: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleQuillChange = (value) => {
        setFormData(prev => ({ ...prev, answer: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(formData);
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <div>
                <Label htmlFor="question" className="text-[#d3d3d3]">Question</Label>
                <Input name="question" value={formData.question} onChange={handleChange} placeholder="Question" required className="bg-[#15171c] text-[#d3d3d3]" />
            </div>
            <div>
                <Label htmlFor="answer" className="text-[#d3d3d3]">Answer</Label>
                <ReactQuill
                    value={formData.answer}
                    onChange={handleQuillChange}
                    placeholder="Write your answer here..."
                    modules={{
                        toolbar: [
                            [{ 'header': [1, 2, false] }],
                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                            ['link', 'image'],
                            ['clean']
                        ],
                    }}
                    className="bg-[#15171c] text-[#d3d3d3]"
                />
            </div>
            <div className="flex justify-end space-x-2">
                <Button type="button" onClick={onCancel} variant="outline" className="bg-black text-white rounded-[30px] py-1 px-4">Cancel</Button>
                <Button type="submit" className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4">Save FAQ</Button>
            </div>
        </form>
    );
};

const FAQManagement = () => {
    const [faqs, setFaqs] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [editingFAQ, setEditingFAQ] = useState(null);
    const [tabName, setTabName] = useState('FAQ');

    useEffect(() => {
        const storedFAQs = JSON.parse(localStorage.getItem('faqs')) || [];
        setFaqs(storedFAQs);
        const storedTabName = localStorage.getItem('faqTabName') || 'FAQ';
        setTabName(storedTabName);
    }, []);

    const handleSave = (faqData) => {
        setFaqs(prevFAQs => {
            let updatedFAQs;
            if (editingFAQ) {
                updatedFAQs = prevFAQs.map(f => f.id === editingFAQ.id ? faqData : f);
            } else {
                updatedFAQs = [...prevFAQs, faqData];
            }
            localStorage.setItem('faqs', JSON.stringify(updatedFAQs));
            return updatedFAQs;
        });
        setIsDialogOpen(false);
        setEditingFAQ(null);
    };

    const handleDelete = (id) => {
        setFaqs(prevFAQs => {
            const updatedFAQs = prevFAQs.filter(f => f.id !== id);
            localStorage.setItem('faqs', JSON.stringify(updatedFAQs));
            return updatedFAQs;
        });
    };

    const handleTabNameChange = (e) => {
        const newTabName = e.target.value;
        setTabName(newTabName);
        localStorage.setItem('faqTabName', newTabName);
    };

    return (
        <Card className="bg-transparent">
            <CardContent className="p-6">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold text-[#eff0f2]">FAQ</h2>
                    <Button onClick={() => setIsDialogOpen(true)} className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4">Add New FAQ</Button>
                </div>
                <div className="mb-4">
                    <Label htmlFor="tabName" className="block text-sm font-medium text-[#d3d3d3]">Tab Name on Main Page</Label>
                    <Input
                        id="tabName"
                        value={tabName}
                        onChange={handleTabNameChange}
                        className="mt-1 bg-[#15171c] text-[#d3d3d3]"
                        placeholder="Enter tab name"
                    />
                </div>
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead className="text-[#d3d3d3]">Question</TableHead>
                            <TableHead className="text-[#d3d3d3]">Answer</TableHead>
                            <TableHead className="text-[#d3d3d3]">Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {faqs.map(faq => (
                            <TableRow key={faq.id} className="hover:bg-black">
                                <TableCell className="text-[#d3d3d3]">{faq.question}</TableCell>
                                <TableCell className="text-[#d3d3d3]">{faq.answer.substring(0, 50)}...</TableCell>
                                <TableCell>
                                    <div className="flex space-x-2">
                                        <Button onClick={() => { setEditingFAQ(faq); setIsDialogOpen(true); }} className="p-2 bg-black text-white rounded-[30px]">
                                            <Pencil size={16} />
                                        </Button>
                                        <Button onClick={() => handleDelete(faq.id)} className="p-2 bg-black text-white rounded-[30px]">
                                            <Trash2 size={16} />
                                        </Button>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                    <DialogContent className="bg-[#15171c]">
                        <DialogHeader>
                            <DialogTitle className="text-[#eff0f2]">{editingFAQ ? 'Edit FAQ' : 'Add New FAQ'}</DialogTitle>
                            <DialogDescription className="text-[#d3d3d3]">
                                {editingFAQ ? 'Edit the details of the FAQ.' : 'Enter the details of the new FAQ.'}
                            </DialogDescription>
                        </DialogHeader>
                        <FAQForm
                            faq={editingFAQ}
                            onSave={handleSave}
                            onCancel={() => setIsDialogOpen(false)}
                        />
                    </DialogContent>
                </Dialog>
            </CardContent>
        </Card>
    );
};

export default FAQManagement;