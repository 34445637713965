import React, { useEffect, useState } from 'react';

const ProfileRewardAnimation = ({ onClose }) => {
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeOut(true);
      setTimeout(onClose, 500);
    }, 3000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className={`fixed inset-0 flex items-center justify-center z-[100] ${fadeOut ? 'animate-fadeOut' : ''}`}>
      <div className="absolute inset-0 bg-black bg-opacity-80 backdrop-blur-sm"></div>
      
      <div className="relative flex flex-col items-center perspective-1000">
        <div className="absolute inset-0">
          <div className="absolute top-0 left-0 w-full h-1 bg-[#e0ff89] animate-scanline"></div>
          <div className="absolute bottom-0 right-0 w-1 h-full bg-[#e0ff89] animate-scanline-vertical"></div>
        </div>

        <div className="relative bg-transparent rounded-[30px] p-8 transform-gpu animate-float">
          <div className="absolute inset-0 bg-gradient-to-r from-[#e0ff89] to-[#b8fcff] opacity-10 animate-pulse rounded-[30px]"></div>
          
          <div className="relative w-48 h-48 mx-auto mb-6">
            <div className="absolute inset-0 bg-[#e0ff89] opacity-20 animate-pulse-slow clip-hexagon"></div>
            <div className="absolute inset-2 bg-black clip-hexagon flex items-center justify-center">
              <div className="absolute inset-0 animate-spin-slow">
                {[...Array(6)].map((_, i) => (
                  <div
                    key={i}
                    className="absolute w-0.5 h-full bg-[#e0ff89] opacity-20 origin-center"
                    style={{ transform: `rotate(${i * 60}deg)` }}
                  ></div>
                ))}
              </div>
              
              <div className="relative transform animate-bounce-gentle">
                <div className="w-20 h-20 rounded-full bg-[#e0ff89] flex items-center justify-center">
                  <img src="/images/general/coin.svg" alt="Coin" className="w-16 h-16" />
                </div>
                <div className="absolute inset-0 bg-white opacity-50 rounded-full animate-ping"></div>
              </div>
            </div>
          </div>

          <div className="text-center relative z-10">
            <h2 className="text-2xl font-bold text-[#e0ff89] mb-2 animate-glitch">
              Profile Completed!
            </h2>
            <div className="flex items-center justify-center text-3xl font-bold text-white animate-scale-up">
              <span className="mr-2">+200</span>
              <img src="/images/general/coin.svg" alt="coin" className="w-8 h-8" />
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .perspective-1000 {
          perspective: 1000px;
        }

        .clip-hexagon {
          clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
        }

        @keyframes scanline {
          0% { transform: translateY(0); opacity: 0; }
          50% { opacity: 1; }
          100% { transform: translateY(100vh); opacity: 0; }
        }

        @keyframes scanline-vertical {
          0% { transform: translateX(0); opacity: 0; }
          50% { opacity: 1; }
          100% { transform: translateX(100vw); opacity: 0; }
        }

        @keyframes float {
          0%, 100% { transform: translateY(0) rotateX(10deg); }
          50% { transform: translateY(-10px) rotateX(15deg); }
        }

        @keyframes pulse-slow {
          0%, 100% { opacity: 0.2; }
          50% { opacity: 0.4; }
        }

        @keyframes spin-slow {
          from { transform: rotate(0deg); }
          to { transform: rotate(360deg); }
        }

        @keyframes bounce-gentle {
          0%, 100% { transform: translateY(-2px); }
          50% { transform: translateY(2px); }
        }

        @keyframes glitch {
          0% { text-shadow: 2px 2px #e0ff89, -2px -2px #b8fcff; }
          25% { text-shadow: -2px 2px #e0ff89, 2px -2px #b8fcff; }
          50% { text-shadow: 2px -2px #e0ff89, -2px 2px #b8fcff; }
          75% { text-shadow: -2px -2px #e0ff89, 2px 2px #b8fcff; }
          100% { text-shadow: 2px 2px #e0ff89, -2px -2px #b8fcff; }
        }

        @keyframes scale-up {
          0% { transform: scale(0.8); opacity: 0; }
          100% { transform: scale(1); opacity: 1; }
        }

        .animate-scanline {
          animation: scanline 2s linear infinite;
        }

        .animate-scanline-vertical {
          animation: scanline-vertical 2s linear infinite;
        }

        .animate-float {
          animation: float 4s ease-in-out infinite;
        }

        .animate-pulse-slow {
          animation: pulse-slow 3s ease-in-out infinite;
        }

        .animate-spin-slow {
          animation: spin-slow 10s linear infinite;
        }

        .animate-bounce-gentle {
          animation: bounce-gentle 2s ease-in-out infinite;
        }

        .animate-glitch {
          animation: glitch 0.3s infinite;
        }

        .animate-scale-up {
          animation: scale-up 0.5s ease-out forwards;
        }

        .animate-fadeOut {
          animation: fadeOut 0.5s forwards;
        }

        @keyframes fadeOut {
          from { opacity: 1; }
          to { opacity: 0; }
        }
      `}</style>
    </div>
  );
};

export default ProfileRewardAnimation;