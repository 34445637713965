import axios from 'axios';

// Получение общей статистики бренда
export const getBrandStatistics = async (brandId) => {
  try {
    const response = await axios.get(`/api/brands/${brandId}/statistics`);
    return response.data;
  } catch (error) {
    console.error('Error fetching brand statistics:', error);
    throw error;
  }
};

// Получение демографической статистики
export const getBrandDemographics = async (brandId, params = {}) => {
  try {
    const { startDate, endDate, country } = params;
    let url = `/api/brands/${brandId}/demographics`;
    
    const queryParams = new URLSearchParams();
    if (startDate) queryParams.append('startDate', startDate);
    if (endDate) queryParams.append('endDate', endDate);
    if (country) queryParams.append('country', country);
    
    if (queryParams.toString()) {
      url += `?${queryParams.toString()}`;
    }
    
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching brand demographics:', error);
    throw error;
  }
};

// Получение статистики сравнений  
export const getComparisonStatistics = async (brandId) => {
  try {
    const response = await axios.get(`/api/brands/${brandId}/comparisons`);
    return response.data;
  } catch (error) {
    console.error('Error fetching comparison statistics:', error);
    throw error; 
  }
};

// Получение статистики за период
export const getBrandStatisticsByPeriod = async (brandId, period, startDate, endDate) => {
  try {
    let url = `/api/brands/${brandId}/statistics/${period}`;
    if (startDate && endDate) {
      url += `?startDate=${startDate}&endDate=${endDate}`;
    }
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching period statistics:', error);
    throw error;
  }
};

// Получение статистики по конкретной стране
export const getBrandCountryStatistics = async (brandId, country) => {
  try {
    const response = await axios.get(`/api/brands/${brandId}/statistics/${country}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching country statistics:', error);
    throw error;
  }
};

// Получение аналитики с группировкой
export const getBrandAnalytics = async (brandId, params = {}) => {
  try {
    const { startDate, endDate, groupBy = 'day', gender, ageRange } = params;
    const queryParams = new URLSearchParams({
      groupBy,
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
      ...(gender && { gender }),
      ...(ageRange && { ageRange })
    });
    
    const response = await axios.get(`/api/brands/${brandId}/analytics?${queryParams}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching brand analytics:', error);
    throw error;
  }
};

// Форматирование данных для графиков
export const formatTimelineData = (data, type) => {
  if (!data || !data.battles) return [];

  return Object.entries(data.battles).map(([date, battles]) => ({
    date,
    value: type === 'battles' 
      ? battles.length
      : battles.filter(b => b.result === 'win').length,
    demographics: battles.reduce((acc, battle) => {
      const { gender, ageRange } = battle.userDemographics || {};
      if (gender) {
        acc.genderStats[gender] = (acc.genderStats[gender] || 0) + 1;
      }
      if (ageRange) {
        acc.ageStats[ageRange] = (acc.ageStats[ageRange] || 0) + 1;
      }
      return acc;
    }, { genderStats: {}, ageStats: {} })
  }));
};

// Форматирование данных по странам
export const formatCountryData = (countryStats) => {
  if (!countryStats) return [];
  
  return countryStats.map(stat => ({
    country: stat.country,
    totalBattles: stat.totalBattles, 
    winRate: stat.totalBattles > 0 ? (stat.wins / stat.totalBattles * 100).toFixed(2) : 0,
    rating: stat.rating,
    demographics: {
      genderStats: stat.demographics?.genderStats || [],
      ageStats: stat.demographics?.ageStats || []
    }
  }));
};

// Форматирование демографических данных
export const formatDemographicStats = (stats) => {
  if (!stats) return { genderStats: [], ageStats: [] };

  const formatGroup = (group) => {
    return Object.entries(group).map(([key, value]) => ({
      type: key,
      total: value.total,
      wins: value.wins,
      losses: value.losses,
      winRate: value.total > 0 ? ((value.wins / value.total) * 100).toFixed(2) : '0'
    }));
  };

  return {
    genderStats: formatGroup(stats.genderStats),
    ageStats: formatGroup(stats.ageStats)
  };
};

// Группировка данных по периоду
export const groupDataByPeriod = (data, periodType) => {
  if (!Array.isArray(data)) return [];

  const periods = {
    day: d => d.toISOString().split('T')[0],
    week: d => {
      const date = new Date(d);
      date.setHours(0, 0, 0, 0);
      date.setDate(date.getDate() - date.getDay());
      return date.toISOString().split('T')[0];
    },
    month: d => d.toISOString().slice(0, 7),
    year: d => d.toISOString().slice(0, 4)
  };

  return Object.entries(
    data.reduce((acc, item) => {
      const period = periods[periodType](new Date(item.timestamp));
      if (!acc[period]) {
        acc[period] = {
          period,
          battles: 0,
          wins: 0,
          socialClicks: 0,
          websiteClicks: 0,
          demographics: {
            genderStats: {},
            ageStats: {}
          }
        };
      }

      // Обновляем основную статистику
      if (item.type === 'battle') {
        acc[period].battles++;
        if (item.result === 'win') acc[period].wins++;

        // Обновляем демографическую статистику
        const { gender, ageRange } = item.userDemographics || {};
        if (gender) {
          if (!acc[period].demographics.genderStats[gender]) {
            acc[period].demographics.genderStats[gender] = { total: 0, wins: 0, losses: 0 };
          }
          acc[period].demographics.genderStats[gender].total++;
          if (item.result === 'win') {
            acc[period].demographics.genderStats[gender].wins++;
          } else {
            acc[period].demographics.genderStats[gender].losses++;
          }
        }

        if (ageRange) {
          if (!acc[period].demographics.ageStats[ageRange]) {
            acc[period].demographics.ageStats[ageRange] = { total: 0, wins: 0, losses: 0 };
          }
          acc[period].demographics.ageStats[ageRange].total++;
          if (item.result === 'win') {
            acc[period].demographics.ageStats[ageRange].wins++;
          } else {
            acc[period].demographics.ageStats[ageRange].losses++;
          }
        }
      } else if (item.type === 'social') {
        acc[period].socialClicks++;
      } else if (item.type === 'website') {
        acc[period].websiteClicks++;
      }

      return acc;
    }, {})
  ).map(([_, value]) => value);
};

export default {
  getBrandStatistics,
  getComparisonStatistics, 
  getBrandStatisticsByPeriod,
  getBrandCountryStatistics,
  getBrandAnalytics,
  getBrandDemographics,
  formatTimelineData,
  formatCountryData,
  formatDemographicStats,
  groupDataByPeriod
};