import React, { useState, useEffect } from 'react';
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import { Label } from "./ui/label";
import axios from 'axios';
import { Upload, X } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { SiDiscord, SiFacebook, SiInstagram, SiKakaotalk, SiLine, SiLinkedin, SiMedium, SiOdnoklassniki, SiOnlyfans, SiPatreon, SiPinterest, SiTencentqq, SiReddit, SiSnapchat, SiTelegram, SiThreads, SiTiktok, SiTumblr, SiTwitch, SiViber, SiVk, SiWechat, SiSinaweibo, SiWhatsapp, SiX, SiYoutube } from 'react-icons/si';
import { useDropzone } from 'react-dropzone';
import { Switch } from "./ui/switch"
import { useNavigate } from 'react-router-dom';
import { TonConnect } from '@tonconnect/sdk';
import { TonConnectButton, useTonAddress, useTonWallet, CHAIN, useTonConnectUI } from '@tonconnect/ui-react';

const socialIcons = {
  Discord: SiDiscord,
  Facebook: SiFacebook,
  Instagram: SiInstagram,
  KakaoTalk: SiKakaotalk,
  Line: SiLine,
  LinkedIn: SiLinkedin,
  Medium: SiMedium,
  Ok: SiOdnoklassniki,
  Onlyfans: SiOnlyfans,
  Patreon: SiPatreon,
  Pinterest: SiPinterest,
  QQ: SiTencentqq,
  Reddit: SiReddit,
  Snapchat: SiSnapchat,
  Telegram: SiTelegram,
  Threads: SiThreads,
  TikTok: SiTiktok,
  Tumblr: SiTumblr,
  Twitch: SiTwitch,
  Viber: SiViber,
  Vk: SiVk,
  WeChat: SiWechat,
  Weibo: SiSinaweibo,
  WhatsApp: SiWhatsapp,
  X: SiX,
  YouTube: SiYoutube,
};

const AddBrand = ({ brand, onSave, onCancel, connectedWalletAddress }) => {
const [formData, setFormData] = useState(brand || {
  name: '',
  logo: '',
  description: '',
  categories: [{ category: '', subCategory: '', subSubCategory: '' }],
  country: '',
  global: true,
  frequency: 0.1,
  website: '',
  socialMedia: [],
  periodType: '',
  activeDays: 0, // Добавляем поле для количества дней
  finalPrice: { // Добавляем финальную цену
    usd: 0,
    ton: 0
  }
});

  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isDoubleFrequency, setIsDoubleFrequency] = useState(false);
  const [selectedSocialMedia, setSelectedSocialMedia] = useState('');
  const [logoOptions, setLogoOptions] = useState([]);
  const [logoPreview, setLogoPreview] = useState('');
  const [nameError, setNameError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('');
  const [paymentUrl, setPaymentUrl] = useState('');
  const [price, setPrice] = useState({ ton: 0, usd: 0 });
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showSubscription, setShowSubscription] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState(null);
  const wallet = useTonWallet();
  const address = useTonAddress();
  const [tonConnectUI] = useTonConnectUI();
  const [tonRate, setTonRate] = useState(null);
  const navigate = useNavigate();

  // Инициализация TON Connect
  useEffect(() => {
    let isComponentMounted = true;

    const initializeTON = async () => {
      try {
        // Проверяем подключенный кошелек
        if (wallet && connectedWalletAddress) {
          setIsWalletConnected(true);
          setWalletAddress(connectedWalletAddress);
          console.log('Using connected wallet:', {
            wallet,
            address: connectedWalletAddress
          });
          return;
        }

        // Если нет подключенного кошелька, проверяем сохраненную сессию
        const savedSession = localStorage.getItem('ton-wallet-session');
        if (savedSession) {
          const session = JSON.parse(savedSession);
          setIsWalletConnected(true);
          setWalletAddress(session.address);
          console.log('Restored wallet session:', session);
          return;
        }

      } catch (error) {
        console.error('Error initializing TON:', error);
        setIsWalletConnected(false);
        setWalletAddress(null);
      }
    };

    initializeTON();

    // Подписываемся на события кошелька
    const handleWalletUpdate = (wallet) => {
      if (!isComponentMounted) return;

      if (wallet) {
        setIsWalletConnected(true);
        setWalletAddress(wallet.account.address);
        // Сохраняем сессию
        localStorage.setItem('ton-wallet-session', JSON.stringify({
          address: wallet.account.address,
          timestamp: Date.now()
        }));
      } else {
        setIsWalletConnected(false);
        setWalletAddress(null);
        localStorage.removeItem('ton-wallet-session');
      }
    };

    if (wallet) {
      handleWalletUpdate(wallet);
    }

    return () => {
      isComponentMounted = false;
    };
  }, [wallet, connectedWalletAddress]);

  useEffect(() => {
    if (wallet && connectedWalletAddress) {
      console.log('Wallet connected:', { wallet, address: connectedWalletAddress });
      setIsWalletConnected(true);
      setWalletAddress(connectedWalletAddress);
    } else {
      console.log('Wallet not connected');
      setIsWalletConnected(false);
      setWalletAddress(null);
    }
  }, [wallet, connectedWalletAddress]);

  useEffect(() => {
    if (brand) {
      const newFormData = {
        ...brand,
        category: brand.category?._id || brand.category || '',
        subCategory: brand.subCategory || '',
        country: brand.country?._id || (Array.isArray(brand.countries) && brand.countries.length > 0 ? brand.countries[0]._id : '') || brand.country || '',
      };
      setFormData(newFormData);
      setLogoPreview(brand.logo || '');
      fetchLogoFromAPI(brand.name);
      console.log('Brand data:', brand);
      console.log('New form data:', newFormData);
    }
  }, [brand]);

  // Добавляем функцию получения курса TON
useEffect(() => {
  const fetchTonRate = async () => {
    try {
      const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=the-open-network&vs_currencies=usd');
      const rate = response.data['the-open-network'].usd;
      setTonRate(rate);
      console.log('Current TON rate:', rate);
    } catch (error) {
      console.error('Error fetching TON rate:', error);
      // Используем запасной вариант если API недоступен
      setTonRate(6.67);
    }
  };

  fetchTonRate();
  // Обновляем курс каждые 5 минут
  const interval = setInterval(fetchTonRate, 5 * 60 * 1000);
  
  return () => clearInterval(interval);
}, []);

  useEffect(() => {
    if (categories.length > 0 && countries.length > 0 && formData.id) {
      console.log('Categories:', categories);
      console.log('Countries:', countries);
      console.log('Current formData:', formData);

      const updatedFormData = {
        ...formData,
        category: formData.category || categories.find(c => c.name === formData.category || c._id === formData.category)?._id || '',
        country: formData.country || countries.find(c => c.name === formData.country || c._id === formData.country)?._id || ''
      };

      console.log('Updated formData:', updatedFormData);
      setFormData(updatedFormData);
    }
  }, [categories, countries, formData.id]);

  const logFormDataChanges = (changes) => {
    console.log('Form data changed:', changes);
  };

  useEffect(() => {
    fetchCategories();
    fetchCountries();
  }, []);

  useEffect(() => {
    // Проверяем доступность Telegram Web App при монтировании
    if (!window.Telegram?.WebApp) {
      console.error('Telegram WebApp not available');
      toast.error('Please open this page in Telegram');
    } else {
      console.log('Telegram WebApp available:', {
        version: window.Telegram.WebApp.version,
        platform: window.Telegram.WebApp.platform,
        initData: window.Telegram.WebApp.initData ? 'present' : 'missing'
      });
    }
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get('/api/categories');
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await axios.get('/api/countries');
      setCountries(response.data);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const getSubCategories = (categoryId) => {
    const category = categories.find(c => c._id === categoryId);
    return category?.subCategories || [];
  };

  const getSubSubCategories = (categoryId, subCategory) => {
    const category = categories.find(c => c._id === categoryId);
    const subCat = category?.subCategories?.find(sc => sc.name === subCategory);
    return subCat?.subSubCategories || [];
  };

  const handleChange = async (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData(prev => {
      const newFormData = { ...prev, [name]: newValue };
      logFormDataChanges({ [name]: newValue });
      return newFormData;
    });

    if (name === 'name' && !brand) {
      fetchLogoFromAPI(value);
      try {
        const response = await axios.get(`/api/brands/check/${value}`);
        if (response.data.exists) {
          setNameError('A brand with this name already exists');
        } else {
          setNameError('');
        }
      } catch (error) {
        console.error('Error checking brand name:', error);
      }
    }
  };

  const fetchLogoFromAPI = async (brandName) => {
    try {
      const response = await axios.get(`https://autocomplete.clearbit.com/v1/companies/suggest?query=${encodeURIComponent(brandName)}`);
      if (response.data && response.data.length > 0) {
        const logos = response.data.map(company => company.logo).filter(logo => logo);
        setLogoOptions(logos);
      } else {
        setLogoOptions([]);
      }
    } catch (error) {
      console.error('Error fetching logo from API:', error);
      setLogoOptions([]);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 100 * 1024) {
        alert('File size should not exceed 100KB');
        return;
      }
      if (!['image/jpeg', 'image/webp'].includes(file.type)) {
        alert('Only JPEG and WEBP files are allowed');
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = function () {
          if (this.width > 300 || this.height > 300) {
            alert('The image size should not exceed 300x300 pixels');
          } else {
            setFormData(prev => ({ ...prev, logo: e.target.result }));
          }
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { 'image/jpeg': ['.jpg', '.jpeg'], 'image/webp': ['.webp'] },
    maxSize: 100 * 1024, // 100KB
    onDrop: acceptedFiles => {
      handleImageUpload(acceptedFiles[0]);
    }
  });

const handleSubmit = async (e) => {
  e.preventDefault();
  console.log('=== Starting form submission ===');

  if (submitting) {
    console.log('Submit already in progress, aborting');
    return;
  }

  try {
    setSubmitting(true);
    console.log('Form Data:', formData);

    // Проверяем наличие адреса доставки
    const userResponse = await axios.get('/api/users/me');
    if (!userResponse.data.defaultShipping) {
      toast.error('Please add shipping address in your profile before adding a brand');
      navigate('/profile'); // Перенаправляем на профиль для заполнения адреса
      return;
    }

    // Проверяем подключение кошелька
    if (!wallet) {
      console.error('No wallet instance found');
      toast.error('Please connect your TON wallet first');
      return;
    }

    console.log('Wallet connected:', { 
      account: wallet.account,
      address: wallet.account?.address,
      chain: wallet.account?.chain,
      publicKey: wallet.account?.publicKey
    });

    // Проверяем выбран ли период подписки
    if (!formData.periodType) {
      toast.error('Please select subscription period');
      return;
    }

    // Создаем платеж
    const paymentRequest = {
      periodType: formData.periodType,
      fromAddress: wallet.account.address,
      amount: formData.finalPrice.ton,
      userId: formData.userId
    };

    console.log('Creating payment with data:', paymentRequest);
    const paymentResponse = await axios.post('/api/payments/create', paymentRequest);

    if (!paymentResponse.data.success) {
      console.error('Failed to create payment:', paymentResponse.data);
      throw new Error('Failed to create payment');
    }

    const paymentData = paymentResponse.data.payment;
    console.log('Payment created:', paymentData);

    // Конвертируем сумму в наноТОНы
    const amountInNano = Math.round(paymentData.amount * 1000000000);
    console.log('Amount in nano:', amountInNano);

    setPaymentStatus('pending');
    toast.loading('Preparing transaction...', { id: 'transaction' });

    // Создаем транзакцию
    const transaction = {
      validUntil: Math.floor(Date.now() / 1000) + 300,
      messages: [
        {
          address: paymentData.toAddress,
          amount: amountInNano.toString()
        }
      ]
    };

    console.log('Sending transaction:', transaction);

    // Используем tonConnectUI для отправки транзакции
    const result = await tonConnectUI.sendTransaction(transaction);

    console.log('Transaction sent, result:', result);

    // Ждем подтверждения транзакции
    if (result) {
      // Подтверждаем платеж на бэкенде
      const confirmationResult = await axios.post('/api/payments/confirm', {
        paymentId: paymentData.id,
        transactionHash: result.boc
      });

      if (!confirmationResult.data.success) {
        throw new Error('Payment confirmation failed');
      }

      setPaymentStatus('completed');
      toast.success('Payment completed!', { id: 'transaction' });

      // Создаем бренд после успешной оплаты
      const brandData = {
        ...formData,
        periodType: formData.periodType,
        activeDays: formData.activeDays,
        paymentDetails: {
          transactionHash: result.boc,
          amount: formData.finalPrice.ton,
          periodType: formData.periodType
        }
      };

      const brandResponse = await axios.post('/api/brands/user', brandData);

      if (!brandResponse.data.success) {
        throw new Error('Failed to create brand');
      }

      console.log('Brand created:', brandResponse.data);
      toast.success('Brand created successfully!');
      
      // Вызываем колбэк успешного создания
      onSave && onSave(brandResponse.data.brand);

    } else {
      throw new Error('Transaction was not confirmed');
    }

  } catch (error) {
    console.error('Form submission error:', error);
    setPaymentStatus('failed');
    toast.error(error.message || 'An error occurred', { id: 'transaction' });

  } finally {
    setSubmitting(false);
    console.log('=== Form submission completed ===');
  }
};

  // Обработчик выбора подписки
  const handleSubscriptionSelect = (details) => {
    setSubscriptionDetails(details);
  };

  // Обработчик отмены подписки
  const handleSubscriptionCancel = () => {
    setShowSubscription(false);
    setSubscriptionDetails(null);
  };


useEffect(() => {
  const calculatePrice = () => {
    if (!formData.categories || !categories || !tonRate) {
      return { ton: 0, usd: 0 };
    }

    try {
      let basePrice = formData.categories.reduce((sum, catObj) => {
        const category = categories.find(cat => cat._id === catObj.category);
        return sum + (category?.price || 0);
      }, 0);

      const usdPrice = isDoubleFrequency ? Math.max(499, basePrice * 2) : basePrice;
      // Используем актуальный курс TON для конвертации
      const tonPrice = +(usdPrice / tonRate).toFixed(2);

      return {
        ton: tonPrice,
        usd: usdPrice
      };
    } catch (error) {
      console.error('Error calculating price:', error);
      return { ton: 0, usd: 0 };
    }
  };

  const newPrice = calculatePrice();
  console.log('Calculated price:', newPrice);
  setPrice(newPrice);
}, [formData.categories, categories, isDoubleFrequency, tonRate]);

  const handleFrequencyChange = (checked) => {
    setIsDoubleFrequency(checked);
    setFormData(prev => ({ ...prev, frequency: checked ? 0.2 : 0.1 }));
  };

  const handleCategoryChange = (selectedCategory, index) => {
    console.log('Selected category:', selectedCategory);
    const category = categories.find(cat => cat._id === selectedCategory);
    if (category) {
      setFormData(prev => {
        const newCategories = [...prev.categories];
        newCategories[index] = { ...newCategories[index], category: selectedCategory };
        return { ...prev, categories: newCategories };
      });
      setSelectedCategories(prev => {
        const isAlreadySelected = prev.some(cat => cat._id === category._id);
        if (isAlreadySelected) {
          return prev.filter(cat => cat._id !== category._id);
        } else {
          return [...prev, category];
        }
      });
    }
  };

  const addSocialMedia = () => {
    if (selectedSocialMedia && !formData.socialMedia.find(sm => sm.platform === selectedSocialMedia)) {
      setFormData(prev => ({
        ...prev,
        socialMedia: [...prev.socialMedia, { platform: selectedSocialMedia, url: '' }]
      }));
      setSelectedSocialMedia('');
    }
  };

  const updateSocialMediaUrl = (platform, url) => {
    setFormData(prev => ({
      ...prev,
      socialMedia: prev.socialMedia.map(sm =>
        sm.platform === platform ? { ...sm, url } : sm
      )
    }));
  };

  const removeSocialMedia = (platform) => {
    setFormData(prev => ({
      ...prev,
      socialMedia: prev.socialMedia.filter(sm => sm.platform !== platform)
    }));
  };

  const handleSubCategoryChange = (selectedSubCategory) => {
    setFormData(prev => ({ ...prev, subCategory: selectedSubCategory }));
  };

  const getSubCategoryOptions = (categoryId) => {
    const selectedCategory = categories.find(cat => cat._id === categoryId);
    if (selectedCategory && selectedCategory.subCategories) {
      return selectedCategory.subCategories.map(subCategory => ({ value: subCategory, label: subCategory }));
    }
    return [];
  };

  const inputClasses = "bg-black text-[#d3d3d3] border-[#282828] border-[3px] placeholder-[#d3d3d3]";

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="flex space-x-4">
        <div className="w-2/3 space-y-2">
          <Label htmlFor="name" className="text-white">Brand Name</Label>
          <div>
            <Input
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter brand name"
              required
              className={`${inputClasses} ${nameError ? 'border-red-500' : ''}`}
            />
            {nameError && <p className="text-red-500 text-sm mt-1">{nameError}</p>}
          </div>
          {logoOptions.length > 0 && (
            <div>
              <Label className="text-white">Logo Options</Label>
              <div className="grid grid-cols-5 gap-2">
                {logoOptions.slice(0, 10).map((logo, index) => (
                  <div
                    key={index}
                    className="w-full h-20 rounded-lg border-2 border-black overflow-hidden flex items-center justify-center cursor-pointer"
                    onClick={() => {
                      setLogoPreview(logo);
                      setFormData(prev => ({ ...prev, logo }));
                    }}
                  >
                    <img src={logo} alt={`Logo option ${index + 1}`} className="max-w-full max-h-full object-contain" />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="w-1/3">
          <Label className="text-white">Logo Preview</Label>
          <div className="relative">
            <div className="border-2 border-black rounded-full p-1">
              <div className="w-32 h-32 rounded-full overflow-hidden flex items-center justify-center bg-gray-200">
                {logoPreview ? (
                  <img src={logoPreview} alt="Logo preview" className="max-w-full max-h-full object-contain" />
                ) : (
                  <p className="text-gray-500 text-sm">No logo</p>
                )}
              </div>
            </div>
            {logoPreview && (
              <button
                type="button"
                className="absolute top-0 right-0 p-1 bg-red-500 text-white rounded-full hover:bg-red-600 focus:outline-none"
                onClick={() => {
                  setLogoPreview(null);
                  setFormData(prev => ({ ...prev, logo: null }));
                }}
              >
                <span className="sr-only">Remove logo</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <Label className="text-white">Logo (Max 300x300px, 100KB, JPEG/WEBP)</Label>
        <div {...getRootProps()} className={`p-4 border-2 border-dashed rounded-md ${isDragActive ? 'border-[#e0ff89]' : 'border-[#282828]'} flex flex-col items-center justify-center`}>
          <input {...getInputProps()} />
          <Upload className={`w-12 h-12 mb-2 ${isDragActive ? 'text-[#e0ff89]' : 'text-[#d3d3d3]'}`} />
          {
            isDragActive ?
              <p className="text-[#e0ff89]">Drop the file here ...</p> :
              <p className="text-[#d3d3d3]">Drag 'n' drop logo here, or click to select file</p>
          }
        </div>
      </div>

      <div>
        <Label htmlFor="description">Description</Label>
        <textarea
          id="description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          maxLength={200}
          className={`w-full p-2 rounded ${inputClasses}`}
          rows={4}
        />
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="categories" className="text-white">Categories</Label>
          {formData.categories.map((cat, index) => (
            <div key={index} className="flex flex-col space-y-2">
              {/* Основная категория */}
              <Select
                value={cat.category || ''}
                onValueChange={(value) => {
                  const newCategories = [...formData.categories];
                  newCategories[index] = {
                    category: value,
                    subCategory: '',
                    subSubCategory: ''
                  };
                  setFormData(prev => ({ ...prev, categories: newCategories }));
                }}
              >
                <SelectTrigger className={`w-full ${inputClasses}`}>
                  <SelectValue placeholder="Select Category">
                    {categories.find(c => c._id === cat.category)?.name || "Select Category"}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent className="bg-[#0f1014] text-[#d3d3d3] z-[100]">
                  {categories.map(category => (
                    <SelectItem key={category._id} value={category._id}>{category.name}</SelectItem>
                  ))}
                </SelectContent>
              </Select>

              {/* Подкатегория */}
              {cat.category && (
                <Select
                  value={cat.subCategory || ''}
                  onValueChange={(value) => {
                    const newCategories = [...formData.categories];
                    newCategories[index] = {
                      ...newCategories[index],
                      subCategory: value,
                      subSubCategory: ''
                    };
                    setFormData(prev => ({ ...prev, categories: newCategories }));
                  }}
                >
                  <SelectTrigger className={`w-full ${inputClasses}`}>
                    <SelectValue placeholder="Select Sub-Category">
                      {cat.subCategory || "Select Sub-Category"}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent className="bg-[#0f1014] text-[#d3d3d3] z-[100]">
                    {getSubCategories(cat.category).map(subCat => (
                      <SelectItem key={subCat.name} value={subCat.name}>
                        {subCat.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}

              {/* Под-подкатегория */}
              {cat.subCategory && (
                <Select
                  value={cat.subSubCategory || ''}
                  onValueChange={(value) => {
                    const newCategories = [...formData.categories];
                    newCategories[index] = {
                      ...newCategories[index],
                      subSubCategory: value
                    };
                    setFormData(prev => ({ ...prev, categories: newCategories }));
                  }}
                >
                  <SelectTrigger className={`w-full ${inputClasses}`}>
                    <SelectValue placeholder="Select Sub-Sub-Category">
                      {cat.subSubCategory || "Select Sub-Sub-Category"}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent className="bg-[#0f1014] text-[#d3d3d3] z-[100]">
                    {getSubSubCategories(cat.category, cat.subCategory).map(subSubCat => (
                      <SelectItem key={subSubCat.name} value={subSubCat.name}>
                        {subSubCat.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}

              {/* Кнопка удаления категории */}
              {index > 0 && (
                <Button
                  type="button"
                  onClick={() => {
                    const newCategories = formData.categories.filter((_, i) => i !== index);
                    setFormData(prev => ({ ...prev, categories: newCategories }));
                  }}
                  className="bg-red-500 text-white hover:bg-red-600 rounded-full self-end"
                >
                  <X size={16} />
                </Button>
              )}
            </div>
          ))}

          {/* Кнопка добавления категории */}
          <Button
            type="button"
            onClick={() => {
              setFormData(prev => ({
                ...prev,
                categories: [...prev.categories, { category: '', subCategory: '', subSubCategory: '' }]
              }));
            }}
            className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4 mt-2"
          >
            Add Category
          </Button>
        </div>
      </div>

      <div>
        <Label htmlFor="country" className="text-white">Country</Label>
        <Select
          id="country"
          name="country"
          value={formData.country || ''}
          onValueChange={(value) => {
            console.log('Selected country:', value);
            setFormData(prev => ({ ...prev, country: value }));
          }}
        >
          <SelectTrigger className={`w-full ${inputClasses}`}>
            <SelectValue placeholder="Select Country">
              {countries.find(c => c._id === formData.country)?.name || formData.country || "Select Country"}
            </SelectValue>
          </SelectTrigger>
          <SelectContent className="bg-[#0f1014] text-[#d3d3d3] z-[100] max-h-[200px] overflow-y-auto">
            {countries.map(country => (
              <SelectItem key={country._id} value={country._id}>{country.name}</SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="flex items-center space-x-2">
        <Switch
          id="doubleFrequency"
          checked={isDoubleFrequency}
          onCheckedChange={handleFrequencyChange}
        />
        <Label htmlFor="doubleFrequency">x2 (Double Number of Shows)</Label>
      </div>

      <div>
        <Label htmlFor="website">Website</Label>
        <Input
          id="website"
          name="website"
          value={formData.website}
          onChange={handleChange}
          className={inputClasses}
        />
      </div>

      <div className="space-y-2">
        <Label className="text-white">Social Media</Label>
        <div className="flex space-x-2">
          <select
            value={selectedSocialMedia}
            onChange={(e) => {
              const platform = e.target.value;
              if (platform && !formData.socialMedia.find(sm => sm.platform === platform)) {
                setFormData(prev => ({
                  ...prev,
                  socialMedia: [...prev.socialMedia, { platform, url: '' }]
                }));
              }
              setSelectedSocialMedia('');
            }}
            className={`w-full ${inputClasses} p-2 rounded`}
          >
            <option value="">Select Social Media</option>
            {Object.keys(socialIcons)
              .filter(platform => !formData.socialMedia.find(sm => sm.platform === platform))
              .map(platform => (
                <option key={platform} value={platform}>{platform}</option>
              ))}
          </select>
        </div>
        <div className="space-y-2 mt-2">
          {formData.socialMedia.map(sm => {
            const Icon = socialIcons[sm.platform];
            return (
              <div key={sm.platform} className="flex items-center space-x-2">
                <Icon className="w-8 h-8 text-[#e0ff89]" />
                <Input
                  value={sm.url}
                  onChange={(e) => updateSocialMediaUrl(sm.platform, e.target.value)}
                  placeholder={`Enter ${sm.platform} URL`}
                  className={`flex-grow ${inputClasses}`}
                />
                <Button
                  type="button"
                  onClick={() => removeSocialMedia(sm.platform)}
                  className="p-2 bg-transparent hover:bg-transparent text-red-300 rounded-full hover:text-red-500"
                >
                  <X size={16} />
                </Button>
              </div>
            );
          })}
        </div>
      </div>

<div className="mt-6 space-y-4">
  <div className="text-center space-y-4">
    <h3 className="text-xl font-semibold">Select Subscription Period</h3>
    
<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
      {/* 1 месяц */}
      <div 
        className={`p-4 border-2 rounded-lg cursor-pointer transition-all relative text-center ${
          formData.periodType === 'month' 
            ? 'border-[#e0ff89] bg-[#1a1a1a]' 
            : 'border-[#282828] hover:border-[#e0ff89]'
        }`}
        onClick={() => {
          const monthlyPrice = price.usd;
          const monthlyTon = price.ton;
          setFormData(prev => ({ 
            ...prev, 
            periodType: 'month',
            activeDays: 30,
            finalPrice: {
              usd: monthlyPrice,
              ton: monthlyTon
            }
          }))
        }}
      >
        <h4 className="font-bold text-center">1 Month</h4>
        <p className="text-2xl font-bold text-white text-center">
          ${Math.floor(price.usd)}
        </p>
        <p className="text-sm text-[#e0ff89] text-center">
          ({price.ton.toFixed(2)} TON)
        </p>
      </div>

      {/* 3 месяца */}
      <div 
        className={`p-4 border-2 rounded-lg cursor-pointer transition-all relative text-center ${
          formData.periodType === 'three_months' 
            ? 'border-[#e0ff89] bg-[#1a1a1a]' 
            : 'border-[#282828] hover:border-[#e0ff89]'
        }`}
        onClick={() => {
          const price3Month = price.usd * 3 * 0.9;
          const price3MonthTon = price.ton * 3 * 0.9;
          setFormData(prev => ({ 
            ...prev, 
            periodType: 'three_months',
            activeDays: 90,
            finalPrice: {
              usd: price3Month,
              ton: price3MonthTon
            }
          }))
        }}
      >
        {/* Скидочный бейдж */}
        <div className="absolute -top-2 -right-2 bg-[#e0ff89] text-black px-2 py-1 rounded-lg transform rotate-12 text-xs font-bold">
          -10%
        </div>
        
        <h4 className="font-bold text-center">3 Months</h4>
        <div className="text-2xl font-bold text-white flex items-center justify-center gap-2">
          <span className="line-through opacity-50">${Math.floor(price.usd * 3)}</span>
          <span>${Math.floor(price.usd * 3 * 0.9)}</span>
        </div>
        <p className="text-sm text-[#e0ff89] text-center">
          ({(price.ton * 3 * 0.9).toFixed(2)} TON)
        </p>
      </div>

      {/* 6 месяцев */}
      <div 
        className={`p-4 border-2 rounded-lg cursor-pointer transition-all relative text-center ${
          formData.periodType === 'six_months' 
            ? 'border-[#e0ff89] bg-[#1a1a1a]' 
            : 'border-[#282828] hover:border-[#e0ff89]'
        }`}
        onClick={() => {
          const price6Month = price.usd * 6 * 0.8;
          const price6MonthTon = price.ton * 6 * 0.8;
          setFormData(prev => ({ 
            ...prev, 
            periodType: 'six_months',
            activeDays: 180,
            finalPrice: {
              usd: price6Month,
              ton: price6MonthTon
            }
          }))
        }}
      >
        {/* Скидочный бейдж */}
        <div className="absolute -top-2 -right-2 bg-[#e0ff89] text-black px-2 py-1 rounded-lg transform rotate-12 text-xs font-bold">
          -20%
        </div>
        
        <h4 className="font-bold text-center">6 Months</h4>
        <div className="text-2xl font-bold text-white flex items-center justify-center gap-2">
          <span className="line-through opacity-50">${Math.floor(price.usd * 6)}</span>
          <span>${Math.floor(price.usd * 6 * 0.8)}</span>
        </div>
        <p className="text-sm text-[#e0ff89] text-center">
          ({(price.ton * 6 * 0.8).toFixed(2)} TON)
        </p>
      </div>

      {/* 1 год */}
      <div 
        className={`p-4 border-2 rounded-lg cursor-pointer transition-all relative text-center ${
          formData.periodType === 'year' 
            ? 'border-[#e0ff89] bg-[#1a1a1a]' 
            : 'border-[#282828] hover:border-[#e0ff89]'
        }`}
        onClick={() => {
          const priceYear = price.usd * 12 * 0.7;
          const priceYearTon = price.ton * 12 * 0.7;
          setFormData(prev => ({ 
            ...prev, 
            periodType: 'year',
            activeDays: 365,
            finalPrice: {
              usd: priceYear,
              ton: priceYearTon
            }
          }))
        }}
      >
        {/* Скидочный бейдж */}
        <div className="absolute -top-2 -right-2 bg-[#e0ff89] text-black px-2 py-1 rounded-lg transform rotate-12 text-xs font-bold">
          -30%
        </div>
        
        <h4 className="font-bold text-center">1 Year</h4>
        <div className="text-2xl font-bold text-white flex items-center justify-center gap-2">
          <span className="line-through opacity-50">${Math.floor(price.usd * 12)}</span>
          <span>${Math.floor(price.usd * 12 * 0.7)}</span>
        </div>
        <p className="text-sm text-[#e0ff89] text-center">
          ({(price.ton * 12 * 0.7).toFixed(2)} TON)
        </p>
      </div>
</div>

    <p className="text-sm text-gray-400 mt-1">
      {isDoubleFrequency ? "Includes 2x brand visibility" : "Standard visibility"}
    </p>
  </div>

  <div className="flex justify-end space-x-2">
    <Button
      type="button"
      onClick={onCancel}
      variant="outline" 
      className="bg-black text-white border-[#282828] hover:bg-white hover:text-black transition-colors duration-200 rounded-[30px] py-1 px-4"
    >
      Cancel
    </Button>
    <Button
      type="submit"
      className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4"
      disabled={submitting || paymentStatus === 'pending' || !walletAddress || !formData.periodType}
    >
      {submitting ? 'Processing...' :
        !walletAddress ? 'Connect Wallet First' :
        !formData.periodType ? 'Select Period' :
        paymentStatus === 'pending' ? 'Waiting for Payment...' :
        'Pay and Create Brand'}
    </Button>
  </div>
</div>

      {/* Статусы платежа */}
      {paymentStatus === 'pending' && (
        <div className="mt-4 text-center">
          <p className="text-[#e0ff89]">Please confirm the transaction in your TON wallet.</p>
        </div>
      )}

      {paymentStatus === 'completed' && (
        <div className="mt-4 text-center">
          <p className="text-[#e0ff89]">Payment completed successfully! Creating your brand...</p>
        </div>
      )}

      {paymentStatus === 'failed' && (
        <div className="mt-4 text-center">
          <p className="text-red-500">Payment failed. Please try again.</p>
        </div>
      )}

      {paymentStatus === 'timeout' && (
        <div className="mt-4 text-center">
          <p className="text-yellow-500">Payment timeout. Please try again if necessary.</p>
        </div>
      )}
    </form>
  );
};

export default AddBrand;