import axios from 'axios';

export const FETCH_ADMIN_PROFILE_REQUEST = 'FETCH_ADMIN_PROFILE_REQUEST';
export const FETCH_ADMIN_PROFILE_SUCCESS = 'FETCH_ADMIN_PROFILE_SUCCESS';
export const FETCH_ADMIN_PROFILE_FAILURE = 'FETCH_ADMIN_PROFILE_FAILURE';
export const UPDATE_ADMIN_PROFILE_REQUEST = 'UPDATE_ADMIN_PROFILE_REQUEST';
export const UPDATE_ADMIN_PROFILE_SUCCESS = 'UPDATE_ADMIN_PROFILE_SUCCESS';
export const UPDATE_ADMIN_PROFILE_FAILURE = 'UPDATE_ADMIN_PROFILE_FAILURE';

// Создаем экземпляр axios с базовым URL
const api = axios.create({
  baseURL: '/api',
});

// Добавляем интерцептор для установки токена
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  console.log('Request config:', config); // Добавим для отладки
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const fetchAdminProfile = () => async (dispatch) => {
    dispatch({ type: FETCH_ADMIN_PROFILE_REQUEST });
    try {
        console.log('Fetching admin profile...'); // Добавим для отладки
        const response = await api.get('/admin/profile');
        console.log('Admin profile response:', response.data); // Добавим для отладки
        dispatch({
            type: FETCH_ADMIN_PROFILE_SUCCESS,
            payload: response.data
        });
    } catch (error) {
        console.error('Error fetching admin profile:', error);
        dispatch({
            type: FETCH_ADMIN_PROFILE_FAILURE,
            payload: error.response?.data?.message || 'Failed to fetch profile'
        });
        if (error.response && error.response.status === 401) {
            console.log('Unauthorized, redirecting to login...'); // Добавим для отладки
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            window.location.href = '/admin/login';
        }
    }
};

export const updateAdminProfile = (profileData) => async (dispatch) => {
    dispatch({ type: UPDATE_ADMIN_PROFILE_REQUEST });
    try {
        const response = await api.put('/admin/profile', profileData);
        dispatch({
            type: UPDATE_ADMIN_PROFILE_SUCCESS,
            payload: response.data
        });
    } catch (error) {
        dispatch({
            type: UPDATE_ADMIN_PROFILE_FAILURE,
            payload: error.response?.data?.message || 'Failed to update profile'
        });
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            window.location.href = '/admin/login';
        }
    }
};