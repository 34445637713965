import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateAdminProfile, fetchAdminProfile } from '../../redux/actions/adminActions';
import { Card, CardContent } from '../ui/card';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { Label } from '../ui/label';
import ImageUpload from '../ui/ImageUpload';
import axios from 'axios';
import { Pencil } from 'lucide-react';

// Добавляем функцию для получения токена из localStorage
const getToken = () => localStorage.getItem('token');

// Создаем экземпляр axios с настроенными заголовками
const api = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${getToken()}`
  }
});

// Добавляем интерцептор для обновления токена при каждом запросе
api.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${getToken()}`;
  return config;
});

const AdminProfile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user: admin, loading, error } = useSelector(state => state.auth);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [isEditingName, setIsEditingName] = useState(false);
    const [isEditingEmail, setIsEditingEmail] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [avatar, setAvatar] = useState('');
    const [avatarPreview, setAvatarPreview] = useState('');

    useEffect(() => {
        dispatch(fetchAdminProfile())
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    handleLogout();
                }
            });
    }, [dispatch]);

    useEffect(() => {
        console.log('Admin data in component:', admin);
        if (admin) {
            setName(admin.name || '');
            setEmail(admin.email || '');
            setAvatar(admin.avatar || '');
            setAvatarPreview(admin.avatar || '');
        }
    }, [admin]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        console.log('Token in AdminProfile:', token); // Добавим для отладки
        if (!token) {
            console.log('No token found, redirecting to login...'); // Добавим для отладки
            navigate('/admin/login');
            return;
        }
        dispatch(fetchAdminProfile())
            .catch(error => {
                console.error('Error in AdminProfile:', error);
                if (error.response && error.response.status === 401) {
                    handleLogout();
                }
            });
    }, [dispatch, navigate]);

    const handleImageUpload = async (file) => {
        const formData = new FormData();
        formData.append('image', file);
        try {
            const response = await api.post('/admin/upload-avatar', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            const imageUrl = response.data.imageUrl;
            setAvatarPreview(imageUrl);
            setAvatar(imageUrl);
            dispatch(updateAdminProfile({ avatar: imageUrl }));
        } catch (error) {
            console.error('Error uploading image:', error);
            if (error.response && error.response.status === 401) {
                handleLogout();
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            alert("New passwords don't match");
            return;
        }
        dispatch(updateAdminProfile({ name, email, currentPassword, newPassword, avatar }))
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    handleLogout();
                }
            });
    };

    const inputClasses = "bg-black text-[#d3d3d3] border-[#282828] border-[3px] placeholder-[#d3d3d3]";

    const handleLogout = () => {
        console.log('Logging out...'); // Добавим для отладки
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/admin/login');
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!admin) return null;

    return (
        <Card className="bg-[#15171c] w-full">
            <CardContent className="p-6">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold text-[#eff0f2]">Admin Profile</h2>
                    <Button
                        onClick={handleLogout}
                        className="bg-red-500 hover:bg-red-600 text-white rounded-[30px] py-1 px-4"
                    >
                        Logout
                    </Button>
                </div>
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="flex space-x-4">
                        <div className="w-2/3 space-y-4">
                            <div className="space-y-2">
                                <Label htmlFor="name" className="text-white">Name</Label>
                                <div className="flex items-center">
                                    {isEditingName ? (
                                        <Input
                                            id="name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            className={inputClasses}
                                        />
                                    ) : (
                                        <span className="text-[#d3d3d3]">{name || 'No name set'}</span>
                                    )}
                                    <Button
                                        type="button"
                                        onClick={() => setIsEditingName(!isEditingName)}
                                        className="ml-2 p-2 bg-transparent hover:bg-[#282828] rounded-full"
                                    >
                                        <Pencil size={16} className="text-[#d3d3d3]" />
                                    </Button>
                                </div>
                            </div>
                            <div className="space-y-2">
                                <Label htmlFor="email" className="text-white">Email</Label>
                                <div className="flex items-center">
                                    {isEditingEmail ? (
                                        <Input
                                            id="email"
                                            type="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            className={inputClasses}
                                        />
                                    ) : (
                                        <span className="text-[#d3d3d3]">{email || 'No email set'}</span>
                                    )}
                                    <Button
                                        type="button"
                                        onClick={() => setIsEditingEmail(!isEditingEmail)}
                                        className="ml-2 p-2 bg-transparent hover:bg-[#282828] rounded-full"
                                    >
                                        <Pencil size={16} className="text-[#d3d3d3]" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="w-1/3">
                            <Label className="text-white">Avatar Preview</Label>
                            <div className="relative">
                                <div className="border-2 border-black rounded-full p-1">
                                    <div className="w-32 h-32 rounded-full overflow-hidden flex items-center justify-center bg-gray-200">
                                        {avatarPreview ? (
                                            <img src={avatarPreview} alt="Avatar preview" className="max-w-full max-h-full object-contain" />
                                        ) : (
                                            <p className="text-gray-500 text-sm">No avatar</p>
                                        )}
                                    </div>
                                </div>
                                {avatarPreview && (
                                    <button
                                        type="button"
                                        className="absolute top-0 right-0 p-1 bg-red-500 text-white rounded-full hover:bg-red-600 focus:outline-none"
                                        onClick={() => {
                                            setAvatarPreview(null);
                                            setAvatar(null);
                                        }}
                                    >
                                        <span className="sr-only">Remove avatar</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="space-y-2">
                        <Label className="text-white">Upload Avatar</Label>
                        <ImageUpload onImageUpload={handleImageUpload} />
                    </div>
                    <div className="space-y-2">
                        <Label htmlFor="currentPassword" className="text-white">Current Password</Label>
                        <Input
                            id="currentPassword"
                            type="password"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            className={inputClasses}
                        />
                    </div>
                    <div className="space-y-2">
                        <Label htmlFor="newPassword" className="text-white">New Password</Label>
                        <Input
                            id="newPassword"
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className={inputClasses}
                        />
                    </div>
                    <div className="space-y-2">
                        <Label htmlFor="confirmPassword" className="text-white">Confirm New Password</Label>
                        <Input
                            id="confirmPassword"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className={inputClasses}
                        />
                    </div>
                    <Button
                        type="submit"
                        className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4"
                    >
                        Update Profile
                    </Button>
                </form>
            </CardContent>
        </Card>
    );
};

export default AdminProfile;