import React from 'react';

const AdminsManagement = () => {
  return (
    <div>
      <h2>Admins Management</h2>
      {/* Добавьте функциональность для управления администраторами */}
    </div>
  );
};

export default AdminsManagement;