import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import { Card, CardContent } from "../ui/card";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "../ui/dialog";
import { Pencil, Trash2, Upload } from "lucide-react";
import { Label } from "../ui/label";
import { useDropzone } from 'react-dropzone';

const ImageUpload = ({ onImageUpload }) => {
    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            onImageUpload(file);
        }
    }, [onImageUpload]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/*',
        multiple: false
    });

    return (
        <div
            {...getRootProps()}
            className={`border-2 border-dashed border-gray-300 rounded-lg p-6 text-center cursor-pointer
        ${isDragActive ? 'border-blue-500 bg-blue-50' : 'hover:border-gray-400'}`}
        >
            <input {...getInputProps()} />
            <Upload className="mx-auto mb-4 text-gray-400" size={48} />
            <p className="text-sm text-gray-500">
                {isDragActive ? 'Drop the image here' : 'Drag & drop an image here, or click to select one'}
            </p>
        </div>
    );
};

const RankForm = ({ rank, onSave, onCancel }) => {
    const [formData, setFormData] = useState(rank || {
        level: '',
        name: '',
        requiredCoins: '',
        battleLimit: '',
        status: '',
        backgroundImage: '',
        battlesRequired: '',
    });
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(rank?.backgroundImage || '');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleImageUpload = (file) => {
        setImageFile(file);

        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result);
            setFormData(prev => ({ ...prev, backgroundImage: reader.result }));
        };
        reader.readAsDataURL(file);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(formData, imageFile);
    };

    const inputClasses = "bg-black text-[#d3d3d3] border-[#282828] border-[3px] placeholder-[#d3d3d3]";

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <Label htmlFor="level" className="text-[#d3d3d3]">Level</Label>
                    <Input id="level" name="level" value={formData.level} onChange={handleChange} required type="number" className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="name" className="text-[#d3d3d3]">Rank Name</Label>
                    <Input id="name" name="name" value={formData.name} onChange={handleChange} required className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="requiredCoins" className="text-[#d3d3d3]">Required Coins</Label>
                    <Input id="requiredCoins" name="requiredCoins" value={formData.requiredCoins} onChange={handleChange} required type="number" className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="battleLimit" className="text-[#d3d3d3]">Battle Limit</Label>
                    <Input id="battleLimit" name="battleLimit" value={formData.battleLimit} onChange={handleChange} required type="number" className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="status" className="text-[#d3d3d3]">Status</Label>
                    <Input id="status" name="status" value={formData.status} onChange={handleChange} required className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="battlesRequired" className="text-[#d3d3d3]">Battles Required</Label>
                    <Input id="battlesRequired" name="battlesRequired" value={formData.battlesRequired} onChange={handleChange} required type="number" className={inputClasses} />
                </div>
            </div>
            <div>
                <Label className="text-[#d3d3d3]">Background Image</Label>
                <ImageUpload onImageUpload={handleImageUpload} />
                {imagePreview && (
                    <div className="relative mt-2">
                        <img src={imagePreview} alt="Background preview" className="h-32 w-full object-cover rounded" />
                        <Button
                            type="button"
                            onClick={() => {
                                setImagePreview('');
                                setFormData(prev => ({ ...prev, backgroundImage: '' }));
                            }}
                            className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full"
                        >
                            X
                        </Button>
                    </div>
                )}
            </div>
            <div className="flex justify-end space-x-2">
                <Button type="button" onClick={onCancel} variant="outline" className="bg-black text-white border-[#282828] hover:bg-white hover:text-black transition-colors duration-200 rounded-[30px] py-1 px-4">Cancel</Button>
                <Button type="submit" className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4">Save Rank</Button>
            </div>
        </form>
    );
};

const RankManagement = () => {
    const [ranks, setRanks] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [editingRank, setEditingRank] = useState(null);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [deletingRankId, setDeletingRankId] = useState(null);

    useEffect(() => {
        fetchRanks();
    }, []);

    const fetchRanks = async () => {
        try {
            const response = await axios.get('/api/ranks');
            console.log('Fetched ranks:', response.data);
            setRanks(response.data);
        } catch (error) {
            console.error('Error fetching ranks:', error);
            alert('Failed to load ranks. Please try refreshing the page.');
        }
    };

   const handleSave = async (rankData, imageFile) => {
    try {
        const formData = new FormData();
        Object.keys(rankData).forEach(key => {
            if (key !== 'backgroundImage') {
                formData.append(key, rankData[key]);
            }
        });

        if (imageFile) {
            formData.append('backgroundImage', imageFile);
        }

        let response;
        if (editingRank) {
            response = await axios.put(`/api/ranks/${editingRank._id}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
        } else {
            response = await axios.post('/api/ranks', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
        }
        console.log('Rank saved:', response.data);
        fetchRanks();
        setIsDialogOpen(false);
        setEditingRank(null);
    } catch (error) {
        console.error('Error saving rank:', error.response ? error.response.data : error.message);
        alert(`Error saving rank: ${error.response ? error.response.data.message : error.message}`);
    }
};

    const handleDelete = (rankId) => {
        setDeletingRankId(rankId);
        setIsDeleteDialogOpen(true);
    };

    const confirmDelete = async () => {
        if (deletingRankId) {
            try {
                await axios.delete(`/api/ranks/${deletingRankId}`);
                fetchRanks();
            } catch (error) {
                console.error('Error deleting rank:', error);
            }
            setIsDeleteDialogOpen(false);
            setDeletingRankId(null);
        }
    };

    return (
        <Card className="bg-transparent">
            <CardContent className="p-6">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold text-[#eff0f2]">Ranks</h2>
                    <Button onClick={() => setIsDialogOpen(true)} className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4">Add New Rank</Button>
                </div>
                <div className="overflow-x-auto max-h-[calc(100vh-300px)] overflow-y-auto">
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead className="text-[#d3d3d3]">Level</TableHead>
                                <TableHead className="text-[#d3d3d3]">Name</TableHead>
                                <TableHead className="text-[#d3d3d3]">Required Coins</TableHead>
                                <TableHead className="text-[#d3d3d3]">Battle Limit</TableHead>
                                <TableHead className="text-[#d3d3d3]">Status</TableHead>
                                <TableHead className="text-[#d3d3d3]">Actions</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {ranks.map(rank => (
                                <TableRow key={rank._id} className="hover:bg-black">
                                    <TableCell className="text-[#d3d3d3]">{rank.level}</TableCell>
                                    <TableCell className="text-[#d3d3d3]">{rank.name}</TableCell>
                                    <TableCell className="text-[#d3d3d3]">{rank.requiredCoins}</TableCell>
                                    <TableCell className="text-[#d3d3d3]">{rank.battleLimit}</TableCell>
                                    <TableCell className="text-[#d3d3d3]">{rank.status}</TableCell>
                                    <TableCell>
                                        <div className="flex space-x-2">
                                            <Button onClick={() => { setEditingRank(rank); setIsDialogOpen(true); }} className="p-2 bg-black text-white rounded-[30px]">
                                                <Pencil size={16} />
                                            </Button>
                                            <Button onClick={() => handleDelete(rank._id)} className="p-2 bg-black text-white rounded-[30px]">
                                                <Trash2 size={16} />
                                            </Button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                    <DialogContent className="max-h-[80vh] overflow-y-auto bg-[#15171c]">
                        <DialogHeader>
                            <DialogTitle className="text-[#eff0f2]">{editingRank ? 'Edit Rank' : 'Add New Rank'}</DialogTitle>
                            <DialogDescription className="text-[#d3d3d3]">
                                {editingRank ? 'Edit the details of the rank.' : 'Enter the details of the new rank.'}
                            </DialogDescription>
                        </DialogHeader>
                        <RankForm
                            rank={editingRank}
                            onSave={handleSave}
                            onCancel={() => setIsDialogOpen(false)}
                        />
                    </DialogContent>
                </Dialog>
            </CardContent>
            <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
                <DialogContent className="bg-black text-white rounded-[30px] p-6">
                    <DialogHeader>
                        <DialogTitle className="text-xl font-bold">Confirm Deletion</DialogTitle>
                        <DialogDescription className="text-[#d3d3d3]">
                            Are you sure you want to delete this rank? This action cannot be undone.
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter className="mt-6 flex justify-end space-x-4">
                        <Button
                            onClick={() => setIsDeleteDialogOpen(false)}
                            className="bg-white text-black hover:bg-gray-200 rounded-[30px] py-1 px-4"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={confirmDelete}
                            className="bg-red-500 text-white hover:bg-red-600 rounded-[30px] py-1 px-4"
                        >
                            Delete
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </Card>
    );
};

export default RankManagement;