import React, { useState, useEffect } from 'react';
import { Calendar, Star, Award, Coins } from 'lucide-react';

const DailyRewards = ({ streakDays: initialStreakDays, level: initialLevel, onClaimReward, onClose }) => {
  const [streakDays, setStreakDays] = useState(initialStreakDays || 0);
  const [currentReward, setCurrentReward] = useState(null);
  const [showAnimation, setShowAnimation] = useState(false);
  const [currentMonth, setCurrentMonth] = useState([]);
  const [level, setLevel] = useState(initialLevel || 1);

  // Определяем награды для каждого уровня
  const rewards = {
    1: { title: 'Brand Rookie', amount: 10 },
    2: { title: 'Brand Pro', amount: 20 },
    3: { title: 'Brand Expert', amount: 30 },
    4: { title: 'Brand Master', amount: 40 },
    5: { title: 'Brand Legend', amount: 50 }
  };

  // Создаем календарь на текущий месяц, начиная с понедельника
  useEffect(() => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    let firstDayWeekday = firstDayOfMonth.getDay();
    // Преобразуем воскресенье (0) в 7 для правильного отображения недели с понедельника
    firstDayWeekday = firstDayWeekday === 0 ? 7 : firstDayWeekday;
    const daysInMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
    
    const days = [];
    // Добавляем пустые ячейки для выравнивания с понедельника
    for (let i = 0; i < firstDayWeekday - 1; i++) {
      days.push({ day: '', isEmpty: true });
    }
    // Добавляем дни месяца
    for (let i = 1; i <= daysInMonth; i++) {
      days.push({
        day: i,
        isClaimed: i < today.getDate(),
        isToday: i === today.getDate(),
        isEmpty: false
      });
    }
    setCurrentMonth(days);
  }, []);

  const handleClaim = async () => {
    setShowAnimation(true);
    const baseReward = rewards[level].amount;
    const bonus = (streakDays + 1) % 10 === 0 ? Math.floor((streakDays + 1) / 10) * 10 : 0;
    const total = baseReward + bonus;

    try {
      await onClaimReward();
      setCurrentReward(total);
      setStreakDays(prev => prev + 1);
      
      const updatedMonth = currentMonth.map(day => 
        day.isToday ? { ...day, isClaimed: true } : day
      );
      setCurrentMonth(updatedMonth);

      setTimeout(() => {
        setShowAnimation(false);
        setTimeout(onClose, 500);
      }, 1500);
    } catch (error) {
      console.error('Error claiming reward:', error);
      setShowAnimation(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50" onClick={(e) => e.stopPropagation()}>
      <div className="w-full max-w-md mx-4 p-6 bg-black rounded-xl border border-[#e0ff89]/20">
        {/* Анимация получения награды */}
        {showAnimation && (
          <div className="absolute inset-0 flex items-center justify-center bg-black/50 rounded-xl">
            <div className="animate-bounce flex items-center gap-2 text-4xl font-bold text-[#e0ff89]">
              <Coins className="w-8 h-8" />
              <span>+{currentReward}</span>
            </div>
          </div>
        )}

        {/* Заголовок и статус */}
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-bold text-white">Daily Rewards</h2>
          <div className="flex items-center gap-2">
            <Star className="w-4 h-4 text-[#e0ff89]" />
            <span className="text-[#e0ff89] font-bold">{streakDays} streak</span>
          </div>
        </div>

        {/* Прогресс уровня */}
        <div className="mb-4">
          <div className="flex justify-between text-sm mb-2">
            <span className="text-[#e0ff89]">{rewards[level].title}</span>
            <span className="text-gray-400">
              {level < 5 ? `${streakDays % 10}/10 to next level` : 'Max Level'}
            </span>
          </div>
          <div className="w-full bg-gray-800 rounded-full h-2">
            <div
              className="bg-[#e0ff89] rounded-full h-2 transition-all duration-300"
              style={{ width: `${(streakDays % 10) * 10}%` }}
            />
          </div>
        </div>

        {/* Календарь */}
<div className="mb-4">
  <div className="grid grid-cols-7 gap-1 text-center">
    {['M', 'T', 'W', 'T', 'F', 'S', 'S'].map(day => (
      <div key={day} className="text-xs text-gray-500 mb-2">{day}</div>
    ))}
    {currentMonth.map((day, index) => (
      <div key={index} className="flex items-center justify-center px-1">
        <div
          className={`
            w-8 h-8 flex items-center justify-center text-xs rounded-full
            ${day.isEmpty ? '' : 
              day.isClaimed ? 'bg-[#e0ff89]/20 text-[#e0ff89]' : 
              day.isToday ? 'bg-[#1a1a1a] text-white ring-2 ring-[#e0ff89]' : 
              'bg-[#1a1a1a] text-gray-400'}
          `}
        >
          {day.day}
        </div>
      </div>
    ))}
  </div>
</div>

        {/* Кнопка получения награды */}
        <button
          onClick={handleClaim}
          className="w-full h-12 rounded-[30px] font-bold bg-[#e0ff89] hover:bg-[#e0ff89]/80 text-black flex items-center justify-center gap-2 transition-all duration-300"
        >
          <Award className="w-5 h-5" />
          <span>Claim +{rewards[level].amount} coins</span>
        </button>
      </div>
    </div>
  );
};

export default DailyRewards;