import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Card, CardContent } from "../ui/card";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "../ui/dialog";
import { Label } from "../ui/label";
import { Pencil, Trash2, Upload, ChevronLeft, ChevronRight } from "lucide-react";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useDropzone } from 'react-dropzone';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { SiDiscord, SiFacebook, SiInstagram, SiKakaotalk, SiLine, SiLinkedin, SiMedium, SiOdnoklassniki, SiOnlyfans, SiPatreon, SiPinterest, SiTencentqq, SiReddit, SiSnapchat, SiTelegram, SiThreads, SiTiktok, SiTumblr, SiTwitch, SiViber, SiVk, SiWechat, SiSinaweibo, SiWhatsapp, SiX, SiYoutube } from 'react-icons/si';
import { Switch } from "../ui/switch";
import { MultiSelect } from "../ui/MultiSelect";
import { COUNTRIES, groupCountriesByRegion, getPopularCountries } from '../../constants/countries';
import { LANGUAGES, groupLanguagesByFirstLetter, getCommonLanguages } from '../../constants/languages';


const getSocialIcon = (platform) => {
    const iconProps = { className: "w-4 h-4 text-white" };
    const icons = {
        Discord: SiDiscord,
        Facebook: SiFacebook,
        Instagram: SiInstagram,
        KakaoTalk: SiKakaotalk,
        Line: SiLine,
        LinkedIn: SiLinkedin,
        Medium: SiMedium,
        Ok: SiOdnoklassniki,
        Onlyfans: SiOnlyfans,
        Patreon: SiPatreon,
        Pinterest: SiPinterest,
        QQ: SiTencentqq,
        Reddit: SiReddit,
        Snapchat: SiSnapchat,
        Telegram: SiTelegram,
        Threads: SiThreads,
        TikTok: SiTiktok,
        Tumblr: SiTumblr,
        Twitch: SiTwitch,
        Viber: SiViber,
        Vk: SiVk,
        WeChat: SiWechat,
        Weibo: SiSinaweibo,
        WhatsApp: SiWhatsapp,
        X: SiX,
        YouTube: SiYoutube
    };
    const Icon = icons[platform];
    return Icon ? <Icon {...iconProps} /> : null;
};

const ImageUpload = ({ onImageUpload }) => {
    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            onImageUpload(file);
        }
    }, [onImageUpload]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/*',
        multiple: false
    });

    return (
        <div
            {...getRootProps()}
            className={`border-2 border-dashed border-gray-300 rounded-lg p-6 text-center cursor-pointer
        ${isDragActive ? 'border-blue-500 bg-blue-50' : 'hover:border-gray-400'}`}
        >
            <input {...getInputProps()} />
            <Upload className="mx-auto mb-4 text-gray-400" size={48} />
            <p className="text-sm text-gray-500">
                {isDragActive ? 'Drop the image here' : 'Drag & drop an image here, or click to select one'}
            </p>
        </div>
    );
};

const SocialTaskForm = ({ task, onSave, onCancel }) => {
    const [formData, setFormData] = useState(task || {
        name: '',
        description: '',
        backgroundImage: '',
        coinsReward: '',
        socialPlatform: '',
        socialUrl: '',
        limitClicks: -1,
        limitClaims: -1,
        targeting: 'all',
        allowedCountries: [],
        allowedLanguages: []
    });

    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(task?.backgroundImage || '');

    // Сгруппированные данные для селектов
    const groupedCountries = groupCountriesByRegion();
    const popularCountries = getPopularCountries();
    const commonLanguages = getCommonLanguages();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleTargetingChange = (checked) => {
        setFormData(prev => ({
            ...prev,
            targeting: checked ? 'specific' : 'all',
            ...(checked ? {} : { allowedCountries: [], allowedLanguages: [] })
        }));
    };

    const handleCountriesChange = (selectedOptions) => {
        console.log('Selected countries:', selectedOptions);
        const countryValues = selectedOptions
            .filter(option => option && option.value)
            .map(option => option.value);
        console.log('Country values to save:', countryValues);
        setFormData(prev => ({
            ...prev,
            allowedCountries: countryValues
        }));
    };

    const handleLanguagesChange = (selectedOptions) => {
        console.log('Selected languages:', selectedOptions);
        const languageValues = selectedOptions
            .filter(option => option && option.value)
            .map(option => option.value);
        console.log('Language values to save:', languageValues);
        setFormData(prev => ({
            ...prev,
            allowedLanguages: languageValues
        }));
    };

    const handleImageUpload = (file) => {
        setImageFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result);
            setFormData(prev => ({ ...prev, backgroundImage: reader.result }));
        };
        reader.readAsDataURL(file);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();

        console.log('Form data before processing:', formData);

        // Добавляем базовые поля
        const baseFields = [
            'name', 'description', 'coinsReward', 'socialPlatform',
            'socialUrl', 'limitClicks', 'limitClaims', 'targeting'
        ];

        baseFields.forEach(field => {
            if (formData[field] !== undefined) {
                formDataToSend.append(field, formData[field]);
            }
        });

        // Обработка таргетинга и связанных массивов
        if (formData.targeting === 'specific') {
            // Обработка стран
            const countries = formData.allowedCountries ?
                (Array.isArray(formData.allowedCountries) ? formData.allowedCountries : [])
                    .filter(Boolean) : [];

            // Обработка языков
            const languages = formData.allowedLanguages ?
                (Array.isArray(formData.allowedLanguages) ? formData.allowedLanguages : [])
                    .filter(Boolean) : [];

            console.log('Processing countries:', countries);
            console.log('Processing languages:', languages);

            // Если массивы пустые, но таргетинг specific - всё равно сохраняем пустые массивы
            formDataToSend.append('allowedCountries', JSON.stringify(countries));
            formDataToSend.append('allowedLanguages', JSON.stringify(languages));
        } else {
            // Для targeting = 'all' всегда отправляем пустые массивы
            formDataToSend.append('allowedCountries', JSON.stringify([]));
            formDataToSend.append('allowedLanguages', JSON.stringify([]));
        }

        // Добавляем изображение, если оно есть
        if (imageFile) {
            formDataToSend.append('backgroundImage', imageFile);
        }

        // Подробное логирование для отладки
        console.log('Final form data contents:');
        for (let pair of formDataToSend.entries()) {
            if (pair[0] === 'allowedCountries' || pair[0] === 'allowedLanguages') {
                console.log(pair[0], 'parsed:', JSON.parse(pair[1]));
            } else {
                console.log(pair[0], pair[1]);
            }
        }

        onSave(formDataToSend);
    };

    const inputClasses = "bg-black text-[#d3d3d3] border-[#282828] border-[3px] placeholder-[#d3d3d3]";

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <div className="mb-4">
                <Label htmlFor="socialPlatform" className="text-[#d3d3d3] block mb-2">Social Platform</Label>
                <Select name="socialPlatform" value={formData.socialPlatform} onValueChange={(value) => handleChange({ target: { name: 'socialPlatform', value } })}>
                    <SelectTrigger className={`${inputClasses} w-full`}>
                        <SelectValue placeholder="Select a platform" />
                    </SelectTrigger>
                    <SelectContent className="max-h-[200px] overflow-y-auto">
                        <SelectItem value="Discord">Discord</SelectItem>
                        <SelectItem value="Facebook">Facebook</SelectItem>
                        <SelectItem value="Instagram">Instagram</SelectItem>
                        <SelectItem value="KakaoTalk">KakaoTalk</SelectItem>
                        <SelectItem value="Line">Line</SelectItem>
                        <SelectItem value="LinkedIn">LinkedIn</SelectItem>
                        <SelectItem value="Medium">Medium</SelectItem>
                        <SelectItem value="Ok">Odnoklassniki</SelectItem>
                        <SelectItem value="Onlyfans">OnlyFans</SelectItem>
                        <SelectItem value="Patreon">Patreon</SelectItem>
                        <SelectItem value="Pinterest">Pinterest</SelectItem>
                        <SelectItem value="QQ">QQ</SelectItem>
                        <SelectItem value="Reddit">Reddit</SelectItem>
                        <SelectItem value="Snapchat">Snapchat</SelectItem>
                        <SelectItem value="Telegram">Telegram</SelectItem>
                        <SelectItem value="Threads">Threads</SelectItem>
                        <SelectItem value="TikTok">TikTok</SelectItem>
                        <SelectItem value="Tumblr">Tumblr</SelectItem>
                        <SelectItem value="Twitch">Twitch</SelectItem>
                        <SelectItem value="Viber">Viber</SelectItem>
                        <SelectItem value="Vk">VK</SelectItem>
                        <SelectItem value="WeChat">WeChat</SelectItem>
                        <SelectItem value="Weibo">Weibo</SelectItem>
                        <SelectItem value="WhatsApp">WhatsApp</SelectItem>
                        <SelectItem value="X">X (Twitter)</SelectItem>
                        <SelectItem value="YouTube">YouTube</SelectItem>
                    </SelectContent>
                </Select>
            </div>
            {formData.socialPlatform && (
                <div className="mb-4 p-4 bg-gray-800 rounded-lg">
                    <h4 className="text-[#d3d3d3] font-bold mb-2">Instructions for {formData.socialPlatform}</h4>
                    <p className="text-[#a0a0a0]">
                        {formData.socialPlatform === 'Discord' && "Enter the Discord server invite link (e.g., https://discord.gg/invitecode)."}
                        {formData.socialPlatform === 'Facebook' && "Enter the Facebook page URL (e.g., https://www.facebook.com/pagename)."}
                        {formData.socialPlatform === 'Instagram' && "Enter the Instagram profile URL (e.g., https://www.instagram.com/username)."}
                        {formData.socialPlatform === 'KakaoTalk' && "Enter the KakaoTalk profile URL."}
                        {formData.socialPlatform === 'Line' && "Enter the Line profile URL."}
                        {formData.socialPlatform === 'LinkedIn' && "Enter the LinkedIn profile or company URL (e.g., https://www.linkedin.com/in/username or https://www.linkedin.com/company/companyname)."}
                        {formData.socialPlatform === 'Medium' && "Enter the Medium profile URL (e.g., https://medium.com/@username)."}
                        {formData.socialPlatform === 'Odnoklassniki' && "Enter the Odnoklassniki profile URL (e.g., https://ok.ru/profile/username)."}
                        {formData.socialPlatform === 'OnlyFans' && "Enter the OnlyFans profile URL."}
                        {formData.socialPlatform === 'Patreon' && "Enter the Patreon page URL (e.g., https://www.patreon.com/creatorname)."}
                        {formData.socialPlatform === 'Pinterest' && "Enter the Pinterest profile URL (e.g., https://www.pinterest.com/username)."}
                        {formData.socialPlatform === 'QQ' && "Enter the QQ profile URL."}
                        {formData.socialPlatform === 'Reddit' && "Enter the Reddit user or subreddit URL (e.g., https://www.reddit.com/user/username or https://www.reddit.com/r/subreddit)."}
                        {formData.socialPlatform === 'Snapchat' && "Enter the Snapchat profile URL or username."}
                        {formData.socialPlatform === 'Telegram' && "Enter the Telegram channel or group link (e.g., https://t.me/yourchannel)."}
                        {formData.socialPlatform === 'Threads' && "Enter the Threads profile URL (e.g., https://www.threads.net/@username)."}
                        {formData.socialPlatform === 'TikTok' && "Enter the TikTok profile URL (e.g., https://www.tiktok.com/@username)."}
                        {formData.socialPlatform === 'Tumblr' && "Enter the Tumblr blog URL (e.g., https://username.tumblr.com)."}
                        {formData.socialPlatform === 'Twitch' && "Enter the Twitch channel URL (e.g., https://www.twitch.tv/username)."}
                        {formData.socialPlatform === 'Viber' && "Enter the Viber community or public account URL."}
                        {formData.socialPlatform === 'VK' && "Enter the VK profile or community URL (e.g., https://vk.com/username)."}
                        {formData.socialPlatform === 'WeChat' && "Enter the WeChat official account ID or URL."}
                        {formData.socialPlatform === 'Weibo' && "Enter the Weibo profile URL."}
                        {formData.socialPlatform === 'WhatsApp' && "Enter the WhatsApp group invite link or business profile URL."}
                        {formData.socialPlatform === 'X (Twitter)' && "Enter the Twitter profile URL (e.g., https://twitter.com/username)."}
                        {formData.socialPlatform === 'YouTube' && "Enter the YouTube channel URL (e.g., https://www.youtube.com/channel/channelID)."}
                    </p>
                </div>
            )}
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <Label htmlFor="name" className="text-[#d3d3d3]">Name</Label>
                    <Input id="name" name="name" value={formData.name} onChange={handleChange} required className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="description" className="text-[#d3d3d3]">Description</Label>
                    <Input id="description" name="description" value={formData.description} onChange={handleChange} required className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="coinsReward" className="text-[#d3d3d3]">Coins Reward</Label>
                    <Input id="coinsReward" name="coinsReward" type="number" value={formData.coinsReward} onChange={handleChange} className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="socialUrl" className="text-[#d3d3d3]">Social URL</Label>
                    <Input id="socialUrl" name="socialUrl" value={formData.socialUrl} onChange={handleChange} required className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="limitClicks" className="text-[#d3d3d3]">Limit Clicks</Label>
                    <Input id="limitClicks" name="limitClicks" type="number" value={formData.limitClicks} onChange={handleChange} className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="limitClaims" className="text-[#d3d3d3]">Limit Claims</Label>
                    <Input id="limitClaims" name="limitClaims" type="number" value={formData.limitClaims} onChange={handleChange} className={inputClasses} />
                </div>
            </div>
            <div>
                <Label className="text-[#d3d3d3]">Background Image</Label>
                <div className="flex flex-col space-y-2">
                    <ImageUpload onImageUpload={handleImageUpload} />
                </div>
                {imagePreview && (
                    <div className="relative mt-2">
                        <img src={imagePreview} alt="Background preview" className="h-32 w-full object-cover rounded" />
                        <Button
                            type="button"
                            onClick={() => {
                                setImagePreview('');
                                setFormData(prev => ({ ...prev, backgroundImage: '' }));
                            }}
                            className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full"
                        >
                            X
                        </Button>
                    </div>
                )}
                {/* Targeting section */}
                <div className="border-t border-gray-700 mt-6 pt-6">
                    <div className="flex items-center justify-between mb-4">
                        <div>
                            <h3 className="text-lg font-medium text-white">Targeting Settings</h3>
                            <p className="text-sm text-gray-400">Enable specific targeting for countries and languages</p>
                        </div>
                        <Switch
                            checked={formData.targeting === 'specific'}
                            onCheckedChange={handleTargetingChange}
                            className="data-[state=checked]:bg-[#e0ff89]"
                        />
                    </div>

                    {formData.targeting === 'specific' && (
                        <div className="space-y-4">
                            {/* Countries Selection */}
                            <div>
                                <Label htmlFor="allowedCountries" className="text-[#d3d3d3]">
                                    Allowed Countries
                                </Label>
                                <MultiSelect
                                    id="allowedCountries"
                                    placeholder="Select countries (empty = all countries)"
                                    selected={formData.allowedCountries.map(code => ({
                                        value: code,
                                        label: COUNTRIES.find(c => c.value === code)?.label || code,
                                        icon: COUNTRIES.find(c => c.value === code)?.icon
                                    }))}
                                    options={{
                                        'Popular Countries': getPopularCountries(),
                                        'Europe': COUNTRIES.filter(c => c.region === 'Europe'),
                                        'Asia': COUNTRIES.filter(c => c.region === 'Asia'),
                                        'Africa': COUNTRIES.filter(c => c.region === 'Africa'),
                                        'North America': COUNTRIES.filter(c => c.region === 'North America'),
                                        'South America': COUNTRIES.filter(c => c.region === 'South America'),
                                        'Oceania': COUNTRIES.filter(c => c.region === 'Oceania'),
                                        'Antarctica': COUNTRIES.filter(c => c.region === 'Antarctica')
                                    }}
                                    onChange={handleCountriesChange}
                                    className="bg-black text-[#d3d3d3] border-[#282828]"
                                />
                            </div>

                            {/* Languages Selection */}
                            <div>
                                <Label htmlFor="allowedLanguages" className="text-[#d3d3d3]">
                                    Allowed Languages
                                </Label>
                                <MultiSelect
                                    id="allowedLanguages"
                                    placeholder="Select languages (empty = all languages)"
                                    selected={formData.allowedLanguages.map(code => ({
                                        value: code,
                                        label: `${LANGUAGES.find(l => l.value === code)?.label} (${LANGUAGES.find(l => l.value === code)?.nativeName})`,
                                    }))}
                                    options={{
                                        'Common Languages': getCommonLanguages(),
                                        'All Languages': LANGUAGES.map(lang => ({
                                            ...lang,
                                            label: `${lang.label} (${lang.nativeName})`
                                        }))
                                    }}
                                    onChange={handleLanguagesChange}
                                    className="bg-black text-[#d3d3d3] border-[#282828]"
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="flex justify-end space-x-2">
                <Button type="button" onClick={onCancel} variant="outline" className="bg-black text-white border-[#282828] hover:bg-white hover:text-black transition-colors duration-200 rounded-[30px] py-1 px-4">Cancel</Button>
                <Button type="submit" className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4">Save Social Task</Button>
            </div>
        </form>
    );
};

const SocialTasksManagement = () => {
    const [tasks, setTasks] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [editingTask, setEditingTask] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [tasksPerPage, setTasksPerPage] = useState(10);
    const [totalTasks, setTotalTasks] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const fetchTasks = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`/api/social-tasks?page=${currentPage}&limit=${tasksPerPage}`);
            console.log('Fetched social tasks:', response.data);
            if (Array.isArray(response.data)) {
                setTasks(response.data);
                setTotalTasks(response.data.length);
            } else if (response.data && Array.isArray(response.data.tasks)) {
                setTasks(response.data.tasks);
                setTotalTasks(response.data.totalTasks || 0);
            } else {
                console.error('Invalid response format:', response.data);
                setTasks([]);
                setTotalTasks(0);
            }
        } catch (error) {
            console.error('Error fetching social tasks:', error);
            setTasks([]);
            setTotalTasks(0);
        } finally {
            setIsLoading(false);
        }
    }, [currentPage, tasksPerPage]);

    useEffect(() => {
        fetchTasks();
    }, [fetchTasks]);

    const handleSave = async (formData) => {
        try {
            // Преобразуем formData в объект для добавления дополнительных данных
            const formDataObj = {};
            for (let [key, value] of formData.entries()) {
                formDataObj[key] = value;
            }
            console.log('Initial form data:', formDataObj);

            // Создаем новый FormData для отправки
            const finalFormData = new FormData();

            // Добавляем базовые поля
            for (let [key, value] of formData.entries()) {
                if (key !== 'allowedCountries' && key !== 'allowedLanguages') {
                    finalFormData.append(key, value);
                }
            }

            // Добавляем массивы стран и языков
            if (formDataObj.targeting === 'specific') {
                const allowedCountries = JSON.parse(formDataObj.allowedCountries || '[]');
                const allowedLanguages = JSON.parse(formDataObj.allowedLanguages || '[]');

                // Преобразуем массивы в строку JSON
                finalFormData.append('allowedCountries', JSON.stringify(allowedCountries));
                finalFormData.append('allowedLanguages', JSON.stringify(allowedLanguages));
            } else {
                finalFormData.append('allowedCountries', JSON.stringify([]));
                finalFormData.append('allowedLanguages', JSON.stringify([]));
            }

            console.log('Final form data before sending:');
            for (let [key, value] of finalFormData.entries()) {
                console.log(key, value);
            }

            let response;
            if (editingTask) {
                response = await axios.put(`/api/social-tasks/${editingTask._id}`, finalFormData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                console.log('Updated task response:', response.data);
            } else {
                response = await axios.post('/api/social-tasks', finalFormData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                console.log('Created task response:', response.data);
            }

            fetchTasks();
            setIsDialogOpen(false);
            setEditingTask(null);
        } catch (error) {
            console.error('Error details:', {
                message: error.message,
                response: error.response?.data,
                data: error.response?.data?.details
            });
            alert(`Error saving social task: ${error.response?.data?.message || error.message}`);
        }
    };

    const handleDelete = async (taskId) => {
        try {
            await axios.delete(`/api/social-tasks/${taskId}`);
            fetchTasks();
        } catch (error) {
            console.error('Error deleting social task:', error);
        }
    };

    const onDragEnd = async (result) => {
        if (!result.destination) return;

        const items = Array.from(tasks);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setTasks(items);

        try {
            await axios.post('/api/social-tasks/reorder', { tasks: items.map(item => item._id) });
        } catch (error) {
            console.error('Error reordering social tasks:', error);
            fetchTasks();
        }
    };

    const handleClick = async (taskId) => {
        try {
            const response = await axios.post(`/api/social-tasks/${taskId}/click`, {}, {
                headers: {
                    'x-telegram-init-data': window.Telegram.WebApp.initData
                }
            });
            console.log('Click registered:', response.data);
            // Обновляем задачу в состоянии
            setTasks(prevTasks => prevTasks.map(task =>
                task._id === taskId
                    ? {
                        ...task,
                        clickCount: task.clickCount + 1,
                        status: task.limitClicks !== -1 && task.clickCount + 1 >= task.limitClicks ? 'expired' : 'active'
                    }
                    : task
            ));
        } catch (error) {
            console.error('Error registering click:', error);
            if (error.response && error.response.status === 400) {
                alert(error.response.data.message);
            } else {
                alert('Failed to register click. Please try again.');
            }
        }
    };

    return (
        <Card className="bg-transparent">
            <CardContent className="p-4">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold text-[#eff0f2]">Social Tasks</h2>
                    <Button onClick={() => setIsDialogOpen(true)} className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4">Add New Social Task</Button>
                </div>
                <div className="overflow-x-auto max-h-[calc(100vh-300px)] overflow-y-auto">
                    {isLoading ? (
                        <p className="text-[#d3d3d3] text-center">Loading tasks...</p>
                    ) : (
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="tasks">
                                {(provided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-4">
                                        {tasks && tasks.length > 0 ? (
                                            tasks.map((task, index) => (
                                                <Draggable key={task._id} draggableId={task._id} index={index}>
                                                    {(provided) => (
                                                        <Card
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className="relative overflow-hidden rounded-lg shadow-lg bg-[#1c1f26]"
                                                        >
                                                            <div className="relative z-10 p-6 bg-black bg-opacity-60 h-full flex flex-col justify-between">
                                                                <div className="flex justify-between items-start">
                                                                    <div>
                                                                        <div className="flex items-center gap-2">
                                                                            {getSocialIcon(task.socialPlatform)}
                                                                            <h3 className="text-2xl font-bold text-[#d3d3d3]">{task.name}</h3>
                                                                        </div>
                                                                        <p className="text-sm text-[#a0a0a0] mt-1">{task.description}</p>
                                                                    </div>
                                                                    <div className="text-right">
                                                                        <p className="text-2xl font-bold text-white flex items-center">
                                                                            {task.coinsReward}
                                                                            <img src="/images/general/coin.svg" alt="coin" className="w-6 h-6 ml-1" />
                                                                        </p>
                                                                        <div className="flex items-center justify-end mt-1">
                                                                            <p className={`text-sm ${task.status === 'active' ? 'text-[#e0ff89]' : 'text-red-500'}`}>
                                                                                {task.status === 'active' ? 'Active' : 'Expired'}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-4">
                                                                    <Button
                                                                        className="w-full bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4"
                                                                        onClick={() => handleClick(task._id)}
                                                                        disabled={task.status === 'expired'}
                                                                    >
                                                                        {task.status === 'expired' ? 'Expired' : `Visit ${task.socialPlatform}`}
                                                                    </Button>
                                                                </div>
                                                                <div className="flex justify-between items-center mt-4">
                                                                    <div className="text-sm text-[#a0a0a0]">
                                                                        <p>Clicks: {task.clickCount}/{task.limitClicks === -1 ? '∞' : task.limitClicks}</p>
                                                                        <p>Claims: {task.claimCount}/{task.limitClaims === -1 ? '∞' : task.limitClaims}</p>
                                                                    </div>
                                                                    <div className="text-sm text-[#a0a0a0]">
                                                                        {task.targeting === 'specific' && (
                                                                            <div className="text-right">
                                                                                {task.allowedCountries && task.allowedCountries.length > 0 ? (
                                                                                    <p>Countries: {task.allowedCountries.join(', ')}</p>
                                                                                ) : (
                                                                                    <p>Countries: All</p>
                                                                                )}
                                                                                {task.allowedLanguages && task.allowedLanguages.length > 0 ? (
                                                                                    <p>Languages: {task.allowedLanguages.join(', ')}</p>
                                                                                ) : (
                                                                                    <p>Languages: All</p>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="flex space-x-2">
                                                                        <Button onClick={() => { setEditingTask(task); setIsDialogOpen(true); }} className="p-2 bg-black text-white rounded-[30px]">
                                                                            <Pencil size={16} />
                                                                        </Button>
                                                                        <Button onClick={() => handleDelete(task._id)} className="p-2 bg-black text-white rounded-[30px]">
                                                                            <Trash2 size={16} />
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {task.backgroundImage && (
                                                                <img
                                                                    src={task.backgroundImage}
                                                                    alt={task.name}
                                                                    className="absolute inset-0 w-full h-full object-cover opacity-30"
                                                                />
                                                            )}
                                                        </Card>
                                                    )}
                                                </Draggable>
                                            ))
                                        ) : (
                                            <p className="text-[#d3d3d3] text-center">No social tasks available.</p>
                                        )}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    )}
                </div>
                <div className="mt-4 flex justify-between items-center">
                    <div className="flex items-center space-x-2">
                        <span className="text-[#d3d3d3]">Tasks per page:</span>
                        <Select value={tasksPerPage.toString()} onValueChange={(value) => setTasksPerPage(Number(value))}>
                            <SelectTrigger className="w-[70px] bg-black text-[#d3d3d3]">
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent className="bg-black text-[#d3d3d3]">
                                {[10, 20, 50, 100].map(value => (
                                    <SelectItem key={value} value={value.toString()}>{value}</SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <div className="flex items-center space-x-2">
                        <Button
                            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                            disabled={currentPage === 1}
                            className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-full p-2"
                        >
                            <ChevronLeft size={16} />
                        </Button>
                        <span className="text-[#d3d3d3]">Page {currentPage} of {Math.ceil(totalTasks / tasksPerPage)}</span>
                        <Button
                            onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(totalTasks / tasksPerPage)))}
                            disabled={currentPage === Math.ceil(totalTasks / tasksPerPage)}
                            className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-full p-2"
                        >
                            <ChevronRight size={16} />
                        </Button>
                    </div>
                </div>
                <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                    <DialogContent className="max-h-[80vh] overflow-y-auto bg-[#15171c]">
                        <DialogHeader>
                            <DialogTitle className="text-[#eff0f2]">{editingTask ? 'Edit Social Task' : 'Add New Social Task'}</DialogTitle>
                            <DialogDescription className="text-[#d3d3d3]">
                                {editingTask ? 'Edit the details of the social task.' : 'Enter the details of the new social task.'}
                            </DialogDescription>
                        </DialogHeader>
                        <SocialTaskForm
                            task={editingTask}
                            onSave={handleSave}
                            onCancel={() => setIsDialogOpen(false)}
                        />
                    </DialogContent>
                </Dialog>
            </CardContent>
        </Card>
    );
};

export default SocialTasksManagement;