import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Card, CardContent } from "../ui/card";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "../ui/dialog";
import { Pencil, Trash2, GripVertical } from "lucide-react";

const ItemTypes = {
  COUNTRY: 'country',
};

const CountryForm = React.memo(({ country, onSave, onCancel }) => {
  const [name, setName] = useState(country ? country.name : '');
  const [code, setCode] = useState(country ? country.code : '');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({ name, code, _id: country?._id });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <Input
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Country Name"
        required
        className="bg-[#15171c] text-[#d3d3d3]"
      />
      <Input
        value={code}
        onChange={(e) => setCode(e.target.value)}
        placeholder="Country Code (e.g., US, GB)"
        required
        className="bg-[#15171c] text-[#d3d3d3]"
      />
      <div className="flex justify-end space-x-2">
        <Button type="button" onClick={onCancel} variant="outline" className="bg-black text-white rounded-[30px] py-1 px-2.5">Cancel</Button>
        <Button type="submit" className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-2.5">Save Country</Button>
      </div>
    </form>
  );
});

const CountryItem = React.memo(({ country, index, moveCountry, onEdit, onDelete }) => {
  const ref = React.useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.COUNTRY,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCountry(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.COUNTRY,
    item: () => ({ id: country._id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div ref={ref} style={{ opacity }} data-handler-id={handlerId} className="flex items-center justify-between bg-[#000000] p-3 mb-2 rounded-md hover:bg-black">
      <div className="flex items-center space-x-3">
        <GripVertical size={16} className="text-[#d3d3d3]" />
        <span className="text-[#d3d3d3]">{country.name}</span>
        <span className="text-gray-500">({country.code})</span>
      </div>
      <div className="flex space-x-2">
        <Button onClick={() => onEdit(country)} className="p-2 bg-black text-white rounded-[30px]">
          <Pencil size={16} />
        </Button>
        <Button onClick={() => onDelete(country._id)} className="p-2 bg-black text-white rounded-[30px]">
          <Trash2 size={16} />
        </Button>
      </div>
    </div>
  );
});

const CountriesManagement = () => {
  const [countries, setCountries] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editingCountry, setEditingCountry] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchCountries = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('/api/countries');
      setCountries(response.data);
    } catch (error) {
      console.error('Ошибка при получении списка стран:', error);
      setCountries([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  const handleSave = async (countryData) => {
    try {
      if (countryData._id) {
        await axios.put(`/api/countries/${countryData._id}`, countryData);
      } else {
        await axios.post('/api/countries', countryData);
      }
      await fetchCountries();
      setIsDialogOpen(false);
      setEditingCountry(null);
    } catch (error) {
      console.error('Ошибка при сохранении страны:', error.response ? error.response.data : error.message);
    }
  };

  const handleDelete = useCallback(async (countryId) => {
    try {
      await axios.delete(`/api/countries/${countryId}`);
      await fetchCountries();
    } catch (error) {
      console.error('Ошибка при удалении страны:', error);
    }
  }, [fetchCountries]);

  const moveCountry = useCallback(
    async (dragIndex, hoverIndex) => {
      const dragCountry = countries[dragIndex];
      const newCountries = [...countries];
      newCountries.splice(dragIndex, 1);
      newCountries.splice(hoverIndex, 0, dragCountry);
      
      setCountries(newCountries);

      try {
        const response = await axios.put('/api/countries/reorder', { 
          countries: newCountries.map(country => ({ _id: country._id }))
        });
        if (response.status === 200) {
          setCountries(response.data);
        } else {
          throw new Error('Не удалось изменить порядок стран');
        }
      } catch (error) {
        console.error('Ошибка при изменении порядка стран:', error.response ? error.response.data : error.message);
        await fetchCountries();
      }
    },
    [countries, fetchCountries],
  );

  const renderCountry = useCallback((country, index) => {
    return (
      <CountryItem
        key={country._id}
        index={index}
        country={country}
        moveCountry={moveCountry}
        onEdit={(country) => {
          setEditingCountry(country);
          setIsDialogOpen(true);
        }}
        onDelete={handleDelete}
      />
    );
  }, [moveCountry, handleDelete]);

  return (
    <DndProvider backend={HTML5Backend}>
      <Card className="bg-transparent">
        <CardContent className="p-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold text-[#eff0f2]">Countries</h2>
            <Button onClick={() => { setEditingCountry(null); setIsDialogOpen(true); }} className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-2.5">Add New Country</Button>
          </div>
          <div className="overflow-y-auto max-h-[calc(100vh-200px)]">
            {isLoading ? (
              <p className="text-[#d3d3d3] text-center">Loading countries...</p>
            ) : countries.length > 0 ? (
              countries.map((country, index) => renderCountry(country, index))
            ) : (
              <p className="text-[#d3d3d3] text-center">No countries available.</p>
            )}
          </div>
          <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogContent className="bg-[#15171c]">
              <DialogHeader>
                <DialogTitle className="text-[#eff0f2]">{editingCountry ? 'Edit Country' : 'Add New Country'}</DialogTitle>
                <DialogDescription className="text-[#d3d3d3]">
                  {editingCountry ? 'Edit the details of the country.' : 'Enter the details of the new country.'}
                </DialogDescription>
              </DialogHeader>
              <CountryForm
                country={editingCountry}
                onSave={handleSave}
                onCancel={() => setIsDialogOpen(false)}
              />
            </DialogContent>
          </Dialog>
        </CardContent>
      </Card>
    </DndProvider>
  );
};

export default CountriesManagement;