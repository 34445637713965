import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from "./ui/card";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { Label } from "./ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { MapPin, Phone, Globe, User } from 'lucide-react';
import {
    COUNTRIES,
    getPhoneCodeByCountry,
    getPhoneNumberMask,
    formatPhoneNumberWithMask,
    validatePhoneNumberWithMask
} from '../constants/countries';

const ShippingAddress = ({ userId, defaultAddress, userProfile, onAddressUpdate }) => {
    console.log('ShippingAddress - Received props:', { userId, defaultAddress, userProfile });

    const hasShippingAddress = Boolean(
        defaultAddress?.fullName &&
        defaultAddress?.phone &&
        defaultAddress?.country &&
        defaultAddress?.city &&
        defaultAddress?.address &&
        defaultAddress?.postalCode
    );

    const [isEditing, setIsEditing] = useState(!hasShippingAddress);

    const [formData, setFormData] = useState({
        fullName: '',
        phone: '',
        phoneCountry: '',
        country: '',
        city: '',
        address: '',
        postalCode: ''
    });

    // Синхронизируем formData с defaultAddress при монтировании и обновлении
    useEffect(() => {
        if (defaultAddress) {
            console.log('Setting form data from defaultAddress:', defaultAddress);

            // Удаляем код страны из номера телефона
            const phoneNumber = defaultAddress.phone ?
                defaultAddress.phone.replace(/^\+\d+\s+/, '') : '';

            setFormData({
                fullName: defaultAddress.fullName || '',
                phone: phoneNumber, // Теперь здесь только номер без кода
                phoneCountry: defaultAddress.country || '',
                country: defaultAddress.country || '',
                city: defaultAddress.city || '',
                address: defaultAddress.address || '',
                postalCode: defaultAddress.postalCode || ''
            });
            setIsEditing(false);
        }
    }, [defaultAddress]);

    // Добавляем консоль лог для отладки
    useEffect(() => {
        console.log('defaultAddress:', defaultAddress);
        console.log('hasShippingAddress:', hasShippingAddress);
        console.log('formData:', formData);
        console.log('isEditing:', isEditing);
    }, [defaultAddress, hasShippingAddress, formData, isEditing]);

    const [errors, setErrors] = useState({});

    // Валидация телефона
    const validatePhone = (phone, country) => {
        if (!phone || !country) return false;
        return validatePhoneNumberWithMask(phone, country);
    };

    // Валидация почтового индекса
    const validatePostalCode = (postalCode, country) => {
        const postalCodeRegex = {
            US: /^\d{5}(-\d{4})?$/,
            GB: /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/i,
            RU: /^\d{6}$/,
        };

        return postalCodeRegex[country]
            ? postalCodeRegex[country].test(postalCode)
            : true;
    };

    // Добавляем функцию валидации только английских букв
    const validateEnglishOnly = (text) => {
        return /^[A-Za-z\s]+$/.test(text);
    };

    // Добавляем функцию для валидации адреса и города
    const validateAddressAndCity = (text) => {
        return /^[A-Za-z0-9\s\-\,\.\#\/]+$/.test(text);
    };

    // Добавляем отдельную функцию для валидации адреса
    const validateAddress = (text) => {
        return /^[A-Za-z0-9\s\-\,\.\#\/]+$/.test(text);
    };

    // Добавляем валидацию email
    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return emailRegex.test(email);
    };

    // Обновляем функцию handleInputChange
    const handleInputChange = (e, field) => {
        const value = e.target.value;

        // Для полей, где нужны только английские буквы
        if (field === 'fullName') {
            if (value && !validateEnglishOnly(value)) {
                setErrors(prev => ({
                    ...prev,
                    [field]: 'Only English letters are allowed'
                }));
                return;
            }
        }

        // Валидация для адреса и города
        if (field === 'address' || field === 'city') {
            if (value && !validateAddressAndCity(value)) {
                setErrors(prev => ({
                    ...prev,
                    [field]: 'Only English letters, numbers and special characters (,-./# etc.) are allowed'
                }));
                return;
            }
        }

        setFormData(prev => ({
            ...prev,
            [field]: value
        }));

        // Очищаем ошибку при вводе
        setErrors(prev => ({
            ...prev,
            [field]: ''
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!userId) {
            toast.error('User ID is required');
            return;
        }

        // Расширенная валидация
        const newErrors = {};

        // Проверка Full Name
        if (!formData.fullName) {
            newErrors.fullName = 'Name is required';
        } else if (!validateEnglishOnly(formData.fullName)) {
            newErrors.fullName = 'Only English letters are allowed';
        }

        // Проверка телефона
        if (!formData.phone || !formData.phoneCountry) {
            newErrors.phone = 'Phone number is required';
        } else if (!validatePhone(formData.phone, formData.phoneCountry)) {
            newErrors.phone = 'Invalid phone number';
        }

        // Остальные проверки...
        if (!formData.country) {
            newErrors.country = 'Country is required';
        }

        if (!formData.city) {
            newErrors.city = 'City is required';
        } else if (!validateAddressAndCity(formData.city)) {
            newErrors.city = 'Only English letters, numbers and special characters (,-./# etc.) are allowed';
        }

        if (!formData.postalCode) {
            newErrors.postalCode = 'Postal code is required';
        } else if (!validatePostalCode(formData.postalCode, formData.country)) {
            newErrors.postalCode = 'Invalid postal code format';
        }

        if (!formData.address) {
            newErrors.address = 'Address is required';
        } else if (!validateAddress(formData.address)) {
            newErrors.address = 'Only English letters, numbers and special characters (,-./# etc.) are allowed';
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        try {
            // Добавляем код страны к номеру телефона
            const dataToSave = {
                ...formData,
                phone: getFullPhoneNumber(formData.phone, formData.phoneCountry || formData.country)
            };

            const response = await axios.put(`/api/users/${userId}/shipping`, dataToSave);
            if (response.data.success) {
                toast.success('Shipping address updated successfully');
                setIsEditing(false);
                onAddressUpdate?.(response.data.address);
            }
        } catch (error) {
            toast.error('Error updating shipping address');
            console.error('Error:', error);
        }
    };

    // Получаем название страны по коду
    const getCountryName = (code) => {
        const country = COUNTRIES.find(c => c.value === code);
        return country ? `${country.icon} ${country.label}` : code;
    };

    const formatPhoneInput = (value, country) => {
        // Очищаем ввод от всего кроме цифр
        let numbers = value.replace(/\D/g, '');

        // Получаем маску
        const mask = getPhoneNumberMask(country);

        // Ограничиваем количество цифр длиной маски
        const maxDigits = (mask.match(/#/g) || []).length;
        numbers = numbers.slice(0, maxDigits);

        // Форматируем номер
        let formattedNumber = '';
        let numberIndex = 0;

        for (let maskIndex = 0; maskIndex < mask.length && numberIndex < numbers.length; maskIndex++) {
            if (mask[maskIndex] === '#') {
                formattedNumber += numbers[numberIndex];
                numberIndex++;
            } else {
                formattedNumber += mask[maskIndex];
            }
        }

        return formattedNumber;
    };

    // Функция для форматирования полного номера с кодом страны
    const getFullPhoneNumber = (phone, country) => {
        const countryCode = getPhoneCodeByCountry(country);
        return `${countryCode} ${phone}`;
    };

    return (
        <Card className="bg-[#1a1a1a] text-white rounded-[20px] overflow-hidden">
            <CardHeader className="bg-[#282828] px-6 py-4">
                <CardTitle className="text-xl font-bold flex items-center gap-2">
                    <MapPin className="w-5 h-5 text-[#e0ff89]" />
                    Shipping Address
                </CardTitle>
            </CardHeader>
            <CardContent className="p-6">
                {!isEditing && defaultAddress ? (
                    <div className="space-y-4">
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <p className="text-[#e0ff89] text-sm flex items-center gap-2">
                                    <User className="w-4 h-4" />
                                    Full Name
                                </p>
                                <p className="font-medium">{defaultAddress.fullName}</p>
                            </div>
                            <div>
                                <p className="text-[#e0ff89] text-sm flex items-center gap-2">
                                    <Phone className="w-4 h-4" />
                                    Phone
                                </p>
                                <p className="font-medium flex items-center gap-2">
                                    <img
                                        src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${defaultAddress.country}.svg`}
                                        alt=""
                                        className="w-4 h-4 rounded-sm object-cover"
                                    />
                                    {defaultAddress.phone}
                                </p>
                            </div>
                            <div>
                                <p className="text-[#e0ff89] text-sm flex items-center gap-2">
                                    <Globe className="w-4 h-4" />
                                    Country
                                </p>
                                <div className="flex items-center gap-2">
                                    <img
                                        src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${defaultAddress.country}.svg`}
                                        alt=""
                                        className="w-4 h-4 rounded-sm object-cover"
                                    />
                                    <span className="font-medium">
                                        {COUNTRIES.find(c => c.value === defaultAddress.country)?.label}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <p className="text-[#e0ff89] text-sm flex items-center gap-2">
                                    <MapPin className="w-4 h-4" />
                                    City
                                </p>
                                <p className="font-medium">
                                    {defaultAddress.city}
                                </p>
                            </div>
                        </div>
                        <div className="pt-4">
                            <p className="text-[#e0ff89] text-sm">Shipping Address</p>
                            <p className="font-medium">
                                {defaultAddress.address}, {defaultAddress.postalCode}
                            </p>
                        </div>
                        <Button
                            onClick={() => setIsEditing(true)}
                            className="mt-4 w-full bg-[#e0ff89] text-black px-4 py-1 h-[34px] hover:bg-[#d1ef7a] rounded-[30px] text-sm"
                        >
                            Edit Address
                        </Button>
                    </div>
                ) : (
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <Label htmlFor="fullName">Full Name *</Label>
                                <Input
                                    id="fullName"
                                    value={formData.fullName}
                                    onChange={(e) => handleInputChange(e, 'fullName')}
                                    className={`bg-black border-[#282828] ${errors.fullName ? 'border-red-500' : ''}`}
                                    placeholder="Enter your full name (English only)"
                                />
                                {errors.fullName && (
                                    <p className="text-red-500 text-sm mt-1">{errors.fullName}</p>
                                )}
                            </div>

                            <div>
                                <Label htmlFor="phone">Phone</Label>
                                <div className="flex gap-2">
                                    <Select
                                        value={formData.phoneCountry || formData.country}
                                        onValueChange={(value) => {
                                            setFormData({
                                                ...formData,
                                                phoneCountry: value,
                                                phone: '' // Сбрасываем номер при смене страны
                                            });
                                        }}
                                    >
                                        <SelectTrigger className="w-[120px] min-w-[120px] bg-black border-[#282828] h-10">
                                            <SelectValue placeholder="Code">
                                                {formData.phoneCountry && (
                                                    <div className="flex items-center gap-2">
                                                        <img
                                                            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${formData.phoneCountry}.svg`}
                                                            alt=""
                                                            className="w-4 h-4 rounded-sm object-cover"
                                                        />
                                                        <span>{getPhoneCodeByCountry(formData.phoneCountry)}</span>
                                                    </div>
                                                )}
                                            </SelectValue>
                                        </SelectTrigger>
                                        <SelectContent
                                            align="start"
                                            sideOffset={8}
                                            className="w-[200px] bg-[#0f1014] border-[#282828] max-h-[300px] overflow-y-auto z-[999]"
                                        >
                                            <div className="max-h-[300px] overflow-y-auto">
                                                {COUNTRIES
                                                    .sort((a, b) => a.phoneCode.localeCompare(b.phoneCode))
                                                    .map((country) => (
                                                        <SelectItem
                                                            key={country.value}
                                                            value={country.value}
                                                            className="py-2 px-4 hover:bg-[#282828] cursor-pointer flex items-center"
                                                        >
                                                            <div className="flex items-center gap-2 text-sm">
                                                                <img
                                                                    src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.value}.svg`}
                                                                    alt=""
                                                                    className="w-4 h-4 rounded-sm object-cover flex-shrink-0"
                                                                />
                                                                <span className="font-medium">{country.phoneCode}</span>
                                                                <span className="text-gray-400 truncate">({country.label})</span>
                                                            </div>
                                                        </SelectItem>
                                                    ))}
                                            </div>
                                        </SelectContent>
                                    </Select>
                                    <div className="flex-1">
                                        <Input
                                            id="phone"
                                            value={formData.phone}
                                            onChange={(e) => {
                                                const country = formData.phoneCountry || formData.country;
                                                if (!country) return;

                                                // Удаляем все нецифровые символы
                                                const numbers = e.target.value.replace(/\D/g, '');

                                                // Форматируем номер согласно маске без кода страны
                                                let formatted = '';
                                                let numberIndex = 0;
                                                const mask = getPhoneNumberMask(country).split('').filter(char => char !== '+' && !char.match(/\d/));

                                                for (let i = 0; numberIndex < numbers.length; i++) {
                                                    if (mask[i] === '#') {
                                                        formatted += numbers[numberIndex];
                                                        numberIndex++;
                                                    } else if (mask[i]) {
                                                        formatted += mask[i];
                                                    } else {
                                                        break;
                                                    }
                                                }

                                                setFormData({
                                                    ...formData,
                                                    phone: formatted
                                                });
                                            }}
                                            placeholder="Enter phone number without country code"
                                            className={`bg-black border-[#282828] ${errors.phone ? 'border-red-500' : ''}`}
                                        />
                                    </div>
                                </div>
                                {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
                            </div>

                            <div>
                                <Label htmlFor="country">Country *</Label>
                                <Select
                                    value={formData.country}
                                    onValueChange={(value) => {
                                        setFormData({
                                            ...formData,
                                            country: value,
                                            city: '',
                                            postalCode: '' // Сбрасываем почтовый индекс при смене страны
                                        });
                                        setErrors(prev => ({
                                            ...prev,
                                            country: ''
                                        }));
                                    }}
                                >
                                    <SelectTrigger className="bg-black border-[#282828]">
                                        <SelectValue placeholder="Select Country">
                                            {formData.country ? COUNTRIES.find(c => c.value === formData.country)?.label : "Select Country"}
                                        </SelectValue>
                                    </SelectTrigger>
                                    <SelectContent
                                        className="z-[999] bg-[#0f1014] max-h-[300px] overflow-y-auto border-[#282828]"
                                    >
                                        {COUNTRIES.sort((a, b) => a.label.localeCompare(b.label)).map(country => (
                                            <SelectItem
                                                key={country.value}
                                                value={country.value}
                                                className="py-2 px-4 hover:bg-[#282828] cursor-pointer"
                                            >
                                                <div className="flex items-center gap-2">
                                                    <img
                                                        src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.value}.svg`}
                                                        alt=""
                                                        className="w-4 h-4 rounded-sm object-cover"
                                                    />
                                                    <span>{country.label}</span>
                                                </div>
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                                {errors.country && <p className="text-red-500 text-sm mt-1">{errors.country}</p>}
                            </div>

                            <div>
                                <Label htmlFor="city">City *</Label>
                                <Input
                                    id="city"
                                    value={formData.city}
                                    onChange={(e) => handleInputChange(e, 'city')}
                                    className={`bg-black border-[#282828] ${errors.city ? 'border-red-500' : ''}`}
                                    placeholder="Enter city name (English only)"
                                />
                                {errors.city && (
                                    <p className="text-red-500 text-sm mt-1">{errors.city}</p>
                                )}
                            </div>

                            <div>
                                <Label htmlFor="postalCode">Postal Code *</Label>
                                <Input
                                    id="postalCode"
                                    value={formData.postalCode}
                                    onChange={(e) => handleInputChange(e, 'postalCode')}
                                    className={`bg-black border-[#282828] ${errors.postalCode ? 'border-red-500' : ''}`}
                                    placeholder="Enter postal code"
                                />
                                {errors.postalCode && <p className="text-red-500 text-sm mt-1">{errors.postalCode}</p>}
                            </div>

                            <div>
                                <Label htmlFor="address">Address *</Label>
                                <Input
                                    id="address"
                                    value={formData.address}
                                    onChange={(e) => handleInputChange(e, 'address')}
                                    className={`bg-black border-[#282828] ${errors.address ? 'border-red-500' : ''}`}
                                    placeholder="Enter address (English only)"
                                />
                                {errors.address && <p className="text-red-500 text-sm mt-1">{errors.address}</p>}
                            </div>

                            <div className="flex gap-4">
                                <Button
                                    type="submit"
                                    className="flex-1 bg-[#e0ff89] text-black px-4 py-1 h-[34px] hover:bg-[#d1ef7a] rounded-[30px] text-sm"
                                >
                                    Save Address
                                </Button>
                                <Button
                                    type="button"
                                    onClick={() => setIsEditing(false)}
                                    className="flex-1 bg-white text-black px-4 py-1 h-[34px] hover:bg-gray-200 rounded-[30px] text-sm"
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </form>
                )}
            </CardContent>
        </Card>
    );
};

export default ShippingAddress;