import React from 'react';

const Dashboard = () => {
  return (
    <div>
      <h2>Dashboard</h2>
      {/* Добавьте содержимое страницы Dashboard */}
    </div>
  );
};

export default Dashboard;