import React, { useState, useEffect, useRef } from 'react';
import { Button } from "./button";
import { X, ChevronDown } from "lucide-react";

const SelectedItem = ({ item, onRemove }) => (
  <div className="flex items-center gap-1 bg-[#15171c] text-white px-2 py-1 text-sm rounded-sm">
    {item.icon && <span>{item.icon}</span>}
    {/* Поддерживаем оба формата: старый с name и новый с label */}
    {item.label || item.name}
    <Button 
      onClick={(e) => {
        e.stopPropagation();
        onRemove(item);
      }}
      variant="ghost" 
      size="sm"
      className="h-4 w-4 p-0 ml-1 text-white hover:bg-[#0f1014]"
    >
      <X className="h-3 w-3" />
    </Button>
  </div>
);

export const MultiSelect = React.memo(({ 
  options, 
  value, 
  selected,
  onChange, 
  placeholder, 
  className 
}) => {
  // Поддерживаем оба способа передачи выбранных значений
  const [selectedValues, setSelectedValues] = useState(selected || value || []);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    setSelectedValues(selected || value || []);
  }, [selected, value]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const handleValueChange = (selectedItem) => {
    // Поддерживаем оба формата идентификации: _id и value
    const idField = selectedItem._id ? '_id' : 'value';
    if (!selectedValues.some(item => item[idField] === selectedItem[idField])) {
      const newValues = [...selectedValues, selectedItem];
      setSelectedValues(newValues);
      onChange(newValues);
    }
    setIsOpen(false);
  };

  const handleRemove = (itemToRemove) => {
    // Поддерживаем оба формата идентификации: _id и value
    const idField = itemToRemove._id ? '_id' : 'value';
    const newValues = selectedValues.filter(item => item[idField] !== itemToRemove[idField]);
    setSelectedValues(newValues);
    onChange(newValues);
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const renderOptions = () => {
    // Проверяем, являются ли options сгруппированными
    if (options && !Array.isArray(options) && typeof options === 'object') {
      return Object.entries(options).map(([groupLabel, groupOptions]) => (
        <div key={groupLabel}>
          <div className="px-4 py-2 text-sm font-semibold text-[#888]">{groupLabel}</div>
          {groupOptions.map((option) => (
            <div 
              key={option.value || option._id}
              className="px-4 py-2 cursor-pointer hover:bg-[#15171c] text-[#d3d3d3] flex items-center gap-2"
              onClick={() => handleValueChange(option)}
            >
              {option.icon && <span>{option.icon}</span>}
              {option.label || option.name}
              {option.nativeName && <span className="text-[#888]">({option.nativeName})</span>}
            </div>
          ))}
        </div>
      ));
    }

    // Для обычного массива опций
    return options.map((option) => (
      <div 
        key={option.value || option._id}
        className="px-4 py-2 cursor-pointer hover:bg-[#15171c] text-[#d3d3d3] flex items-center gap-2"
        onClick={() => handleValueChange(option)}
      >
        {option.icon && <span>{option.icon}</span>}
        {option.label || option.name}
        {option.nativeName && <span className="text-[#888]">({option.nativeName})</span>}
      </div>
    ));
  };

  return (
    <div className="space-y-2 relative" ref={ref}>
      <div 
        onClick={toggleOpen}
        className={`w-full bg-black text-[#d3d3d3] border-[#282828] border-[1px] rounded-md p-2 cursor-pointer flex items-center justify-between ${className}`}
      >
        <div className="flex flex-wrap gap-1 max-h-20 overflow-y-auto">
          {selectedValues.length > 0 ? (
            selectedValues.map((item) => (
              <SelectedItem 
                key={item.value || item._id}
                item={item}
                onRemove={handleRemove}
              />
            ))
          ) : (
            <span className="text-[#d3d3d3]">{placeholder}</span>
          )}
        </div>
        <ChevronDown className={`h-4 w-4 transition-transform ${isOpen ? 'transform rotate-180' : ''}`} />
      </div>
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-[#0f1014] border border-[#282828] rounded-md shadow-lg max-h-60 overflow-auto">
          {renderOptions()}
        </div>
      )}
    </div>
  );
});

export default MultiSelect;