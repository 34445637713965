import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './AdminLogin.css';

const AdminLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await axios.post('/api/admin/login', { email, password });
        const { token, user } = response.data;
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
        console.log('Token saved:', token); // Добавим для отладки
        navigate('/admin');
    } catch (error) {
        console.error('Login error:', error);
        setError(error.response?.data?.message || 'An error occurred during login');
    }
};

    return (
        <div className="min-h-screen flex items-center justify-center bg-cover bg-center" style={{backgroundImage: "url('/images/general/login.jpg')"}}>
            <div className="flex flex-col lg:flex-row w-full max-w-5xl">
                {/* Left side (Logo and text) */}
                <div className="lg:w-1/2 flex flex-col justify-center items-center lg:items-start p-10">
                    <h2 className="text-4xl font-bold mb-4 text-white">battle brands.</h2>
                    <p className="text-gray-200">Find out how many people choose your brand</p>
                </div>
                
                {/* Right side (Login form) */}
                <div className="lg:w-1/2 p-10">
                    <div className="login-card bg-[#1e1e2d] p-10 rounded-[30px]">
                        <h1 className="text-2xl font-bold mb-6 text-center text-white">Sign In</h1>
                        {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
                        <form onSubmit={handleSubmit}>
                            <div className="mb-6">
                                <input 
                                    type="email" 
                                    placeholder="Email" 
                                    className="w-full px-4 py-3 rounded-full bg-gray-700 text-white"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mb-6">
                                <input 
                                    type="password" 
                                    placeholder="Password" 
                                    className="w-full px-4 py-3 rounded-full bg-gray-700 text-white"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <button 
                                type="submit" 
                                className="w-full py-3 font-semibold rounded-full bg-[#e0ff89] text-black hover:bg-[#c8e57b]"
                            >
                                Login
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminLogin;