import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import { Card, CardContent } from "../ui/card";
import { Button } from "../ui/button";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogFooter } from "../ui/dialog";
import { Pencil, Trash2, ChevronLeft, ChevronRight, ArrowUpDown, MoveVertical, MoveDown, MoveUp } from "lucide-react";
import BrandForm from './BrandForm';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../ui/tooltip";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { SiDiscord, SiFacebook, SiInstagram, SiKakaotalk, SiLine, SiLinkedin, SiMedium, SiOdnoklassniki, SiOnlyfans, SiPatreon, SiPinterest, SiTencentqq, SiReddit, SiSnapchat, SiTelegram, SiThreads, SiTiktok, SiTumblr, SiTwitch, SiViber, SiVk, SiWechat, SiSinaweibo, SiWhatsapp, SiX, SiYoutube } from 'react-icons/si';
import { Input } from "../ui/input";

const socialIcons = {
  Discord: SiDiscord,
  Facebook: SiFacebook,
  Instagram: SiInstagram,
  KakaoTalk: SiKakaotalk,
  Line: SiLine,
  LinkedIn: SiLinkedin,
  Medium: SiMedium,
  Ok: SiOdnoklassniki,
  Onlyfans: SiOnlyfans,
  Patreon: SiPatreon,
  Pinterest: SiPinterest,
  QQ: SiTencentqq,
  Reddit: SiReddit,
  Snapchat: SiSnapchat,
  Telegram: SiTelegram,
  Threads: SiThreads,
  TikTok: SiTiktok,
  Tumblr: SiTumblr,
  Twitch: SiTwitch,
  Viber: SiViber,
  Vk: SiVk,
  WeChat: SiWechat,
  Weibo: SiSinaweibo,
  WhatsApp: SiWhatsapp,
  X: SiX,
  YouTube: SiYoutube,
};

const BrandsManagement = () => {
  const [brands, setBrands] = useState(null);
  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editingBrand, setEditingBrand] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [brandsPerPage, setBrandsPerPage] = useState(10);
  const [totalBrands, setTotalBrands] = useState(0);
  const [sortColumn, setSortColumn] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchQuery, setSearchQuery] = useState('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deletingBrandId, setDeletingBrandId] = useState(null);
  const [statusFilter, setStatusFilter] = useState('all');
  const [pendingBrandsCount, setPendingBrandsCount] = useState(0);
  const [categoryFilter, setCategoryFilter] = useState('all');

  const getBrandStatus = (brand) => {
    if (brand.status === 'pending') return "pending";
    if (brand.activeDays === -1) return "active";
    const currentDate = new Date();
    const createdDate = new Date(brand.createdAt);
    const daysSinceCreation = Math.floor((currentDate - createdDate) / (1000 * 60 * 60 * 24));
    return daysSinceCreation < brand.activeDays ? "active" : "expired";
  };

  const fetchData = useCallback(async () => {
    try {
      console.log('Fetching data with params:', { currentPage, brandsPerPage, sortColumn, sortOrder, statusFilter, categoryFilter });
      const [brandsRes, categoriesRes, countriesRes, pendingCountRes] = await Promise.all([
        axios.get(`/api/brands?page=${currentPage}&limit=${brandsPerPage}&sort=${sortColumn}&order=${sortOrder}&status=${statusFilter}&category=${categoryFilter}`),
        axios.get('/api/categories'),
        axios.get('/api/countries'),
        axios.get('/api/brands/pending-count')
      ]);
      console.log('Full brands response:', brandsRes);
      console.log('Brands data:', brandsRes.data);
      if (brandsRes.data && Array.isArray(brandsRes.data.brands)) {
        setBrands(brandsRes.data.brands);
        setTotalBrands(brandsRes.data.total);
      } else {
        console.error('Invalid brands data format:', brandsRes.data);
        setBrands([]);
        setTotalBrands(0);
      }
      setCategories(categoriesRes.data || []);
      setCountries(countriesRes.data || []);
      setPendingBrandsCount(pendingCountRes.data.count);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      setBrands([]);
      setTotalBrands(0);
      setPendingBrandsCount(0);
    }
  }, [currentPage, brandsPerPage, sortColumn, sortOrder, statusFilter, categoryFilter]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleApprove = useCallback(async (brandId) => {
    try {
      await axios.put(`/api/brands/${brandId}/approve`);
      fetchData();
    } catch (error) {
      console.error('Error approving brand:', error);
      alert('Failed to approve brand. Please try again.');
    }
  }, [fetchData]);

  const handleSave = useCallback(async (brandData) => {
    try {
      console.log('Sending brand data:', brandData);
      let savedBrand;
      if (editingBrand) {
        const response = await axios.put(`/api/brands/${editingBrand._id}`, brandData);
        savedBrand = response.data;
      } else {
        const response = await axios.post('/api/brands', brandData);
        savedBrand = response.data;
      }
      console.log('Saved brand:', savedBrand);
      await fetchData();
      setIsDialogOpen(false);
      setEditingBrand(null);
    } catch (error) {
      console.error('Error saving brand:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
    }
  }, [editingBrand, fetchData]);

  const handleDelete = useCallback((brandId) => {
    setDeletingBrandId(brandId);
    setIsDeleteDialogOpen(true);
  }, []);

  const confirmDelete = useCallback(async () => {
    if (deletingBrandId) {
      try {
        const response = await axios.delete(`/api/brands/${deletingBrandId}`);
        if (response.status === 200) {
          console.log('Brand deleted successfully');
          await fetchData();
        } else {
          console.error('Unexpected response status:', response.status);
        }
      } catch (error) {
        console.error('Error deleting brand:', error);
        alert('Failed to delete brand. Please try again.');
      }
      setIsDeleteDialogOpen(false);
      setDeletingBrandId(null);
    }
  }, [deletingBrandId, fetchData]);

  const handleAddNewBrand = () => {
    setEditingBrand(null);
    setIsDialogOpen(true);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleBrandsPerPageChange = (value) => {
    setBrandsPerPage(Number(value));
    setCurrentPage(1);
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const renderSortIcon = (column) => {
    if (column === sortColumn) {
      return sortOrder === 'asc' ? <MoveUp className="ml-2 h-4 w-4 inline" /> : <MoveDown className="ml-2 h-4 w-4 inline" />;
    }
    return <MoveVertical className="ml-2 h-4 w-4 inline" />;
  };

  const filteredBrands = brands ? brands.filter(brand =>
    brand.name.toLowerCase().includes(searchQuery.toLowerCase())
  ) : [];

  return (
    <Card className="bg-transparent">
      <CardContent className="p-6">
        <div className="flex flex-col space-y-4 mb-4">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold text-[#eff0f2]">Brands</h2>
            <Button onClick={handleAddNewBrand} className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4">Add New Brand</Button>
          </div>
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center space-x-4">
              <Input
                type="text"
                placeholder="Search Brand"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-64 bg-black text-white placeholder-gray-500 border-gray-700 focus:border-[#e0ff89] focus:ring-[#e0ff89]"
              />
              <Select value={statusFilter} onValueChange={setStatusFilter}>
                <SelectTrigger className="w-[150px] bg-[#15171c] text-[#d3d3d3]">
                  <SelectValue placeholder="Filter by status" />
                </SelectTrigger>
                <SelectContent className="bg-[#15171c] text-[#d3d3d3]">
                  <SelectItem value="all">All</SelectItem>
                  <SelectItem value="pending">Pending</SelectItem>
                  <SelectItem value="active">Active</SelectItem>
                  <SelectItem value="expired">Expired</SelectItem>
                </SelectContent>
              </Select>
              <Select value={categoryFilter} onValueChange={setCategoryFilter}>
                <SelectTrigger className="w-[150px] bg-[#15171c] text-[#d3d3d3]">
                  <SelectValue placeholder="Filter by category" />
                </SelectTrigger>
                <SelectContent className="bg-[#15171c] text-[#d3d3d3]">
                  <SelectItem value="all">All Categories</SelectItem>
                  {categories.map(category => (
                    <SelectItem key={category._id} value={category._id}>{category.name}</SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="flex items-center space-x-4">
              <span className="text-[#d3d3d3]">Pending Brands: {pendingBrandsCount}</span>
              <span className="text-[#d3d3d3]">Total Brands: {totalBrands}</span>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto max-h-[calc(100vh-300px)] overflow-y-auto">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="text-[#d3d3d3]">Logo</TableHead>
                <TableHead className="text-[#d3d3d3] cursor-pointer" onClick={() => handleSort('name')}>
                  <div className="flex items-center justify-between">
                    <span>Name</span>
                    <span>{renderSortIcon('name')}</span>
                  </div>
                </TableHead>
                <TableHead className="text-[#d3d3d3] cursor-pointer" onClick={() => handleSort('category')}>
                  <div className="flex items-center justify-between">
                    <span>Category</span>
                    <span>{renderSortIcon('category')}</span>
                  </div>
                </TableHead>
                <TableHead className="text-[#d3d3d3]">Country</TableHead>
                <TableHead className="text-[#d3d3d3] cursor-pointer" onClick={() => handleSort('global')}>
                  <div className="flex items-center justify-between">
                    <span>Global</span>
                    <span>{renderSortIcon('global')}</span>
                  </div>
                </TableHead>
                <TableHead className="text-[#d3d3d3]">Description</TableHead>
                <TableHead className="text-[#d3d3d3] cursor-pointer" onClick={() => handleSort('frequency')}>
                  <div className="flex items-center justify-between">
                    <span>Freq</span>
                    <span>{renderSortIcon('frequency')}</span>
                  </div>
                </TableHead>
                <TableHead className="text-[#d3d3d3] cursor-pointer" onClick={() => handleSort('activeDays')}>
                  <div className="flex items-center justify-between">
                    <span>Day</span>
                    <span>{renderSortIcon('activeDays')}</span>
                  </div>
                </TableHead>
                <TableHead className="text-[#d3d3d3]">Website</TableHead>
                <TableHead className="text-[#d3d3d3]">Social</TableHead>
                <TableHead className="text-[#d3d3d3] cursor-pointer" onClick={() => handleSort('status')}>
                  <div className="flex items-center justify-between">
                    <span>Status</span>
                    <span>{renderSortIcon('status')}</span>
                  </div>
                </TableHead>
                <TableHead className="text-[#d3d3d3] cursor-pointer" onClick={() => handleSort('addedBy')}>
                  <div className="flex items-center justify-between">
                    <span>User</span>
                    <span>{renderSortIcon('addedBy')}</span>
                  </div>
                </TableHead>
                <TableHead className="text-[#d3d3d3]">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredBrands.length > 0 ? (
                filteredBrands.map(brand => (
                  <TableRow key={brand._id} className="hover:bg-black">
                    <TableCell>
                      {brand.logo ? (
                        <img src={brand.logo} alt={`${brand.name} logo`} className="w-10 h-10 object-cover rounded-full" />
                      ) : (
                        <div className="w-10 h-10 bg-gray-300 rounded-full"></div>
                      )}
                    </TableCell>
                    <TableCell className="text-[#d3d3d3]">{brand.name}</TableCell>
                    <TableCell className="text-[#d3d3d3]">
                      {brand.categories.map((cat, index) => (
                        <span key={index}>
                          {`${cat.category?.name || ''} > ${cat.subCategory || ''} ${cat.subSubCategory ? `> ${cat.subSubCategory}` : ''}`}
                          {index < brand.categories.length - 1 ? ', ' : ''}
                        </span>
                      ))}
                    </TableCell>
                    <TableCell>
                      <div className="flex space-x-1">
                        {brand.countries.map(country => (
                          <TooltipProvider key={country._id}>
                            <Tooltip>
                              <TooltipTrigger>
                                <img src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`} alt={country.name} className="w-5 h-5" />
                              </TooltipTrigger>
                              <TooltipContent>
                                <p>{country.name}</p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        ))}
                      </div>
                    </TableCell>
                    <TableCell className="text-[#d3d3d3]">{brand.global ? 'Yes' : 'No'}</TableCell>
                    <TableCell className="text-[#d3d3d3]">
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger>
                            <span className="truncate block max-w-[200px]">{brand.description || 'N/A'}</span>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>{brand.description || 'No description available'}</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </TableCell>
                    <TableCell className="text-[#d3d3d3]">{brand.frequency}</TableCell>
                    <TableCell className="text-[#d3d3d3]">{brand.activeDays === -1 ? '∞' : brand.activeDays}</TableCell>
                    <TableCell className="text-[#d3d3d3]">
                      <a href={brand.website} target="_blank" rel="noopener noreferrer" className="hover:text-[#e0ff89]">
                        {brand.website ? 'Link' : 'N/A'}
                      </a>
                    </TableCell>
                    <TableCell>
                      <div className="flex space-x-1">
                        {brand.socialMedia.map(social => {
                          const Icon = socialIcons[social.platform];
                          return (
                            <a
                              key={social.platform}
                              href={social.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-[#d3d3d3] hover:text-[#e0ff89]"
                            >
                              <Icon size={16} />
                            </a>
                          );
                        })}
                      </div>
                    </TableCell>
                    <TableCell>
                      <span className={
                        brand.status === "active" ? "text-[#e0ff89]" :
                          brand.status === "expired" ? "text-red-500" :
                            brand.status === "pending" ? "text-yellow-500" :
                              "text-[#d3d3d3]"
                      }>
                        {brand.status}
                      </span>
                    </TableCell>
                    <TableCell className="text-[#d3d3d3]">
                      {brand.owner && brand.owner.username ? '@' + brand.owner.username : 'Admin'}
                    </TableCell>
                    <TableCell>
                      <div className="flex space-x-2">
                        {brand.status === 'pending' && (
                          <Button onClick={() => handleApprove(brand._id)} className="p-2 bg-green-500 text-white rounded-[30px]">
                            Approve
                          </Button>
                        )}
                        <Button onClick={() => { setEditingBrand(brand); setIsDialogOpen(true); }} className="p-2 bg-black text-white rounded-[30px]">
                          <Pencil size={16} />
                        </Button>
                        <Button onClick={() => handleDelete(brand._id)} className="p-2 bg-black text-white rounded-[30px]">
                          <Trash2 size={16} />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={13} className="text-center text-[#d3d3d3]">
                    {brands === null ? 'Loading...' : 'No brands found'}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <div className="mt-4 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <span className="text-[#d3d3d3]">Brands per page:</span>
            <Select value={brandsPerPage.toString()} onValueChange={handleBrandsPerPageChange}>
              <SelectTrigger className="w-[70px] bg-[#15171c] text-[#d3d3d3]">
                <SelectValue />
              </SelectTrigger>
              <SelectContent className="bg-[#15171c] text-[#d3d3d3]">
                {[10, 20, 50, 100].map(value => (
                  <SelectItem key={value} value={value.toString()}>{value}</SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex items-center space-x-2">
            <Button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-full p-2"
            >
              <ChevronLeft size={16} />
            </Button>
            <span className="text-[#d3d3d3]">Page {currentPage} of {Math.ceil(totalBrands / brandsPerPage)}</span>
            <Button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === Math.ceil(totalBrands / brandsPerPage)}
              className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-full p-2"
            >
              <ChevronRight size={16} />
            </Button>
          </div>
        </div>
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogContent className="max-h-[80vh] overflow-y-auto bg-[#15171c]">
            <DialogHeader>
              <DialogTitle className="text-[#eff0f2]">{editingBrand ? 'Edit Brand' : 'Add New Brand'}</DialogTitle>
              <DialogDescription className="text-[#d3d3d3]">
                {editingBrand ? 'Edit the details of the brand.' : 'Enter the details of the new brand.'}
              </DialogDescription>
            </DialogHeader>
            <BrandForm
              brand={editingBrand}
              onSave={handleSave}
              onCancel={() => setIsDialogOpen(false)}
              categories={categories}
              countries={countries}
            />
          </DialogContent>
        </Dialog>
      </CardContent>
      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent className="bg-black text-white rounded-[30px] p-6">
          <DialogHeader>
            <DialogTitle className="text-xl font-bold">Confirm Deletion</DialogTitle>
            <DialogDescription className="text-[#d3d3d3]">
              Are you sure you want to delete this brand? This action cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="mt-6 flex justify-end space-x-4">
            <Button
              onClick={() => setIsDeleteDialogOpen(false)}
              className="bg-white text-black hover:bg-gray-200 rounded-[30px] py-1 px-4"
            >
              Cancel
            </Button>
            <Button
              onClick={confirmDelete}
              className="bg-red-500 text-white hover:bg-red-600 rounded-[30px] py-1 px-4"
            >
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default BrandsManagement;