import React, { useState, useEffect, useRef } from 'react';
import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer, CartesianGrid } from 'recharts';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { X, Info, Star } from 'lucide-react';
import { Avatar, AvatarImage, AvatarFallback } from "../components/ui/avatar";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../components/ui/dialog";
import axios from 'axios';
import { toast } from 'react-hot-toast';
import ReactCountryFlag from "react-country-flag";
import {
    SiDiscord,
    SiFacebook,
    SiInstagram,
    SiKakaotalk,
    SiLine,
    SiLinkedin,
    SiMedium,
    SiOdnoklassniki,
    SiOnlyfans,
    SiPatreon,
    SiPinterest,
    SiTencentqq,
    SiReddit,
    SiSnapchat,
    SiTelegram,
    SiThreads,
    SiTiktok,
    SiTumblr,
    SiTwitch,
    SiViber,
    SiVk,
    SiWechat,
    SiSinaweibo,
    SiWhatsapp,
    SiX,
    SiYoutube
} from 'react-icons/si';

const BOTTOM_MENU_HEIGHT = '4rem';

const gameContainerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    paddingBottom: BOTTOM_MENU_HEIGHT,
    backgroundColor: 'black',
    zIndex: 50,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
};

const HOUSE_EDGE = 0.03;
const CRASH_CHANCE = 0.07;
const GROWTH_CHANCE = 0.9;
const MAX_MULTIPLIER = 10;

const generateData = (length) => {
    let data = [];
    let value = 1;
    for (let i = 0; i < length; i++) {
        if (Math.random() < CRASH_CHANCE) {
            value = 1;
        } else if (Math.random() < GROWTH_CHANCE) {
            value = Math.min(value * (1 + (Math.random() * 0.2 - HOUSE_EDGE)), MAX_MULTIPLIER);
        } else {
            value = Math.max(value * (1 - Math.random() * 0.1), 1);
        }
        data.push({ time: i, value: value });
    }
    return data;
};

const BrandGrowthGame = ({ user, setUser, setGlobalUser, onClose, updateUserData }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentMultiplier, setCurrentMultiplier] = useState(1);
    const [data, setData] = useState(generateData(1000));
    const [visibleData, setVisibleData] = useState([]);
    const [bet, setBet] = useState(100);
    const [result, setResult] = useState(null);
    const [winAmount, setWinAmount] = useState(0);
    const [cashedOut, setCashedOut] = useState(false);
    const [crashed, setCrashed] = useState(false);
    const [currentBalance, setCurrentBalance] = useState(user.coins);
    const [gameEnded, setGameEnded] = useState(false);
    const timerRef = useRef(null);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [isBrandInfoOpen, setIsBrandInfoOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [animatingBalance, setAnimatingBalance] = useState(currentBalance);
    const [isAnimating, setIsAnimating] = useState(false);

    const socialIcons = {
        Discord: SiDiscord,
        Facebook: SiFacebook,
        Instagram: SiInstagram,
        KakaoTalk: SiKakaotalk,
        Line: SiLine,
        LinkedIn: SiLinkedin,
        Medium: SiMedium,
        Ok: SiOdnoklassniki,
        Onlyfans: SiOnlyfans,
        Patreon: SiPatreon,
        Pinterest: SiPinterest,
        QQ: SiTencentqq,
        Reddit: SiReddit,
        Snapchat: SiSnapchat,
        Telegram: SiTelegram,
        Threads: SiThreads,
        TikTok: SiTiktok,
        Tumblr: SiTumblr,
        Twitch: SiTwitch,
        Viber: SiViber,
        Vk: SiVk,
        WeChat: SiWechat,
        Weibo: SiSinaweibo,
        WhatsApp: SiWhatsapp,
        X: SiX,
        YouTube: SiYoutube,
    };

    const handleWebsiteClick = async (brandId, websiteUrl) => {
        try {
            await axios.post(`/api/brands/${brandId}/click/website`);
            window.open(websiteUrl, '_blank');
        } catch (error) {
            console.error('Error registering website click:', error);
            toast.error("Failed to register click", {
                duration: 3000,
                position: 'top-center',
            });
        }
    };

    const handleSocialMediaClick = async (brandId, platform, socialUrl) => {
        try {
            await axios.post(`/api/brands/${brandId}/click/social`, { platform });
            window.open(socialUrl, '_blank');
        } catch (error) {
            console.error('Error registering social media click:', error);
            toast.error("Failed to register click", {
                duration: 3000,
                position: 'top-center',
            });
        }
    };

    const [brands, setBrands] = useState([]);

    const animateBalance = (start, end, duration) => {
        setIsAnimating(true);
        const startTime = Date.now();
        const animate = () => {
            const now = Date.now();
            const progress = Math.min((now - startTime) / duration, 1);
            const current = Math.floor(start + progress * (end - start));
            setAnimatingBalance(current);
            if (progress < 1) {
                requestAnimationFrame(animate);
            } else {
                setIsAnimating(false);
            }
        };
        requestAnimationFrame(animate);
    };

    useEffect(() => {
        setCurrentBalance(user.coins);
    }, [user.coins]);

    useEffect(() => {
        const fetchBrands = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get('/api/brands');
                console.log('Fetched brands:', response.data);
                if (response.data && response.data.brands && response.data.brands.length > 0) {
                    const activeBrands = response.data.brands.filter(brand => brand.status === 'active');
                    setBrands(activeBrands);
                    if (activeBrands.length > 0) {
                        const randomBrand = activeBrands[Math.floor(Math.random() * activeBrands.length)];
                        setSelectedBrand(randomBrand);
                    }
                } else {
                    console.warn('No active brands found');
                    toast.warning("No active brands available for the game", {
                        duration: 3000,
                        position: 'top-center',
                    });
                }
            } catch (error) {
                console.error('Error fetching brands:', error);
                toast.error("Failed to load brands. Please try again.", {
                    duration: 3000,
                    position: 'top-center',
                });
            } finally {
                setIsLoading(false);
            }
        };
        fetchBrands();
    }, [toast]);

const startGame = async () => {
    if (currentBalance < bet) {
        toast.error("Not enough funds for a bet!", {
            duration: 3000,
            position: 'top-center',
        });
        return;
    }
    
    const newBalance = currentBalance - bet;
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            throw new Error('No authentication token found');
        }
        
        const response = await axios.post('/api/users/update-balance', 
            { coins: newBalance },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        
        const updatedUser = response.data.user;
        setUser(updatedUser);
        setGlobalUser(updatedUser);
        setCurrentBalance(updatedUser.coins);

        // Добавляем событие обновления баланса здесь
        window.dispatchEvent(new Event('balanceUpdate'));
        
        setIsPlaying(true);
        setVisibleData([]);
        setResult(null);
        setWinAmount(0);
        setCashedOut(false);
        setCurrentMultiplier(1);
        setData(generateData(1000));
        setCrashed(false);
        setGameEnded(false);
        
        if (brands.length > 0) {
            const randomBrand = brands[Math.floor(Math.random() * brands.length)];
            setSelectedBrand(randomBrand);
        }
    } catch (error) {
        console.error("Error updating balance:", error);
        toast.error("Failed to start game. Please try again.", {
            duration: 3000,
            position: 'top-center',
        });
    }
};

const cashOut = async () => {
    const winAmount = Math.floor(bet * currentMultiplier);
    const newBalance = currentBalance + winAmount;
    try {
        console.log('Attempting to cash out with new balance:', newBalance);
        const token = localStorage.getItem('token');
        const telegramInitData = localStorage.getItem('telegramInitData');
        
        if (!token) {
            throw new Error('No authentication token found');
        }
        
        console.log('Sending update balance request...');
        const response = await axios.post('/api/users/update-balance', 
            { coins: newBalance }, 
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-Telegram-Init-Data': telegramInitData,
                    'Content-Type': 'application/json'
                }
            }
        );
        
        console.log('Update balance response:', response.data);
        const updatedUser = response.data.user;
        setUser(updatedUser);
        setGlobalUser(updatedUser);
        setCurrentBalance(updatedUser.coins);
        animateBalance(currentBalance, newBalance, 1000);
        
        // Диспатчим событие для обновления баланса в родительском компоненте
        const event = new CustomEvent('balanceUpdate', { 
            detail: { balance: updatedUser.coins }
        });
        window.dispatchEvent(event);
        
        setResult(winAmount);
        setWinAmount(winAmount);
        setCashedOut(true);
        
        toast.success(`You won ${winAmount} coins!`, {
            duration: 3000,
            position: 'top-center',
        });
    } catch (error) {
        console.error("Error updating balance:", error);
        if (error.response) {
            console.error("Error response:", error.response.data);
        }
        toast.error("Failed to update balance. Please try again.", {
            duration: 3000,
            position: 'top-center',
        });
        setCurrentBalance(currentBalance);
    }
};

    // Добавляем useEffect для обработки закрытия игры
useEffect(() => {
    return () => {
        const updateFinalBalance = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) return;

                // Обновляем баланс пользователя используя текущий баланс из игры
                const response = await axios.post('/api/users/update-balance', 
                    { coins: currentBalance },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    }
                );
                
                const updatedUser = response.data.user;
                
                // Обновляем состояние в родительских компонентах
                setUser(updatedUser);
                setGlobalUser(updatedUser);
                
                // Обновляем через колбэк если он предоставлен
                if (updateUserData) {
                    await updateUserData(updatedUser);
                }

            } catch (error) {
                console.error("Error updating final balance:", error);
                toast.error("Failed to sync game balance", {
                    duration: 3000,
                    position: 'top-center',
                });
            }
        };
        
        // Вызываем обновление только если баланс изменился
        if (currentBalance !== user.coins) {
            updateFinalBalance();
        }
    };
}, [currentBalance, user.coins, setUser, setGlobalUser, updateUserData]);

const endGame = () => {
    clearInterval(timerRef.current);
    setIsPlaying(false);
    setCrashed(true);
    setGameEnded(true);
    // Показываем сообщение о проигрыше только если не было выигрыша
    if (!cashedOut) {
        setResult(0);

    }
};

    useEffect(() => {
        if (isPlaying) {
            timerRef.current = setInterval(() => {
                setVisibleData(prevData => {
                    if (prevData.length >= data.length - 1) {
                        endGame();
                        return prevData;
                    }
                    const newDataPoint = data[prevData.length];
                    setCurrentMultiplier(newDataPoint.value);
                    if (newDataPoint.value === 1 && prevData[prevData.length - 1]?.value > 1) {
                        endGame();
                    }
                    return [...prevData, newDataPoint];
                });
            }, 100);
        }
        return () => clearInterval(timerRef.current);
    }, [isPlaying, data]);

    const renderButton = () => {
        const isDisabled = !isPlaying || cashedOut;
        const showLoss = gameEnded && !cashedOut;
        const buttonClass = `w-full ${showLoss ? 'bg-red-500 text-white' : 'bg-[#e0ff89] text-black'} hover:bg-[#d1ef7a] font-bold rounded-[30px] h-12 flex items-center justify-center`;

        return (
            <Button
                onClick={cashOut}
                disabled={isDisabled}
                className={buttonClass}
            >
                {isPlaying
                    ? (cashedOut ? `Win: ${winAmount}` : 'Cash Out')
                    : (gameEnded
                        ? (cashedOut ? `Win: ${winAmount}` : 'Loss')
                        : 'Cash Out')}
            </Button>
        );
    };

    const openBrandInfo = () => {
        setIsBrandInfoOpen(true);
    };

    return (
        <div style={gameContainerStyle}>
            <div className="text-white flex flex-col h-full p-4">
                <div className="flex items-center justify-between mb-4">
                    <h2 className="text-2xl font-bold">Brand Growth</h2>
                    <button
                        onClick={onClose}
                        className="text-white cursor-pointer focus:outline-none"
                    >
                        <X size={24} />
                    </button>
                </div>
                <div className="flex items-center mb-4">
                    {isLoading ? (
                        <h2 className="text-lg font-bold">Loading brand...</h2>
                    ) : (
                        <>
                            <div onClick={openBrandInfo} className="flex items-center cursor-pointer">
                                <Avatar className="w-8 h-8 mr-2">
                                    <AvatarImage src={selectedBrand?.logo || '/default-logo.png'} alt={selectedBrand?.name || 'Default Brand'} />
                                    <AvatarFallback>{selectedBrand?.name?.[0] || 'D'}</AvatarFallback>
                                </Avatar>
                                <h2 className="text-lg font-bold flex-grow mr-2">{selectedBrand?.name || 'Default Brand'}</h2>
                                <div className="w-6 h-6 rounded-full flex items-center justify-center">
                                    <Info className="h-4 w-4 text-gray-400" />
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {!isLoading && (
                    <div className="flex-grow mb-4 min-h-[200px]">
                        <ResponsiveContainer width="100%" height="100%">
                            <AreaChart data={visibleData.length > 0 ? visibleData : [{ time: 0, value: 1 }, { time: 100, value: MAX_MULTIPLIER }]}>
                                <XAxis dataKey="time" hide />
                                <YAxis hide domain={[1, MAX_MULTIPLIER]} />
                                <CartesianGrid strokeDasharray="3 3" stroke="#333" />
                                <Area
                                    type="monotone"
                                    dataKey="value"
                                    stroke={crashed ? "#FF0000" : "#e0ff89"}
                                    fill={crashed ? "#FF0000" : "#e0ff89"}
                                    fillOpacity={0.3}
                                />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                )}
                <div className="flex justify-between items-center mb-4">
                    <div className="text-3xl font-bold">x{currentMultiplier.toFixed(2)}</div>
                    <div className="text-xl font-bold flex items-center">
                        {isAnimating ? animatingBalance : currentBalance}
                        <img src="/images/general/coin.svg" alt="coin" className="w-6 h-6 ml-1" />
                    </div>
                </div>
                <div className="flex justify-between gap-4 mb-4">
                    <div className="flex-grow">
                        <div className="w-full bg-black text-white border-2 border-white rounded-[30px] h-12 flex items-center justify-between px-2">
                            <button
                                onClick={() => setBet(Math.max(100, bet - 100))}
                                disabled={isPlaying}
                                className="w-8 h-8 bg-white rounded-full flex items-center justify-center"
                            >
                                <span className="text-black text-2xl font-bold leading-none">-</span>
                            </button>
                            <span className="text-xl font-bold">{bet}</span>
                            <button
                                onClick={() => setBet(bet + 100)}
                                disabled={isPlaying}
                                className="w-8 h-8 bg-white rounded-full flex items-center justify-center"
                            >
                                <span className="text-black text-2xl font-bold leading-none">+</span>
                            </button>
                        </div>
                    </div>
                    <div className="w-1/3">
                        {renderButton()}
                    </div>
                </div>
                <Button
                    onClick={startGame}
                    disabled={isPlaying}
                    className="w-full bg-[#e0ff89] hover:bg-[#d1ef7a] text-black font-bold rounded-[30px] h-12"
                >
                    {isPlaying ? 'Game in progress...' : 'Start Trade'}
                </Button>
            </div>
            <Dialog open={isBrandInfoOpen} onOpenChange={setIsBrandInfoOpen}>
                <DialogContent className="bg-black rounded-[30px] p-6">
                    <DialogHeader>
                        <DialogTitle className="text-white flex items-center justify-between">
                            <div className="flex items-center">
                                <Avatar className="w-12 h-12 mr-4">
                                    <AvatarImage src={selectedBrand?.logo} alt={selectedBrand?.name} />
                                    <AvatarFallback>{selectedBrand?.name?.[0]}</AvatarFallback>
                                </Avatar>
                                <div>
                                    <h2 className="text-2xl font-bold text-left">{selectedBrand?.name}</h2>
                                    <div className="flex items-center mt-1">
                                        {[1, 2, 3, 4, 5].map((star) => {
                                            const rating = selectedBrand?.rating || 0;
                                            const fillPercentage = Math.min(100, Math.max(0, (rating - (star - 1)) * 100));
                                            return (
                                                <div key={star} className="relative w-4 h-4">
                                                    <Star className="w-4 h-4 text-gray-400" />
                                                    <div className="absolute top-0 left-0 overflow-hidden" style={{ width: `${fillPercentage}%` }}>
                                                        <Star className="w-4 h-4 text-yellow-400 fill-current" />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        <span className="ml-2 text-sm text-gray-400">
                                            ({selectedBrand?.rating?.toFixed(1) || '0.0'})
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </DialogTitle>
                    </DialogHeader>
                    <div className="text-white mt-2 space-y-4">
                        <div className="flex items-center">
                            <span className="font-semibold mr-2">Country:</span>
                            {selectedBrand?.countries && selectedBrand.countries.length > 0 && (
                                <span>
                                    {selectedBrand.countries.map((country, index) => (
                                        <React.Fragment key={country.code}>
                                            <ReactCountryFlag countryCode={country.code} svg className="mr-1" />
                                            <span>{country.name}</span>
                                            {index < selectedBrand.countries.length - 1 && ', '}
                                        </React.Fragment>
                                    ))}
                                </span>
                            )}
                        </div>
                        <div>
                            <span className="font-semibold">Category: </span>
                            {selectedBrand?.categories && selectedBrand.categories.length > 0 && (
                                <span>
                                    {selectedBrand.categories.map((cat, index) => (
                                        <React.Fragment key={cat.category._id}>
                                            {cat.category.name}
                                            {cat.subCategory && ` (${cat.subCategory})`}
                                            {index < selectedBrand.categories.length - 1 && ', '}
                                        </React.Fragment>
                                    ))}
                                </span>
                            )}
                        </div>
                        <div>
                            <span className="font-semibold">Description: </span>
                            <p className="text-gray-300">{selectedBrand?.description}</p>
                        </div>
                        {selectedBrand?.socialMedia && selectedBrand.socialMedia.length > 0 && (
                            <div>
                                <span className="font-semibold">Social: </span>
                                <div className="flex space-x-4 mt-2">
                                    {selectedBrand.socialMedia.map(social => {
                                        const Icon = socialIcons[social.platform];
                                        return Icon ? (
                                            <Button
                                                key={social.platform}
                                                onClick={() => handleSocialMediaClick(selectedBrand._id, social.platform, social.url)}
                                                className="text-gray-400 hover:text-white transition-colors"
                                            >
                                                <Icon size={24} />
                                            </Button>
                                        ) : null;
                                    })}
                                </div>
                            </div>
                        )}
                        {selectedBrand?.website && (
                            <div className="mt-4">
                                <Button
                                    onClick={() => handleWebsiteClick(selectedBrand._id, selectedBrand.website)}
                                    className="w-full bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4 text-center font-bold transition-colors"
                                >
                                    Go to {selectedBrand.name}
                                </Button>
                            </div>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default BrandGrowthGame;