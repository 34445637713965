import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { Card, CardContent, CardFooter } from "./components/ui/card";
import { Button } from "./components/ui/button";
import { Avatar, AvatarImage, AvatarFallback } from "./components/ui/avatar";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./components/ui/tabs";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "./components/ui/dialog";
import { Home, BarChart2, Gift, Users, Share2, Copy, ChevronDown, ChevronUp, ChevronRight, Video, Star, X, ShoppingCart, Smartphone, Globe, Ticket } from "lucide-react";
import * as LucideIcons from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Input } from "./components/ui/input";
import './BrandBattle.css';
import ReactCountryFlag from "react-country-flag";
import BrandAvatar from './components/BrandAvatar';
import { toast } from 'react-hot-toast';
import AnimatedBackground from './components/AnimatedBackground';
import BrandGrowthGame from './components/BrandGrowthGame';
import UserProfile from './components/UserProfile';
import TicketDisplay from './components/TicketDisplay';
import BattleTimer from './components/BattleTimer';
import BoringAvatar from 'boring-avatars';
import OptimizedImage, { PreloadImages } from './components/ui/OptimizedImage';
import { SiDiscord, SiFacebook, SiInstagram, SiKakaotalk, SiLine, SiLinkedin, SiMedium, SiOdnoklassniki, SiOnlyfans, SiPatreon, SiPinterest, SiTencentqq, SiReddit, SiSnapchat, SiTelegram, SiThreads, SiTiktok, SiTumblr, SiTwitch, SiViber, SiVk, SiWechat, SiSinaweibo, SiWhatsapp, SiX, SiYoutube } from 'react-icons/si';

const BrandBattle = ({ user: initialUser, setUser: setGlobalUser }) => {
  console.log('BrandBattle component started', { initialUser });
  const [userData, setUserData] = useState(initialUser || {});
  const [stats, setStats] = useState({ battlesWon: initialUser?.stats?.battlesWon || 0, brandsFaced: [] });
  const [score, setScore] = useState(initialUser?.coins || 0);
  const [battlesWon, setBattlesWon] = useState(initialUser?.stats?.battlesWon || 0);
  const [availableBattles, setAvailableBattles] = useState(0);
  const [battleLimit, setBattleLimit] = useState(initialUser?.battleLimit || 50);
  const [remainingBattles, setRemainingBattles] = useState(initialUser?.availableBattles || 0);
  const [level, setLevel] = useState(initialUser?.level || 1);
  const [brandsLoaded, setBrandsLoaded] = useState(false);
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState({});
  const [ranks, setRanks] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('Global');
  const [countries, setCountries] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [referralLink, setReferralLink] = useState(userData.referralLink || '');
  const [currentRank, setCurrentRank] = useState(initialUser?.rank || 'Kettle');
  const [backgroundImage, setBackgroundImage] = useState(initialUser?.backgroundImage || '/default-background.jpg');
  const [selectedBrandIndex, setSelectedBrandIndex] = useState(null);
  const [openFaqId, setOpenFaqId] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedMainCategory, setSelectedMainCategory] = useState('');
  const [isCategoryDialogOpen, setIsCategoryDialogOpen] = useState(false);
  const [isCountryDialogOpen, setIsCountryDialogOpen] = useState(false);
  const [isReferralDialogOpen, setIsReferralDialogOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [showUpgradeAnimation, setShowUpgradeAnimation] = useState(false);
  const [levelProgress, setLevelProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [referrals, setReferrals] = useState([]);
  const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);
  const [selectedBrandInfo, setSelectedBrandInfo] = useState(null);
  const [touchStart, setTouchStart] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [translateY, setTranslateY] = useState(0);
  const [videoTasks, setVideoTasks] = useState([]);
  const [socialTasks, setSocialTasks] = useState([]);
  const [specialTasks, setSpecialTasks] = useState([]);
  const [taskCodes, setTaskCodes] = useState({});
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [leaderboardPeriod, setLeaderboardPeriod] = useState('week');
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [brandStats, setBrandStats] = useState({});
  const [timer, setTimer] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [showGo, setShowGo] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [selectedSubSubCategory, setSelectedSubSubCategory] = useState('');
  const [userLocation, setUserLocation] = useState(null);

  const getSocialIcon = (platform) => {
    const Icon = socialIcons[platform];
    return Icon ? <Icon className="w-6 h-6 text-white" /> : null;
  };

  const socialIcons = {
    Discord: SiDiscord,
    Facebook: SiFacebook,
    Instagram: SiInstagram,
    KakaoTalk: SiKakaotalk,
    Line: SiLine,
    LinkedIn: SiLinkedin,
    Medium: SiMedium,
    Ok: SiOdnoklassniki,
    Onlyfans: SiOnlyfans,
    Patreon: SiPatreon,
    Pinterest: SiPinterest,
    QQ: SiTencentqq,
    Reddit: SiReddit,
    Snapchat: SiSnapchat,
    Telegram: SiTelegram,
    Threads: SiThreads,
    TikTok: SiTiktok,
    Tumblr: SiTumblr,
    Twitch: SiTwitch,
    Viber: SiViber,
    Vk: SiVk,
    WeChat: SiWechat,
    Weibo: SiSinaweibo,
    WhatsApp: SiWhatsapp,
    X: SiX,
    YouTube: SiYoutube,
  };

  useEffect(() => {
    if (initialUser?.remainingBattles !== undefined) {
      setRemainingBattles(initialUser.remainingBattles);
    }
  }, [initialUser]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const telegramInitData = window.Telegram?.WebApp?.initData || localStorage.getItem('telegramInitData');
        if (!telegramInitData) {
          throw new Error('No Telegram init data found');
        }
        console.log('Telegram init data:', telegramInitData);
        localStorage.setItem('telegramInitData', telegramInitData);
        const parsedInitData = Object.fromEntries(new URLSearchParams(telegramInitData));
        const startParam = parsedInitData.start_param || window.Telegram?.WebApp?.initDataUnsafe?.start_param || new URLSearchParams(window.location.search).get('start');
        console.log('Start parameter:', startParam);
        const response = await axios.post('/api/users/auth', { telegramInitData, start: startParam });
        console.log('Auth response:', response.data);
        const { user, token } = response.data;
        localStorage.setItem('token', token);
        updateUserState(user);
        setAvailableBattles(user.availableBattles);
        if (user.referralLink) {
          console.log('Setting referral link:', user.referralLink);
          setReferralLink(user.referralLink);
        } else {
          console.error('User data does not contain referralLink');
        }
        // Добавляем установку ранга и фона
        setCurrentRank(user.rank);
        setBackgroundImage(user.backgroundImage);
      } catch (error) {
        console.error('Error fetching user data:', error);
        if (initialUser && initialUser.referralLink) {
          console.log('Using initial user referral link:', initialUser.referralLink);
          setReferralLink(initialUser.referralLink);
        } else {
          console.error('No referral link available');
        }
      } finally {
        setIsUserDataLoaded(true);
      }
    };
    fetchUserData();
  }, []);

  const getOrderedBrands = useCallback((allBrands, count = 2) => {
    const filteredBrands = allBrands.filter(brand => {
      const countryMatch = selectedCountry === 'Global'
        ? brand.global
        : (!selectedCountry || (brand.countries && brand.countries.some(c => c.code === selectedCountry)));
      const categoryMatch = !selectedMainCategory || brand.category === selectedMainCategory;
      const subCategoryMatch = !selectedSubCategory || brand.subCategory === selectedSubCategory;
      return countryMatch && categoryMatch && subCategoryMatch;
    });

    const shuffledBrands = filteredBrands.sort(() => 0.5 - Math.random());
    return shuffledBrands.slice(0, count);
  }, [selectedCountry, selectedMainCategory, selectedSubCategory]);

  // Функция для получения брендов с учетом выбранной страны и категории
  const fetchBrands = useCallback(async () => {
    try {
      const [brandsResponse, categoriesResponse] = await Promise.all([
        axios.get('/api/brands'),
        axios.get('/api/categories')
      ]);
      const brandsData = brandsResponse.data;
      console.log('Fetched brands data:', brandsData);

      if (brandsData && brandsData.brands && Array.isArray(brandsData.brands)) {
        setBrands(brandsData.brands);

        setCategories(categoriesResponse.data.reduce((acc, category) => {
          if (category && category._id) {
            acc[category._id] = {
              ...category,
              subCategories: Array.isArray(category.subCategories) ? category.subCategories : []
            };
          }
          return acc;
        }, {}));

        const availableCountries = ['Global', ...new Set(brandsData.brands.flatMap(brand =>
          brand.countries && Array.isArray(brand.countries) ? brand.countries.map(c => c.code) : []
        ).filter(Boolean))];

        setCountries(prevCountries =>
          Array.isArray(prevCountries)
            ? prevCountries.filter(country => availableCountries.includes(country.code))
            : []
        );

        return brandsData.brands;
      } else {
        console.warn('Unexpected brands data structure:', brandsData);
        setBrands([]);
        setCategories({});
        setCountries([]);
        return [];
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setBrands([]);
      setCategories({});
      setCountries([]);
      return [];
    }
  }, []);

  const getFilteredBrands = useCallback(() => {
    console.log('Starting brand filtering with:', {
      selectedCountry,
      selectedMainCategory,
      selectedSubCategory,
      selectedSubSubCategory,
      totalBrands: brands.length
    });

    if (!Array.isArray(brands)) {
      console.error('Brands is not an array:', brands);
      return [];
    }

    return brands.filter(brand => {
      try {
        console.log('Filtering brand:', brand.name);
        console.log('Brand categories:', brand.categories);

        // Проверка статуса бренда
        if (brand.status !== 'active') {
          console.log(`Brand ${brand.name} is not active`);
          return false;
        }

        if (!brand.categories || !Array.isArray(brand.categories)) {
          console.log(`Invalid categories for brand ${brand.name}`);
          return false;
        }

        // Проверка страны
        const countryMatch = selectedCountry === 'Global' ||
          brand.global ||
          (brand.countries && Array.isArray(brand.countries) &&
            brand.countries.some(c => c.code === selectedCountry));

        console.log(`Country match for ${brand.name}:`, countryMatch);

        // Проверка основной категории
        const categoryMatch = selectedMainCategory === '' || brand.categories.some(cat => {
          const categoryId = cat.category?._id || cat.category;
          const match = categoryId?.toString() === selectedMainCategory?.toString();
          console.log(`Category check for ${brand.name}:`, {
            brandCategoryId: categoryId?.toString(),
            selectedCategory: selectedMainCategory?.toString(),
            match
          });
          return match;
        });

        console.log(`Category match for ${brand.name}:`, categoryMatch);

        // Проверка подкатегории
        const subCategoryMatch = !selectedSubCategory || brand.categories.some(cat => {
          const match = cat.subCategory === selectedSubCategory;
          console.log(`Subcategory check for ${brand.name}:`, {
            brandSubCategory: cat.subCategory,
            selectedSubCategory,
            match
          });
          return match;
        });

        console.log(`Subcategory match for ${brand.name}:`, subCategoryMatch);

        // Проверка под-подкатегории
        const subSubCategoryMatch = !selectedSubSubCategory || brand.categories.some(cat => {
          const match = cat.subSubCategory === selectedSubSubCategory;
          console.log(`Sub-subcategory check for ${brand.name}:`, {
            brandSubSubCategory: cat.subSubCategory,
            selectedSubSubCategory,
            match
          });
          return match;
        });

        console.log(`Sub-subcategory match for ${brand.name}:`, subSubCategoryMatch);

        return countryMatch && categoryMatch && subCategoryMatch && subSubCategoryMatch;
      } catch (error) {
        console.error(`Error filtering brand ${brand.name}:`, error);
        return false;
      }
    });
  }, [brands, selectedCountry, selectedMainCategory, selectedSubCategory, selectedSubSubCategory]);

  const getTwoRandomBrands = useCallback((filteredBrands) => {
    const shuffled = [...filteredBrands].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 2);
  }, []);

  useEffect(() => {
    console.log('State changed:', {
      selectedMainCategory,
      selectedSubCategory,
      selectedSubSubCategory,
      brandsCount: brands.length,
      selectedBrandsCount: selectedBrands.length
    });
  }, [selectedMainCategory, selectedSubCategory, selectedSubSubCategory, brands.length, selectedBrands.length]);

  // Добавляем useEffect для отслеживания страны пользователя
  useEffect(() => {
    const getUserLocation = async () => {
      try {
        // Проверяем наличие страны в userData
        if (userData?.country) {
          console.log('Setting user location from userData:', userData.country);
          setUserLocation(userData.country);
          return;
        }

        // Проверяем наличие страны в localStorage
        const savedCountry = localStorage.getItem('userCountry');
        if (savedCountry) {
          console.log('Setting user location from localStorage:', savedCountry);
          setUserLocation(savedCountry);
          return;
        }

        // Если нет данных о стране, используем IP геолокацию через публичный API
        console.log('No country data, using geolocation API...');
        const response = await axios.get('https://ipapi.co/json/');
        const country = response.data.country_code;
        console.log('Got location from geolocation API:', country);

        // Сохраняем страну в localStorage
        localStorage.setItem('userCountry', country);
        setUserLocation(country);

      } catch (error) {
        console.error('Error getting user location:', error);
        // В случае ошибки используем Global
        setUserLocation('Global');
        localStorage.setItem('userCountry', 'Global');
      }
    };

    getUserLocation();
  }, [userData]);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await axios.get('/api/brands');
        const allBrands = response.data.brands || [];
        console.log('Loaded brands:', allBrands);

        // Фильтруем только активные бренды
        const filteredBrands = allBrands.filter(brand => brand.status === 'active');

        console.log('Filtered active brands:', filteredBrands);
        setBrands(prevBrands => {
          // Объединяем существующие бренды с новыми активными брендами
          const updatedBrands = [...prevBrands];
          filteredBrands.forEach(newBrand => {
            if (!updatedBrands.some(existingBrand => existingBrand._id === newBrand._id)) {
              updatedBrands.push(newBrand);
            }
          });
          return updatedBrands;
        });

        if (selectedBrands.length === 0) {
          setSelectedBrands(getTwoRandomBrands(filteredBrands));
        }
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    };

    if (brands.length === 0) {
      fetchBrands();
    }
  }, [brands.length, selectedBrands.length, getTwoRandomBrands]);

  const startTimer = useCallback(() => {
    setTimer(5);
    setIsTimerRunning(true);
    setShowGo(false);
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(interval);
          setShowGo(true);
          setTimeout(() => {
            setIsTimerRunning(false);
            setShowGo(false);
          }, 1000);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
  }, []);

  const updateSelectedBrands = useCallback(() => {
    console.log('Updating selected brands');
    if (brands.length > 0) {
      const filteredBrands = getFilteredBrands();
      console.log('Filtered brands:', filteredBrands);
      if (filteredBrands.length >= 2) {
        const newBrands = getTwoRandomBrands(filteredBrands);
        console.log('New selected brands:', newBrands);
        setSelectedBrands(newBrands);
      } else {
        toast.info("No brands match the current filters. Please try different filters.", {
          duration: 3000,
          position: 'top-center'
        });
      }
    } else {
      console.log('No brands available');
      // Уведомление если нет доступных брендов
      toast.info("No brands available. Please try again later.", {
        duration: 3000,
        position: 'top-center'
      });
    }
  }, [brands, getFilteredBrands, getTwoRandomBrands]);

  // Добавляем useEffect для отладки выбранной категории
  useEffect(() => {
    console.log('MainCategory changed to:', selectedMainCategory);
    if (selectedMainCategory === '0') {
      setSelectedMainCategory('');
    } else if (selectedMainCategory) {
      setIsCategoryDialogOpen(true);
    }
  }, [selectedMainCategory]);

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await axios.get('/api/tickets');
        setTickets(response.data);
      } catch (error) {
        console.error('Error fetching tickets:', error);
      }
    };

    fetchTickets();
  }, []);

  useEffect(() => {
    console.log('Effect triggered to update brands');
    if (brands.length > 0) {
      updateSelectedBrands();
    }
  }, [brands, selectedCountry, selectedMainCategory, selectedSubCategory, updateSelectedBrands]);

  const updateUserDataCallback = useCallback(async (updatedData) => {
    try {
      const response = await axios.put('/api/users/me', updatedData, {
        headers: {
          'X-Telegram-Init-Data': localStorage.getItem('telegramInitData')
        }
      });
      setGlobalUser(response.data);
      setUserData(prevData => ({ ...prevData, ...response.data }));
      setScore(response.data.coins);
      setBattlesWon(response.data.battles);
      setRemainingBattles(response.data.availableBattles);
      setLevel(response.data.level);
    } catch (error) {
      console.error('Error updating user data:', error);
      toast.error("Failed to update user data. Please try again.", {
        duration: 3000,
        position: 'top-center',
      });
    }
  }, [setGlobalUser]);

  useEffect(() => {
    const loadBrands = async () => {
      try {
        const fetchedBrands = await fetchBrands();
        console.log('Loaded brands:', fetchedBrands);

        // Фильтруем только активные бренды
        const filteredBrands = fetchedBrands.filter(brand => brand.status === 'active');

        console.log('Filtered active brands:', filteredBrands);
        setBrands(filteredBrands);

        if (selectedBrands.length === 0) {
          setSelectedBrands(getTwoRandomBrands(filteredBrands));
        }
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    };

    if (brands.length === 0) {
      loadBrands();
    }
  }, [fetchBrands, brands.length, selectedBrands.length, getTwoRandomBrands]);

  const handleAuth = async (authData) => {
    try {
      console.log('Attempting authentication with data:', authData);
      const response = await axios.post('/api/users/auth', { telegramInitData: authData }, {
        headers: { 'Content-Type': 'application/json' }
      });
      const { user, token } = response.data;
      localStorage.setItem('token', token);
      localStorage.setItem('telegramInitData', authData);
      updateUserData(user);
      setGlobalUser(user);
      console.log('Authentication successful, token saved:', token);
      return token;
    } catch (error) {
      console.error('Authentication error:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
      throw error;
    }
  };

  const refreshUserData = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        const response = await axios.get('/api/users/me', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        const updatedUser = response.data;
        setUserData(updatedUser);
        setGlobalUser(updatedUser);
        updateUserData({
          coins: updatedUser.coins,
          battles: updatedUser.battles,
          battleLimit: updatedUser.totalBattles - updatedUser.currentBattles,
          level: updatedUser.level
        });
      }
    } catch (error) {
      console.error('Error refreshing user data:', error);
    }
  }, [setGlobalUser]);

  const updateUserState = (user) => {
    console.log('Updating user state with:', user);
    if (user && Object.keys(user).length > 0) {
      setUserData(user);
      setScore(user.coins || 0);
      setBattlesWon(user.battles || 0);
      setStats({ battlesWon: user.battles || 0, brandsFaced: user.stats?.brandsFaced || [] });
      setRemainingBattles(user.availableBattles || 0);
      setBattleLimit(user.battleLimit || 50);
      setLevel(user.level || 1);
      setGlobalUser(user);
      console.log('Updated user state:', {
        coins: user.coins,
        battles: user.battles,
        availableBattles: user.availableBattles,
        battleLimit: user.battleLimit,
        level: user.level
      });
    } else {
      console.warn('Attempted to update user state with invalid data:', user);
    }
  };

  useEffect(() => {
    if (!isUserDataLoaded) {
      return;
    }

    const initAuth = async () => {
      const telegramInitData = window.Telegram?.WebApp?.initData || localStorage.getItem('telegramInitData');
      if (telegramInitData) {
        try {
          console.log('Initializing auth with telegramInitData');
          const response = await axios.post('/api/users/auth', { telegramInitData });
          console.log('Auth response:', response.data);
          const { user, token } = response.data;
          localStorage.setItem('token', token);
          localStorage.setItem('telegramInitData', telegramInitData);
          setUserData(prevData => ({
            ...prevData,
            ...user,
            avatar: user.avatar || null,
            defaultAvatar: null
          }));
        } catch (error) {
          console.error('Error during authentication:', error);
        }
      } else {
        console.warn('No telegramInitData available');
      }
    };

    initAuth();
  }, [isUserDataLoaded]);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        // Получаем страну и язык пользователя из userData
        const userCountry = userData?.country || null;
        const userLanguage = userData?.languageCode || null;

        console.log('Fetching tasks for user:', {
          country: userCountry,
          language: userLanguage
        });

        const [videoResponse, socialResponse, specialResponse] = await Promise.all([
          axios.get('/api/video-tasks', {
            params: { userCountry, userLanguage }
          }),
          axios.get('/api/social-tasks', {
            params: { userCountry, userLanguage }
          }),
          axios.get('/api/special-tasks', {
            params: { userCountry, userLanguage }
          })
        ]);

        // Фильтруем задания на клиентской стороне
        const filterTasksByTargeting = (tasks) => {
          return tasks.filter(task => {
            if (task.targeting === 'all') return true;

            if (task.targeting === 'specific') {
              const countryMatch = !task.allowedCountries.length ||
                task.allowedCountries.includes(userCountry);
              const languageMatch = !task.allowedLanguages.length ||
                task.allowedLanguages.includes(userLanguage);
              return countryMatch && languageMatch;
            }

            return false;
          });
        };

        const filteredVideoTasks = filterTasksByTargeting(videoResponse.data);
        const filteredSocialTasks = filterTasksByTargeting(socialResponse.data);
        const filteredSpecialTasks = filterTasksByTargeting(specialResponse.data);

        console.log('Filtered tasks:', {
          video: filteredVideoTasks.length,
          social: filteredSocialTasks.length,
          special: filteredSpecialTasks.length
        });

        const updatedVideoTasks = filteredVideoTasks.map(task => ({
          ...task,
          status: userData.completedVideoTasks?.includes(task._id) ? 'completed' : task.status || 'not_started'
        }));
        const updatedSocialTasks = filteredSocialTasks.map(task => ({
          ...task,
          status: userData.completedSocialTasks?.includes(task._id) ? 'completed' : task.status || 'not_started'
        }));
        const updatedSpecialTasks = filteredSpecialTasks.map(task => ({
          ...task,
          status: userData.completedSpecialTasks?.includes(task._id) ? 'completed' : task.status || 'not_started'
        }));

        setVideoTasks(updatedVideoTasks);
        setSocialTasks(updatedSocialTasks);
        setSpecialTasks(updatedSpecialTasks);
      } catch (error) {
        console.error('Error fetching tasks:', error);
        toast.error("Failed to load tasks", {
          duration: 3000,
          position: 'top-center',
        });
      }
    };

    if (userData) {
      fetchTasks();
    }
  }, [userData]);

  useEffect(() => {
    const handleUserUpdate = (event) => {
      const { userId, avatar, defaultAvatar } = event.detail;
      if (userId && userId === userData._id) {
        setUserData(prevData => ({
          ...prevData,
          avatar,
          defaultAvatar
        }));
      }
    };

    window.addEventListener('userUpdate', handleUserUpdate);

    return () => {
      window.removeEventListener('userUpdate', handleUserUpdate);
    };
  }, [userData?._id]);

  const handleStartVideoTask = async (taskId) => {
    try {
      const response = await axios.post(`/api/video-tasks/${taskId}/start`);
      setVideoTasks(prevTasks =>
        prevTasks.map(task =>
          task._id === taskId ? { ...task, status: 'in_progress' } : task
        )
      );
      setTimeout(() => {
        setVideoTasks(prevTasks =>
          prevTasks.map(task =>
            task._id === taskId ? { ...task, status: 'ready_to_claim' } : task
          )
        );
      }, response.data.timerDuration * 1000);
    } catch (error) {
      console.error('Error starting video task:', error);
      toast.error("Failed to start video task. Please try again.", {
        duration: 3000,
        position: 'top-center',
      });
    }
  };

  const handleCodeInput = (taskId, code) => {
    setTaskCodes(prevCodes => ({ ...prevCodes, [taskId]: code }));
    setVideoTasks(prevTasks =>
      prevTasks.map(task =>
        task._id === taskId ? { ...task, codeError: false } : task
      )
    );
  };

  const handleClaimVideoTask = async (taskId) => {
    try {
      const code = taskCodes[taskId];
      const task = videoTasks.find(t => t._id === taskId);
      if (!task) {
        console.error('Task not found');
        toast.error("Task not found. Please try again.", {
          duration: 3000,
          position: 'top-center',
        });
        return;
      }
      if (!code) {
        toast.error("Please enter a code.", {
          duration: 3000,
          position: 'top-center',
        });
        return;
      }
      const token = localStorage.getItem('token');
      const telegramInitData = localStorage.getItem('telegramInitData');
      if (!token || !telegramInitData) {
        toast.error("Authentication error. Please refresh the page and try again.", {
          duration: 3000,
          position: 'top-center',
        });
        return;
      }
      console.log('Sending claim request with:', { taskId, code, token: token.substring(0, 10) + '...' });
      const response = await axios.post(`/api/video-tasks/${taskId}/claim`,
        { code },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-Telegram-Init-Data': telegramInitData
          }
        }
      );
      console.log('Claim response:', response.data);
      if (response.data.success) {
        setVideoTasks(prevTasks =>
          prevTasks.map(t =>
            t._id === taskId ? { ...t, status: 'completed', codeError: false } : t
          )
        );
        const updatedUserData = {
          ...userData,
          coins: response.data.updatedUser.coins,
          completedVideoTasks: response.data.updatedUser.completedVideoTasks
        };
        setUserData(updatedUserData);
        setGlobalUser(updatedUserData);
        setScore(updatedUserData.coins);
        toast.success(`Task completed successfully! You earned ${task.coinsReward} coins.`, {
          duration: 3000,
          position: 'top-center',
        });
      } else {
        console.error('Claim failed:', response.data);
        switch (response.data.errorType) {
          case 'INVALID_CODE':
            setVideoTasks(prevTasks =>
              prevTasks.map(t =>
                t._id === taskId ? { ...t, codeError: true } : t
              )
            );
            toast.error("Invalid code. Please try again.", {
              duration: 3000,
              position: 'top-center',
            });
            break;
          case 'TASK_COMPLETED':
            toast.info("You have already completed this task.", {
              duration: 3000,
              position: 'top-center',
            });
            setVideoTasks(prevTasks =>
              prevTasks.map(t =>
                t._id === taskId ? { ...t, status: 'completed' } : t
              )
            );
            break;
          case 'TASK_EXPIRED':
            toast.error("This task has expired.", {
              duration: 3000,
              position: 'top-center',
            });
            setVideoTasks(prevTasks =>
              prevTasks.map(t =>
                t._id === taskId ? { ...t, status: 'expired' } : t
              )
            );
            break;
          default:
            toast.error(response.data.message || "Failed to claim task. Please try again.", {
              duration: 3000,
              position: 'top-center',
            });
        }
      }
    } catch (error) {
      console.error('Error claiming video task:', error);
      if (error.response && error.response.data) {
        console.error('Error response:', error.response.data);
        toast.error(error.response.data.message || "Failed to claim task. Please try again.", {
          duration: 3000,
          position: 'top-center',
        });
      } else {
        toast.error("Network error. Please check your connection and try again.", {
          duration: 3000,
          position: 'top-center',
        });
      }
    }
  };

  const handleWatchVideo = async (taskId, videoUrl) => {
    try {
      const task = videoTasks.find(t => t._id === taskId);
      if (!task) {
        console.error('Task not found');
        return;
      }

      const token = localStorage.getItem('token');
      const telegramInitData = localStorage.getItem('telegramInitData');

      if (!token || !telegramInitData) {
        toast.error("Authentication error. Please refresh the page and try again.", {
          duration: 3000,
          position: 'top-center',
        });
        return;
      }

      // Отправляем запрос на сервер для регистрации клика
      const response = await axios.post(`/api/video-tasks/${taskId}/click`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-Telegram-Init-Data': telegramInitData
        }
      });

      if (response.data.success) {
        // Открываем видео
        window.open(videoUrl, '_blank');

        setVideoTasks(prevTasks =>
          prevTasks.map(t =>
            t._id === taskId ? { ...t, status: 'in_progress' } : t
          )
        );

        toast.success("Video opened. Watching in progress...", {
          duration: 3000,
          position: 'top-center',
        });

        // Используем таймер из задания
        const timerDuration = task.timerDuration * 1000;
        setTimeout(() => {
          setVideoTasks(prevTasks =>
            prevTasks.map(t =>
              t._id === taskId ? { ...t, status: 'ready_to_claim' } : t
            )
          );
          toast.success("Video watched. You can now enter the code.", { duration: 3000, position: 'top-center' });
        }, timerDuration);
      } else {
        toast.info("You've already clicked this video.", { duration: 3000, position: 'top-center' });
      }
    } catch (error) {
      console.error('Error registering video click:', error);
      if (error.response && error.response.data) {
        toast.error(error.response.data.message || "Failed to register video click. Please try again.", { duration: 3000, position: 'top-center' });
      } else {
        toast.error("Failed to register video click. Please try again.", { duration: 3000, position: 'top-center' });
      }
    }
  };

  const handleSubscribe = async (taskId) => {
    try {
      const task = socialTasks.find(t => t._id === taskId);
      if (!task) {
        throw new Error('Task not found');
      }

      // Отправляем запрос на сервер для регистрации клика
      const response = await axios.post(`/api/social-tasks/${taskId}/click`, {}, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'X-Telegram-Init-Data': localStorage.getItem('telegramInitData')
        }
      });

      if (response.data.success) {
        // Открываем ссылку на канал в новом окне
        window.open(task.socialUrl, '_blank');

        // Меняем статус задачи на 'subscribed', чтобы показать кнопку Claim
        setSocialTasks(prevTasks =>
          prevTasks.map(t =>
            t._id === taskId ? { ...t, status: 'subscribed' } : t
          )
        );
      } else {
        toast.info("No brands in this subcategory.", { duration: 3000, position: 'top-center' });
      }
    } catch (error) {
      console.error('Error subscribing to social task:', error);
      toast.error("Authentication error. Please refresh the page and try again.", { duration: 3000, position: 'top-center' });
    }
  };

  const handleWebsiteClick = async (brandId, websiteUrl) => {
    try {
      await axios.post(`/api/brands/${brandId}/click/website`);
      window.open(websiteUrl, '_blank');
    } catch (error) {
      console.error('Error registering website click:', error);
      toast.error("Failed to register click", {
        duration: 3000,
        position: 'top-center',
      });
    }
  };

  const handleSocialMediaClick = async (brandId, platform, socialUrl) => {
    try {
      await axios.post(`/api/brands/${brandId}/click/social`, { platform });
      window.open(socialUrl, '_blank');
    } catch (error) {
      console.error('Error registering social media click:', error);
      toast.error("Failed to register click", {
        duration: 3000,
        position: 'top-center',
      });
    }
  };

  const handleClaimSocialTask = async (taskId) => {
    try {
      const task = socialTasks.find(t => t._id === taskId);
      if (!task) {
        throw new Error('Task not found');
      }

      // Проверяем подписку через бота
      const checkSubscriptionResponse = await axios.post('/api/social-tasks/check-subscription', {
        userId: userData.telegramId,
        channelUsername: new URL(task.socialUrl).pathname.slice(1) // Извлекаем username из URL
      });

      if (checkSubscriptionResponse.data.isSubscribed) {
        const response = await axios.post(`/api/social-tasks/${taskId}/claim`);
        if (response.data.success) {
          setSocialTasks(prevTasks =>
            prevTasks.map(t =>
              t._id === taskId ? { ...t, status: 'completed' } : t
            )
          );
          const updatedUserData = {
            ...userData,
            coins: response.data.updatedUser.coins,
            completedSocialTasks: response.data.updatedUser.completedSocialTasks
          };
          setUserData(updatedUserData);
          setGlobalUser(updatedUserData);
          setScore(updatedUserData.coins);
          toast.success(`Task completed successfully! You earned ${task.coinsReward} coins.`, {
            duration: 3000,
            position: 'top-center',
          });
        } else {
          toast.error(response.data.message || "Failed to claim task. Please try again.", {
            duration: 3000,
            position: 'top-center',
          });
        }
      } else {
        toast.error("You are not subscribed to the channel. Please subscribe and try again.", {
          duration: 3000,
          position: 'top-center',
        });
      }
    } catch (error) {
      console.error('Error claiming social task:', error);
      toast.error(error.response?.data?.message || "Failed to claim task. Please try again.", {
        duration: 3000,
        position: 'top-center',
      });
    }
  };

  const handleSpecialTask = async (taskId) => {
    try {
      const task = specialTasks.find(t => t._id === taskId);
      if (!task) {
        throw new Error('Task not found');
      }

      const response = await axios.post(`/api/special-tasks/${taskId}/click`, {}, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'X-Telegram-Init-Data': localStorage.getItem('telegramInitData')
        }
      });

      if (response.data.success) {
        window.open(task.specialUrl, '_blank');
        setSpecialTasks(prevTasks =>
          prevTasks.map(t =>
            t._id === taskId ? { ...t, status: 'in_progress' } : t
          )
        );
        toast.success("Task started successfully!", {
          duration: 3000,
          position: 'top-center',
        });
      } else {
        toast.error(response.data.message || "Failed to start special task.", {
          duration: 3000,
          position: 'top-center',
        });
      }
    } catch (error) {
      console.error('Error handling special task:', error);
      toast.error("Failed to start special task. Please try again.", {
        duration: 3000,
        position: 'top-center',
      });
    }
  };

  const handleClaimSpecialTask = async (taskId) => {
    try {
      const task = specialTasks.find(t => t._id === taskId);
      if (!task) {
        throw new Error('Task not found');
      }

      let requestData = {};
      if (task.taskType === 'web') {
        const code = taskCodes[taskId];
        if (!code) {
          toast.error("Please enter a code.", {
            duration: 3000,
            position: 'top-center',
          });
          return;
        }
        requestData = { code };
      }

      const response = await axios.post(`/api/special-tasks/${taskId}/claim`,
        requestData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'X-Telegram-Init-Data': localStorage.getItem('telegramInitData')
          }
        }
      );

      if (response.data.success) {
        const updatedUserData = {
          ...userData,
          coins: response.data.updatedUser.coins,
          completedSpecialTasks: response.data.updatedUser.completedSpecialTasks
        };
        setUserData(updatedUserData);
        setGlobalUser(updatedUserData);
        setScore(updatedUserData.coins);

        const updatedTasksResponse = await axios.get('/api/special-tasks', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'X-Telegram-Init-Data': localStorage.getItem('telegramInitData')
          }
        });

        const updatedSpecialTasks = updatedTasksResponse.data.map(task => ({
          ...task,
          status: updatedUserData.completedSpecialTasks.includes(task._id) ? 'completed' : task.status
        }));
        setSpecialTasks(updatedSpecialTasks);

        toast.success(`Task completed successfully! You earned ${task.coinsReward} coins.`, {
          duration: 3000,
          position: 'top-center',
        });
      } else {
        if (task.taskType === 'web') {
          setSpecialTasks(prevTasks =>
            prevTasks.map(t =>
              t._id === taskId ? { ...t, codeError: true } : t
            )
          );
          toast.error("Invalid code. Please try again.", {
            duration: 3000,
            position: 'top-center',
          });
        } else {
          toast.error("Failed to claim task. Please try again.", {
            duration: 3000,
            position: 'top-center',
          });
        }
      }
    } catch (error) {
      console.error('Error claiming special task:', error);
      toast.error(error.response?.data?.message || "Failed to claim task. Please try again.", {
        duration: 3000,
        position: 'top-center',
      });
    }
  };

  const handleOtherSocialPlatforms = async (taskId) => {
    try {
      const task = socialTasks.find(t => t._id === taskId);
      if (!task) {
        throw new Error('Task not found');
      }
      window.open(task.socialUrl, '_blank');
      setSocialTasks(prevTasks =>
        prevTasks.map(t =>
          t._id === taskId ? { ...t, status: 'ready_to_claim' } : t
        )
      );
    } catch (error) {
      console.error('Error opening social link:', error);
      toast.error("Failed to open social link. Please try again.", { duration: 3000, position: 'top-center' });
    }
  };

  const handleClaimOtherSocialTask = async (taskId) => {
    try {
      const task = socialTasks.find(t => t._id === taskId);
      if (!task) {
        throw new Error('Task not found');
      }
      const response = await axios.post(`/api/social-tasks/${taskId}/claim`);
      if (response.data.success) {
        setSocialTasks(prevTasks =>
          prevTasks.map(t =>
            t._id === taskId ? { ...t, status: 'completed' } : t
          )
        );
        setUserData(prevUserData => ({
          ...prevUserData,
          coins: response.data.updatedUser.coins,
          completedSocialTasks: response.data.updatedUser.completedSocialTasks
        }));
        setScore(response.data.updatedUser.coins);
        toast.success("Video opened. Watching in progress...", { duration: 3000, position: 'top-center' });
      } else {
        toast.error(response.data.message || "Failed to claim task. Please try again.", { duration: 3000, position: 'top-center' });
      }
    } catch (error) {
      console.error('Error claiming social task:', error);
      toast.error(error.response?.data?.message || "Failed to claim task. Please try again.", { duration: 3000, position: 'top-center' });
    }
  };

  const getOrderedBrandsFunc = useCallback((allBrands, count = 2, country, category, subCategory) => {
    if (!Array.isArray(allBrands) || allBrands.length === 0) {
      return [];
    }
    let filteredBrands = allBrands;
    if (country && country !== 'Global') {
      filteredBrands = filteredBrands.filter(brand =>
        brand.countries && Array.isArray(brand.countries) && brand.countries.some(c => c.code === country)
      );
    }
    if (category) {
      filteredBrands = filteredBrands.filter(brand => brand.category === category);
      if (subCategory) {
        filteredBrands = filteredBrands.filter(brand => brand.subCategory === subCategory);
      }
    }
    if (filteredBrands.length < count) {
      const otherBrands = allBrands.filter(brand => !filteredBrands.includes(brand));
      filteredBrands = [...filteredBrands, ...otherBrands];
    }
    const sortedBrands = filteredBrands.sort((a, b) => {
      if (country && country !== 'Global') {
        const aInCountry = a.countries && Array.isArray(a.countries) && a.countries.some(c => c.code === country);
        const bInCountry = b.countries && Array.isArray(b.countries) && b.countries.some(c => c.code === country);
        if (aInCountry && !bInCountry) return -1;
        if (!aInCountry && bInCountry) return 1;
      }
      return (b.frequency || 0) - (a.frequency || 0);
    });
    return sortedBrands.slice(0, count);
  }, []);

  // Функция для обновления данных пользователя на сервере
  const updateUserData = useCallback(async (updatedData) => {
    try {
      const response = await axios.put('/api/users/me', updatedData, {
        headers: {
          'X-Telegram-Init-Data': localStorage.getItem('telegramInitData')
        }
      });
      setUserData(response.data);
      setGlobalUser(response.data);
    } catch (error) {
      console.error('Error updating user data:', error);
    }
  }, [setGlobalUser]);

  // Эффект для загрузки начальных данных
  const fetchInitialData = async () => {
    setIsLoading(true);
    try {
      const [categoriesRes, ranksRes, tasksRes, faqsRes, countriesRes, referralsRes, brandsRes] = await Promise.all([
        axios.get('/api/categories'),
        axios.get('/api/ranks'),
        axios.get('/api/tasks'),
        axios.get('/api/faq'),
        axios.get('/api/countries'),
        axios.get('/api/referrals'),
        axios.get('/api/brands')
      ]);

      setCategories(categoriesRes.data);
      setRanks(ranksRes.data);
      setTasks(tasksRes.data);
      setFaqs(faqsRes.data);
      setCountries(countriesRes.data);
      setReferrals(referralsRes.data);
      if (brandsRes.data && Array.isArray(brandsRes.data.brands)) {
        setBrands(brandsRes.data.brands);
        const initialBrands = getOrderedBrands(brandsRes.data.brands, 2);
        setSelectedBrands(initialBrands);
      } else {
        console.error('Unexpected brands data structure:', brandsRes.data);
        setBrands([]);
        setSelectedBrands([]);
      }
    } catch (error) {
      console.error('Error fetching initial data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const toggleFaq = (id) => {
    setOpenFaqId(openFaqId === id ? null : id);
  };

  const renderCategoryButton = (category, isSubCategory = false) => (
    <Button
      key={category}
      onClick={() => handleCategorySelect(category, isSubCategory)}
      className={`justify-start w-full mb-2 rounded-[30px] ${(isSubCategory ? selectedSubCategory : selectedCategory) === category
        ? 'bg-[#e0ff89] text-black'
        : 'bg-[#1a1a1a] text-white hover:text-[#e0ff89]'
        } ${isSubCategory ? 'text-sm' : ''}`}
    >
      {category}
    </Button>
  );

  const handleCategorySelect = useCallback((category) => {
    console.log('Selecting category:', category);
    const categoryId = category._id; // Получаем ObjectId категории

    setSelectedMainCategory(prev => {
      const newValue = prev === categoryId ? '' : categoryId;
      console.log('New selectedMainCategory:', newValue);
      return newValue;
    });

    setSelectedSubCategory('');
    setSelectedSubSubCategory('');

    const filteredBrands = getFilteredBrands();
    console.log('Filtered brands after category select:', filteredBrands);

    if (filteredBrands.length >= 2) {
      const newBrands = getTwoRandomBrands(filteredBrands);
      setSelectedBrands(newBrands);
      console.log('New selected brands:', newBrands);
    } else {
      setSelectedBrands([]);
      console.log('No brands match filters');
      toast.info("No brands available in this category.", {
        duration: 3000,
        position: 'top-center'
      });
    }

    if (categoryId) {
      setIsCategoryDialogOpen(true);
    }
  }, [getFilteredBrands, getTwoRandomBrands]);

  const handleSubCategorySelect = useCallback((subCategory) => {
    console.log('Selecting subcategory:', subCategory);
    setSelectedSubCategory(subCategory.name);
    setSelectedSubSubCategory('');

    // Проверяем наличие под-подкатегорий
    if (!subCategory.subSubCategories || subCategory.subSubCategories.length === 0) {
      // Если нет под-подкатегорий, закрываем диалог и фильтруем бренды
      const filteredBrands = getFilteredBrands().filter(brand =>
        brand.categories.some(cat =>
          cat.subCategory === subCategory.name
        )
      );

      if (filteredBrands.length >= 2) {
        const newBrands = getTwoRandomBrands(filteredBrands);
        setSelectedBrands(newBrands);
        setIsCategoryDialogOpen(false);
      } else {
        toast.info("'No brands available in this subcategory.", {
          duration: 3000,
          position: 'top-center'
        });


        // Попробуем взять бренды из основной категории
        const mainCategoryBrands = getFilteredBrands();
        if (mainCategoryBrands.length >= 2) {
          setSelectedBrands(getTwoRandomBrands(mainCategoryBrands));
        }
      }
    }
  }, [getFilteredBrands, getTwoRandomBrands]);

  const handleSubSubCategorySelect = useCallback((subSubCategory) => {
    console.log('Selecting sub-subcategory:', subSubCategory);
    setSelectedSubSubCategory(subSubCategory.name);
    setIsCategoryDialogOpen(false);

    // Используем getFilteredBrands напрямую
    const filteredBrands = getFilteredBrands();

    console.log('Filtered brands after sub-subcategory select:', filteredBrands);

    if (filteredBrands.length >= 2) {
      // Если у нас достаточно брендов, выбираем случайные два
      const newBrands = getTwoRandomBrands(filteredBrands);
      setSelectedBrands(newBrands);
    } else if (filteredBrands.length === 1) {
      // Если найден только один бренд
      const singleBrand = filteredBrands[0];

      // Ищем другой бренд из той же категории и подкатегории
      const otherBrands = brands.filter(b =>
        b._id !== singleBrand._id &&
        b.categories.some(cat =>
          cat.category._id === selectedMainCategory &&
          cat.subCategory === selectedSubCategory
        )
      );

      if (otherBrands.length > 0) {
        const randomBrand = otherBrands[Math.floor(Math.random() * otherBrands.length)];
        setSelectedBrands([singleBrand, randomBrand]);
      } else {
        // Если других брендов нет, используем только один
        setSelectedBrands([singleBrand]);
      }
    }

    // Показываем сообщение только если действительно нет брендов
    if (filteredBrands.length === 0) {
      toast.info("No brands available in this sub-subcategory.", {
        duration: 3000,
        position: 'top-center'
      });
      // Попробуем взять бренды из подкатегории
      const subCategoryBrands = brands.filter(brand =>
        brand.categories.some(cat =>
          cat.category._id === selectedMainCategory &&
          cat.subCategory === selectedSubCategory
        )
      );
      if (subCategoryBrands.length >= 2) {
        setSelectedBrands(getTwoRandomBrands(subCategoryBrands));
      }
    }
  }, [getFilteredBrands, getTwoRandomBrands, selectedMainCategory, selectedSubCategory, brands]);

  useEffect(() => {
    if (selectedMainCategory) {
      setIsCategoryDialogOpen(true);
    }
  }, [selectedMainCategory]);

  const closeDialog = () => {
    setIsCategoryDialogOpen(false);
  };

  const toggleCategory = (categoryId) => {
    setSelectedMainCategory(prevCategory =>
      prevCategory === categoryId ? '' : categoryId
    );
  };

  const handleMenuClick = (menu) => {
    setActiveMenu(prevMenu => {
      if (prevMenu === menu) {
        setTranslateY(0);
        return null;
      } else {
        setTranslateY(0);
        return menu;
      }
    });
  };

  const handleTouchStart = (e) => {
    if (e.target.closest('.drag-handle')) {
      setIsDragging(true);
      setTouchStart(e.targetTouches[0].clientY);
    }
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const currentTouch = e.targetTouches[0].clientY;
    const diff = currentTouch - touchStart;
    if (diff > 0) {
      setTranslateY(diff);
    }
  };

  const handleTouchEnd = () => {
    if (!isDragging) return;
    if (translateY > 100) {
      handleMenuClick(null);
    }
    setTranslateY(0);
    setIsDragging(false);
  };


  const closeMenu = () => {
    setActiveMenu(null);
  };

  const vibrate = () => {
    if (navigator.vibrate) {
      navigator.vibrate(50);
    }
  };

  const handleWithVibration = (handler) => {
    return (...args) => {
      vibrate();
      handler(...args);
    };
  };

  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase();
  };

  const canActivateRank = (rank) => {
    return score >= rank.requiredCoins &&
      battlesWon >= rank.battlesRequired &&
      level >= rank.level &&
      (!currentRank || rank.level === (userData.level)) &&
      rank.name !== userData.rank;
  };

  const availableCountries = useMemo(() => {
    const countryCodes = new Set(brands.flatMap(brand =>
      brand.countries && Array.isArray(brand.countries) ? brand.countries.map(c => c.code) : []
    ));
    countryCodes.add('Global'); // Добавляем 'Global' в любом случае
    return countries.filter(country => countryCodes.has(country.code));
  }, [brands, countries]);

  const handleCountryChange = useCallback((newCountry) => {
    console.log('Changing country to:', newCountry);
    setSelectedCountry(newCountry);

    // Обновляем бренды после изменения страны
    setTimeout(() => {
      const filteredBrands = getFilteredBrands();
      console.log('Filtered brands after country change:', filteredBrands);
      console.log('Filtered brands details:', filteredBrands.map(b => ({
        name: b.name,
        countries: b.countries,
        global: b.global
      })));
      if (filteredBrands.length > 0) {
        const newBrands = getTwoRandomBrands(filteredBrands);
        setSelectedBrands(newBrands);
      } else {
        toast.info("No brands in this country.", {
          duration: 3000,
          position: 'top-center',
        });
        setSelectedBrands([]); // Очищаем выбранные бренды, если нет подходящих
      }
      setIsCountryDialogOpen(false);
    }, 0);
  }, [getFilteredBrands, getTwoRandomBrands]);

  const UpgradeAnimation = () => (
    <div className="absolute inset-0 flex items-center justify-center overflow-hidden z-[999]">
      <div className="absolute inset-0 bg-black bg-opacity-90"></div>

      <div className="matrix-background">
        {[...Array(200)].map((_, i) => (
          <div
            key={i}
            className="matrix-symbol"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              animationDelay: `${Math.random() * 5}s`
            }}
          >
            {String.fromCharCode(0x30A0 + Math.random() * 96)}
          </div>
        ))}
      </div>

      <div className="hologram-container">
        <div className="data-stream top"></div>
        <div className="data-stream bottom"></div>

        <div className="rank-display">
          <div className="rank-hexagon">
            <div className="rank-hexagon-inner"></div>
            <div className="rank-hexagon-scan"></div>
          </div>
          <div className="rank-info">
            <div className="rank-title">NEW RANK</div>
            <div className="rank-details">ACHIEVED</div>
          </div>
        </div>
      </div>

      <style jsx>{`
      .matrix-background {
        position: absolute;
        inset: 0;
        overflow: hidden;
      }

      .matrix-symbol {
        position: absolute;
        color: #00ff00;
        font-size: 20px;
        opacity: 0;
        animation: matrixRain 5s linear infinite;
      }

      .hologram-container {
        position: relative;
        width: 300px;
        height: 300px;
        perspective: 1000px;
      }

      .data-stream {
        position: absolute;
        width: 2px;
        height: 100vh;
        background: linear-gradient(180deg, transparent, #00ffff, transparent);
        animation: dataStream 2s ease-out infinite;
        opacity: 0.5;
      }

      .data-stream.top {
        transform: translateX(-150px);
      }

      .data-stream.bottom {
        transform: translateX(150px);
      }

      .rank-display {
        position: relative;
        transform-style: preserve-3d;
        animation: floatHologram 4s ease-in-out infinite;
      }

      .rank-hexagon {
        width: 200px;
        height: 200px;
        position: relative;
        clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
        background: linear-gradient(45deg, #00ffff33, #ff00ff33);
        animation: rotateHexagon 10s linear infinite;
      }

      .rank-hexagon-inner {
        position: absolute;
        inset: 10px;
        clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
        background: linear-gradient(-45deg, #00ffff22, #ff00ff22);
        animation: pulseHexagon 2s ease-in-out infinite;
      }

      .rank-hexagon-scan {
        position: absolute;
        width: 100%;
        height: 4px;
        background: linear-gradient(90deg, transparent, #00ffff, transparent);
        animation: hexagonScan 2s linear infinite;
      }

      .rank-info {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: #fff;
        z-index: 1;
      }

      .rank-title {
        font-size: 2em;
        font-weight: bold;
        color: #00ffff;
        text-shadow: 0 0 10px #00ffff;
        animation: glitchText 0.3s infinite;
      }

      .rank-details {
        font-size: 1.2em;
        color: #ff00ff;
        text-shadow: 0 0 10px #ff00ff;
      }

      @keyframes matrixRain {
        0% { transform: translateY(-100%); opacity: 1; }
        100% { transform: translateY(100%); opacity: 0; }
      }

      @keyframes dataStream {
        0% { transform: translateY(-100vh); opacity: 0; }
        50% { opacity: 1; }
        100% { transform: translateY(100vh); opacity: 0; }
      }

      @keyframes floatHologram {
        0%, 100% { transform: translateY(0) rotateX(20deg); }
        50% { transform: translateY(-20px) rotateX(25deg); }
      }

      @keyframes rotateHexagon {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }

      @keyframes pulseHexagon {
        0%, 100% { opacity: 0.5; transform: scale(0.95); }
        50% { opacity: 1; transform: scale(1.05); }
      }

      @keyframes hexagonScan {
        0% { top: 0; opacity: 0; }
        50% { opacity: 1; }
        100% { top: 100%; opacity: 0; }
      }

      @keyframes glitchText {
        0% { transform: translate(0); }
        25% { transform: translate(-2px, 2px); }
        50% { transform: translate(2px, -2px); }
        75% { transform: translate(-2px, -2px); }
        100% { transform: translate(0); }
      }
    `}</style>
    </div>
  );

  const updateUser = async (updatedData) => {
    try {
      const response = await axios.put(`/api/user/${userData._id}`, updatedData);
      setGlobalUser(response.data);
      setUserData(response.data);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

// Обработчик выбора бренда
const handleBrandSelect = useCallback(async (selectedBrand, index) => {
  console.log('Handling brand selection:', {
    selectedBrand,
    index,
    userLocation,
    userDemographics: {
      gender: userData.gender,
      ageRange: userData.ageRange
    }
  });

  if (remainingBattles > 0 && !isTimerRunning) {
    setSelectedBrandIndex(index);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }

      const otherBrandIndex = index === 0 ? 1 : 0;
      const otherBrand = selectedBrands[otherBrandIndex];

      if (!otherBrand) {
        throw new Error('No competitor brand found');
      }

      // Получаем IP пользователя
      const userIp = await fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => data.ip)
        .catch(() => null);

      console.log('Sending comparison request:', {
        winnerBrandId: selectedBrand._id,
        loserBrandId: otherBrand._id,
        userCountry: userLocation,
        ipCountry: userLocation, // Используем текущую локацию пользователя
        demographicData: {
          gender: userData.gender,
          ageRange: userData.ageRange
        }
      });

      const response = await axios.post('/api/brands/compare', {
        winnerBrandId: selectedBrand._id,
        loserBrandId: otherBrand._id,
        userCountry: userLocation,
        ipCountry: userLocation,
        demographicData: {
          gender: userData.gender,
          ageRange: userData.ageRange
        }
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'X-Telegram-Init-Data': localStorage.getItem('telegramInitData')
        }
      });

      console.log('Brand comparison response:', response.data);

      const { winnerBrand, loserBrand, updatedUser } = response.data;

      // Выбираем новую пару брендов, исключая текущие
      const nextFilteredBrands = getFilteredBrands().filter(b =>
        b._id !== selectedBrand._id && b._id !== otherBrand._id
      );

      if (nextFilteredBrands.length >= 2) {
        setTimeout(() => {
          const nextNewBrands = getTwoRandomBrands(nextFilteredBrands);
          setSelectedBrands(nextNewBrands);
          startTimer();
        }, 500);
      } else {
        console.warn('Not enough brands for next selection');
        toast.warning("Not enough brands available for the next battle.", {
          duration: 3000,
          position: 'top-center',
        });
      }

      // Сбрасываем индекс выбранного бренда
      setSelectedBrandIndex(null);

      // Обновляем статистику бренда
      setBrandStats(prevStats => ({
        ...prevStats,
        [selectedBrand._id]: {
          ...prevStats[selectedBrand._id],
          totalBattles: winnerBrand.totalComparisons,
          wins: winnerBrand.totalWins,
          rating: winnerBrand.rating,
          demographics: winnerBrand.demographics
        }
      }));

      // Обновляем состояния пользователя
      if (updatedUser) {
        setUserData(prevData => ({
          ...prevData,
          coins: updatedUser.coins,
          battles: updatedUser.battles,
          availableBattles: updatedUser.availableBattles,
          level: updatedUser.level
        }));

        setGlobalUser(prevUser => ({
          ...prevUser,
          coins: updatedUser.coins,
          battles: updatedUser.battles,
          availableBattles: updatedUser.availableBattles,
          level: updatedUser.level
        }));

        setRemainingBattles(updatedUser.availableBattles);
        setScore(updatedUser.coins);
        setBattlesWon(prevBattlesWon => prevBattlesWon + 1);

        window.dispatchEvent(new CustomEvent('balanceUpdate', {
          detail: { coins: updatedUser.coins }
        }));
      }

      // Обновляем общую статистику
      setStats(prevStats => ({
        battlesWon: (prevStats.battlesWon || 0) + 1,
        brandsFaced: [...(prevStats.brandsFaced || []), selectedBrand],
        demographics: {
          ...(prevStats.demographics || {}),
          gender: {
            ...(prevStats.demographics?.gender || {}),
            [userData.gender]: (prevStats.demographics?.gender?.[userData.gender] || 0) + 1
          },
          ageRange: {
            ...(prevStats.demographics?.ageRange || {}),
            [userData.ageRange]: (prevStats.demographics?.ageRange?.[userData.ageRange] || 0) + 1
          }
        }
      }));

    } catch (error) {
      console.error('Error in brand selection:', error);
      console.error('Error details:', error.response?.data);

      toast.error(error.response?.data?.message || "An error occurred during brand comparison. Please try again.", {
        duration: 3000,
        position: 'top-center',
      });

      setSelectedBrandIndex(null);
    }
  } else if (isTimerRunning) {
    toast.info("Please wait for the timer to finish before selecting a brand.", {
      duration: 3000,
      position: 'top-center',
    });
  } else {
    toast.info("No battles available. Please wait for battles to reset.", {
      duration: 3000,
      position: 'top-center',
    });
  }
}, [remainingBattles, userLocation, getFilteredBrands, getTwoRandomBrands, selectedBrands, isTimerRunning, startTimer, setGlobalUser, userData]);

  useEffect(() => {
    console.log('remainingBattles updated:', remainingBattles);
  }, [remainingBattles]);

  useEffect(() => {
    const currentLevel = calculateLevel(battlesWon);
    const battlesForCurrentLevel = currentLevel === 1 ? 0 : 1000 + (currentLevel - 2) * 200;
    const battlesForNextLevel = currentLevel === 1 ? 1000 : 1000 + (currentLevel - 1) * 200;
    const battlesInCurrentLevel = battlesWon - battlesForCurrentLevel;
    const progress = Math.min(100, Math.max(0, (battlesInCurrentLevel / (battlesForNextLevel - battlesForCurrentLevel)) * 100));
    setLevelProgress(progress);
    setLevel(currentLevel);
  }, [battlesWon]);

  const calculateLevel = (battles) => {
    if (battles < 1000) return 1;
    return Math.floor((battles - 1000) / 200) + 2;
  };

  useEffect(() => {
    localStorage.setItem('remainingBattles', remainingBattles.toString());
  }, [remainingBattles]);

  useEffect(() => {
    const savedState = localStorage.getItem('userState');
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      setScore(parsedState.score);
      setBattlesWon(parsedState.battlesWon);
      setRemainingBattles(parsedState.remainingBattles);
      setLevel(parsedState.level);
    }
  }, []);

  const handleRankUpgrade = async (rank) => {
    console.log('Attempting to upgrade rank:', rank);
    if (canActivateRank(rank)) {
      try {
        console.log('Sending request to update rank:', {
          rank: rank.name,
          coins: score - rank.requiredCoins,
          battleLimit: rank.battleLimit
        });
        const response = await axios.put('/api/users/me/update-rank', {
          rank: rank.name,
          coins: score - rank.requiredCoins,
          battleLimit: rank.battleLimit
        }, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        });

        console.log('Received response:', response.data);

        if (response.data.success) {
          const updatedUser = response.data.user;
          console.log('Rank upgrade successful, updating state with:', updatedUser);

          // Обновляем все состояния пользователя
          setUserData(updatedUser);
          setScore(updatedUser.coins);
          setRemainingBattles(updatedUser.availableBattles);
          setBattleLimit(updatedUser.battleLimit);
          setCurrentRank(rank.name);

          // Обновляем фоновое изображение
          if (rank.backgroundImage) {
            console.log('Updating background image to:', rank.backgroundImage);
            setBackgroundImage(rank.backgroundImage);
          } else {
            console.warn('No background image found for rank:', rank.name);
          }

          // Анимация и уведомление
          setShowUpgradeAnimation(true);
          setTimeout(() => setShowUpgradeAnimation(false), 5000);
          toast.success("Rank upgraded successfully!", {
            duration: 3000,
            position: 'top-center',
          });
        } else {
          console.log('Rank upgrade not successful:', response.data);
          toast.error(response.data.message || "Failed to upgrade rank", {
            duration: 3000,
            position: 'top-center',
          });
        }
      } catch (error) {
        console.error('Error upgrading rank:', error);
        console.error('Error details:', error.response ? error.response.data : 'No response data');
        toast.error(error.response?.data?.message || "Failed to upgrade rank. Please try again.", {
          duration: 3000,
          position: 'top-center',
        });
      }
    } else {
      console.log('Cannot activate rank:', rank);
      toast.error("Cannot activate this rank at the moment.", {
        duration: 3000,
        position: 'top-center',
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [categoriesResponse, ranksResponse, tasksResponse, faqsResponse, countriesResponse, referralsResponse] = await Promise.all([
          axios.get('/api/categories'),
          axios.get('/api/ranks'),
          axios.get('/api/tasks'),
          axios.get('/api/faq'),
          axios.get('/api/countries'),
          axios.get('/api/referrals')
        ]);

        console.log('Fetched referrals:', referralsResponse.data);
        const sortedCategories = categoriesResponse.data.sort((a, b) => a.order - b.order);
        setCategories(sortedCategories);
        setRanks(ranksResponse.data);
        setTasks(tasksResponse.data);
        setFaqs(faqsResponse.data);
        setCountries(countriesResponse.data);
        setReferrals(referralsResponse.data);

      } catch (error) {
        console.error('Ошибка получения данных:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log('Current referrals state:', referrals);
  }, [referrals]);

  useEffect(() => {
    const checkAndUpdateBattles = async () => {
      try {
        const response = await axios.post('/api/users/update-battles', {}, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        });
        if (response.data.success) {
          setRemainingBattles(response.data.availableBattles);
          setBattleLimit(response.data.battleLimit);
        }
      } catch (error) {
        console.error('Error updating battles:', error);
      }
    };

    checkAndUpdateBattles();

    // Проверяем обновление каждый час
    const interval = setInterval(checkAndUpdateBattles, 60 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const savedRemainingBattles = localStorage.getItem('remainingBattles');
    if (savedRemainingBattles) {
      setRemainingBattles(parseInt(savedRemainingBattles, 10));
    }
  }, []);

  const getTopBrands = (limit = 5) => {
    const brandCounts = stats.brandsFaced.reduce((acc, brand) => {
      const brandName = brand.name;
      acc[brandName] = (acc[brandName] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(brandCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, limit)
      .map(([brand, count]) => ({ brand, count }));
  };

  const handleTaskCompletion = (task) => {
    if (task.type === 'referral') {
      setIsReferralDialogOpen(true);
    } else {
      setRemainingBattles(prev => prev + task.battleReward);
    }
  };

  const copyReferralLink = () => {
    console.log('copyReferralLink function called');
    console.log('Current referralLink:', referralLink);

    if (!referralLink) {
      console.log('Referral link is not available');
      toast.error("Referral link is not available", {
        duration: 3000,
        position: 'top-center',
      });
      return;
    }

    const textArea = document.createElement("textarea");
    textArea.value = referralLink;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand('copy');
      console.log('Referral link copied successfully');
      toast.success("Referral link copied!", {
        duration: 3000,
        position: 'top-center',
      });
    } catch (err) {
      console.error('Error during copy operation:', err);
      toast.error("Failed to copy link", {
        duration: 3000,
        position: 'top-center',
      });
    }
    document.body.removeChild(textArea);
  };

  const handleBrandInfo = (brand) => {
    setSelectedBrandInfo(brand);
  };

  const LeaderboardTable = ({ data, period, handlePeriodChange }) => {
    // Кэшируем аватары для предотвращения моргания
    const [avatarCache, setAvatarCache] = useState({});
    const [loadedData, setLoadedData] = useState([]);

    // Обработка ошибок загрузки аватара
    const handleAvatarError = (userId) => {
      setAvatarCache(prev => ({
        ...prev,
        [userId]: '/default-avatar.png' // Путь к дефолтному аватару
      }));
    };

    // Ограничиваем количество пользователей до 100
    useEffect(() => {
      const limitedData = data.slice(0, 100);
      setLoadedData(limitedData);

      // Предзагрузка аватаров
      limitedData.forEach(user => {
        if (user.avatar && !avatarCache[user._id]) {
          const img = new Image();
          img.onload = () => {
            setAvatarCache(prev => ({
              ...prev,
              [user._id]: user.avatar
            }));
          };
          img.onerror = () => handleAvatarError(user._id);
          img.src = user.avatar;
        }
      });
    }, [data]);

    const handleTabChange = (value) => {
      if (navigator.vibrate) {
        navigator.vibrate(50);
      }
      handlePeriodChange(value);
    };

    const renderLeaderboard = (users) => (
      <div className="space-y-2">
        {data.map((user, index) => (
          <div key={user._id} className="flex items-center justify-between py-2 border-b border-black border-opacity-20">
            <div className="flex items-center">
              <span className="w-6 text-black font-bold mr-2">
                {index + 1}
              </span>
              <Avatar className="w-8 h-8 mr-2 bg-gray-200">
                {user.avatar ? (
                  <AvatarImage
                    src={user.avatar}
                    alt={user.name}
                    className="object-cover"
                  />
                ) : (
                  <BoringAvatar
                    size={32}
                    name={user.name || 'User'}
                    variant="beam"
                    colors={["#6c788e", "#a6aec1", "#cfd5e1", "#ededf2", "#fcfdff"]}
                  />
                )}
                <AvatarFallback>
                  <BoringAvatar
                    size={32}
                    name={user.name || 'User'}
                    variant="beam"
                    colors={["#6c788e", "#a6aec1", "#cfd5e1", "#ededf2", "#fcfdff"]}
                  />
                </AvatarFallback>
              </Avatar>
              <span className="text-black">{user.name}</span>
            </div>
            <div className="flex items-center">
              <span className="text-black font-bold mr-1">{user.coins.toLocaleString()}</span>
              <img src="/images/general/coin.svg" alt="coin" className="w-4 h-4" />
            </div>
          </div>
        ))}
      </div>
    );

    return (
      <Tabs defaultValue={period} onValueChange={handleTabChange}>
        <div className="space-y-2">
          <TabsList className="w-full grid grid-cols-3 bg-black rounded-[30px] p-1">
            <TabsTrigger
              value="week"
              className="text-white data-[state=active]:bg-[#e0ff89] data-[state=active]:text-black rounded-[30px] transition-colors hover:text-[#e0ff89]"
            >
              Week
            </TabsTrigger>
            <TabsTrigger
              value="month"
              className="text-white data-[state=active]:bg-[#e0ff89] data-[state=active]:text-black rounded-[30px] transition-colors hover:text-[#e0ff89]"
            >
              Month
            </TabsTrigger>
            <TabsTrigger
              value="allTime"
              className="text-white data-[state=active]:bg-[#e0ff89] data-[state=active]:text-black rounded-[30px] transition-colors hover:text-[#e0ff89]"
            >
              All Time
            </TabsTrigger>
          </TabsList>
          <TabsContent value="week" className="mt-2">
            {renderLeaderboard(loadedData)}
          </TabsContent>
          <TabsContent value="month" className="mt-2">
            {renderLeaderboard(loadedData)}
          </TabsContent>
          <TabsContent value="allTime" className="mt-2">
            {renderLeaderboard(loadedData)}
          </TabsContent>
        </div>
      </Tabs>
    );
  };

  const renderLeaderboard = (data) => (
    <div className="space-y-2">
      {data.map((user, index) => (
        <div key={user._id} className="flex items-center justify-between py-2 border-b border-black border-opacity-20">
          <div className="flex items-center">
            <span className="w-6 text-black font-bold mr-2">
              {index + 1}
            </span>
            <Avatar className="w-8 h-8 mr-2">
              <AvatarImage src={user.avatar} alt={user.name} />
              <AvatarFallback>{user.name[0]}</AvatarFallback>
            </Avatar>
            <span className="text-black">{user.name}</span>
          </div>
          <div className="flex items-center">
            <span className="text-black font-bold mr-1">{user.coins.toLocaleString()}</span>
            <img src="/images/general/coin.svg" alt="coin" className="w-4 h-4" />
          </div>
        </div>
      ))}
    </div>
  );

  const fetchLeaderboardData = async (period) => {
    try {
      const response = await axios.get(`/api/users/leaderboard/${period}`);
      setLeaderboardData(response.data);
    } catch (error) {
      console.error('Error fetching leaderboard data:', error);
      toast.error("Failed to fetch leaderboard data", {
        duration: 3000,
        position: 'top-center',
      });
    }
  };

  const handlePeriodChange = (period) => {
    setLeaderboardPeriod(period);
    fetchLeaderboardData(period);
  };

  useEffect(() => {
    fetchLeaderboardData(leaderboardPeriod);
  }, []);

  const handleAddBrand = () => {
    // Логика добавления бренда
    console.log('Adding brand');
  };

  const handleAddTask = () => {
    // Логика добавления задачи
    console.log('Adding task');
  };

  const toggleProfile = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  const handleTicketPurchase = async (ticketId) => {
    try {
      console.log('Attempting to purchase ticket:', ticketId);
      const token = localStorage.getItem('token');
      const telegramInitData = localStorage.getItem('telegramInitData');
      console.log('Token:', token ? 'Present' : 'Missing');
      console.log('Telegram Init Data:', telegramInitData ? 'Present' : 'Missing');

      const response = await axios.post(`/api/tickets/${ticketId}/purchase`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-Telegram-Init-Data': telegramInitData
        }
      });

      console.log('Purchase response:', response.data);

      if (response.data.success) {
        const { user, ticket } = response.data;
        setUserData(prevData => ({
          ...prevData,
          coins: user.coins,
          tickets: user.tickets
        }));
        setScore(user.coins);

        setTickets(prevTickets => prevTickets.map(t =>
          t._id === ticket._id ? { ...t, soldTickets: ticket.soldTickets, totalTickets: ticket.totalTickets } : t
        ));

        toast.success('Ticket successfully purchased!', {
          duration: 3000,
          position: 'top-center',
        });
        return true;
      } else {
        console.error('Unexpected response structure:', response.data);
        throw new Error(response.data.message || 'Failed to purchase ticket');
      }
    } catch (error) {
      console.error('Error purchasing ticket:', error);

      if (error.response && error.response.data && error.response.data.message) {
        if (error.response.data.message === 'Insufficient coins') {
          toast.error('Not enough coins to buy a ticket', {
            duration: 3000,
            position: 'top-center',
          });
        } else if (error.response.data.message === 'All tickets are sold out') {
          toast.error('All tickets are sold out', {
            duration: 3000,
            position: 'top-center',
          });
        } else {
          console.error('Unexpected error:', error.response.data.message);
        }
      } else {
        console.error('Unexpected error:', error);
      }

      return false;
    }
  };

  useEffect(() => {
    const handleBalanceUpdate = async (event) => {
      console.log('Balance update event received:', event);
      const token = localStorage.getItem('token');
      const telegramInitData = localStorage.getItem('telegramInitData');

      if (!token) {
        console.error('No token found');
        return;
      }

      try {
        console.log('Fetching updated user data...');

        // Затем получаем обновлённые данные пользователя
        const response = await axios.post('/api/users/auth',
          { telegramInitData },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );

        console.log('Auth response:', response.data);
        const updatedUser = response.data.user;

        // Обновляем все необходимые состояния
        setUserData(prevData => {
          const newData = {
            ...prevData,
            ...updatedUser,
            coins: updatedUser.coins,
            // Добавляем проверку на наличие нового аватара в событии
            avatar: event.detail?.avatar || updatedUser.avatar
          };
          // Обновляем localStorage
          localStorage.setItem('user', JSON.stringify(newData));
          return newData;
        });

        // Обновляем глобальное состояние пользователя с учетом аватара
        setGlobalUser(prevUser => ({
          ...prevUser,
          ...updatedUser,
          avatar: event.detail?.avatar || updatedUser.avatar
        }));

        // Обновляем локальные состояния
        setScore(updatedUser.coins);
        setBattlesWon(updatedUser.battles || 0);
        setRemainingBattles(updatedUser.availableBattles || 0);
        setLevel(updatedUser.level || 1);

        console.log('Updated user state:', {
          coins: updatedUser.coins,
          battles: updatedUser.battles,
          availableBattles: updatedUser.availableBattles,
          level: updatedUser.level,
          avatar: event.detail?.avatar || updatedUser.avatar
        });
      } catch (error) {
        console.error('Error updating balance:', error);
        if (error.response) {
          console.error('Error response:', error.response.data);
        }
      }
    };

    // Подписываемся на событие обновления баланса
    window.addEventListener('balanceUpdate', handleBalanceUpdate);

    // Подписываемся на событие обновления пользователя
    window.addEventListener('userUpdate', handleBalanceUpdate);

    return () => {
      // Отписываемся от обоих событий при размонтировании
      window.removeEventListener('balanceUpdate', handleBalanceUpdate);
      window.removeEventListener('userUpdate', handleBalanceUpdate);
    };
  }, [setGlobalUser, setUserData, setScore, setBattlesWon, setRemainingBattles, setLevel]);

  useEffect(() => {
    // Собираем все URL изображений
    const imagesToPreload = [
      ...(ranks?.map(rank => rank.backgroundImage) || []),
      ...(videoTasks?.map(task => task.backgroundImage) || []),
      ...(socialTasks?.map(task => task.backgroundImage) || []),
      ...(specialTasks?.map(task => task.backgroundImage) || []),
      ...(referrals?.map(referral => referral.backgroundImage) || []),
      ...(tickets?.map(ticket => ticket.backgroundImage) || []), // Добавляем изображения билетов
      '/images/backgrounds/pass-background.jpg', // Добавляем фоновое изображение для Pass
      '/images/backgrounds/ticket-default.jpg' // Добавляем дефолтное изображение для билетов
    ].filter(Boolean);

    // Предзагружаем первые 10 изображений для оптимизации
    const priorityImages = imagesToPreload.slice(0, 10);

    return () => {
      // Cleanup если нужен
    };
  }, [ranks, videoTasks, socialTasks, specialTasks, referrals, tickets]);

  return (
    <div className="flex flex-col min-h-screen overflow-x-hidden text-white relative">
      <PreloadImages
        images={[
          ...(ranks?.map(rank => rank.backgroundImage) || []),
          ...(videoTasks?.map(task => task.backgroundImage) || []),
          ...(referrals?.map(referral => referral.backgroundImage) || [])
        ].filter(Boolean).slice(0, 10)} // Берем только первые 10 изображений
      />
      <AnimatedBackground />
      <div className="w-full relative p-4">
        <div className="flex flex-col justify-between p-4 bg-cover bg-center relative rounded-[30px] overflow-hidden" style={{
          backgroundImage: `url(${backgroundImage || '/default-background.jpg'})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}>
          <div className="absolute inset-0 bg-black opacity-60"></div>
<div className="relative z-10 flex justify-between items-start w-full mb-4">
  <div className="flex items-center space-x-4">
    <div className="relative group overflow-visible">
      {/* Основной контейнер аватара */}
      <div className="relative w-16 h-16">
        <Avatar className="w-full h-full !overflow-visible">
          {userData?.avatar ? (
            <>
              <AvatarImage
                src={userData.avatar}
                alt="User Avatar"
                className="object-cover w-full h-full"
                onError={(e) => {
                  console.error('Error loading avatar:', e);
                  e.target.style.display = 'none';
                }}
              />
              {/* Бейдж для верифицированного профиля */}
              {userData.profileCompletionReward?.claimed && (
                <div className="absolute -top-2 -right-2 w-7 h-7 bg-[#e0ff89] rounded-full flex items-center justify-center transform rotate-12 z-20 shadow-lg shadow-black/50 border-2 border-black/20">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="transform -rotate-12"
                  >
                    <path d="M18 12.5V10a2 2 0 0 0-2-2v0a2 2 0 0 0-2 2v1.4" />
                    <path d="M14 11V9a2 2 0 1 0-4 0v2" />
                    <path d="M10 10.5V5a2 2 0 1 0-4 0v9" />
                    <path d="m7 15-1.76-1.76a2 2 0 0 0-2.83 2.82l3.6 3.6C7.5 21.14 9.2 22 12 22h2a8 8 0 0 0 8-8V7a2 2 0 1 0-4 0v5" />
                  </svg>
                </div>
              )}
            </>
          ) : (
            <div className="w-full h-full">
              <BoringAvatar
                size={64}
                name={userData?.displayName || userData?.firstName || userData?.username || 'User'}
                variant="beam"
                colors={["#6c788e", "#a6aec1", "#cfd5e1", "#ededf2", "#fcfdff"]}
              />
            </div>
          )}
          <AvatarFallback>
            <div className="w-full h-full">
              <BoringAvatar
                size={64}
                name={userData?.displayName || userData?.firstName || userData?.username || 'User'}
                variant="beam"
                colors={["#6c788e", "#a6aec1", "#cfd5e1", "#ededf2", "#fcfdff"]}
              />
            </div>
          </AvatarFallback>
        </Avatar>
      </div>
    </div>
              <div className="flex-grow">
                <h1 className="text-2xl font-bold text-white">
                  {userData.displayName || userData.firstName || userData.username || 'User'}
                </h1>
                <p className="text-sm text-gray-300 mt-1">{currentRank || 'Kettle'}</p>
                <p className="text-xl font-bold text-white flex items-center mt-1">
                  {score !== undefined ? (
                    <>
                      {Math.floor(score)}
                      <img src="/images/general/coin.svg" alt="coin" className="w-6 h-6 ml-1" />
                    </>
                  ) : '0'}
                </p>
              </div>
            </div>
            <Button className="w-10 h-10 rounded-full p-0 overflow-hidden bg-transparent" onClick={toggleProfile}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
              </svg>
            </Button>
          </div>
          <div className="relative z-10 w-full bg-black rounded-full h-3 mt-2">
            <div className="absolute left-0 top-0 bottom-0 bg-[#e0ff89] rounded-full transition-all duration-300 ease-in-out" style={{ width: `${levelProgress}%` }}></div>
            <div className="absolute inset-0 flex items-center justify-center text-xs font-medium">
              <span className="text-white mix-blend-difference">Level {userData.level}: {Math.floor(levelProgress)}%</span>
            </div>
          </div>
        </div>
      </div>
      <div className={`fixed inset-0 bg-black z-[60] transition-transform duration-300 ease-in-out ${isProfileOpen ? 'translate-y-0' : 'translate-y-full'}`}>
        <div className="h-full overflow-y-auto scrollbar-hide">
          <div className="flex justify-between items-center p-4">
            <h2 className="text-xl font-bold text-white">My Account</h2>
            <Button onClick={toggleProfile} className="text-white">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </Button>
          </div>
          <UserProfile
            user={userData}
            onProfileUpdate={(updatedUser) => {
              setUserData(updatedUser);
              // Создаем событие для обновления UI
              window.dispatchEvent(new CustomEvent('userUpdate', {
                detail: {
                  avatar: updatedUser.avatar
                }
              }));
            }}
          />
        </div>
      </div>
      <div className="max-w-screen-xl mx-auto px-4">
        <CardContent className="space-y-4 pt-0">
          <Dialog open={isCountryDialogOpen} onOpenChange={setIsCountryDialogOpen}>
            <DialogContent className="bg-black rounded-[30px] p-6">
              <DialogHeader>
                <DialogTitle className="text-white">Select Country</DialogTitle>
              </DialogHeader>
              <div className="space-y-4">
                <Button
                  className={`w-full h-6 flex items-center justify-center space-x-2 rounded-[30px] transition-colors ${selectedCountry === 'Global'
                    ? 'bg-[#e0ff89] text-black'
                    : 'bg-zinc-800 text-white hover:bg-[#e0ff89] hover:text-black'
                    }`}
                  onClick={() => {
                    handleCountryChange('Global');
                    setIsCountryDialogOpen(false);
                  }}
                >
                  <span role="img" aria-label="Globe">🌐</span>
                  <span>Global</span>
                </Button>
                <div className="grid grid-cols-2 gap-4">
                  {availableCountries.map((country) => (
                    <Button
                      key={country.code}
                      className={`flex items-center space-x-2 rounded-[30px] transition-colors h-6 ${selectedCountry === country.code
                        ? 'bg-[#e0ff89] text-black'
                        : 'bg-zinc-800 text-white hover:bg-[#e0ff89] hover:text-black'
                        }`}
                      onClick={() => {
                        handleCountryChange(country.code);
                        setIsCountryDialogOpen(false);
                      }}
                    >
                      <ReactCountryFlag countryCode={country.code} svg />
                      <span>{country.name}</span>
                    </Button>
                  ))}
                </div>
              </div>
            </DialogContent>
          </Dialog>
          <div
            className={`fixed inset-0 z-50 ${isCategoryDialogOpen ? '' : 'pointer-events-none'}`}
          >
            <div
              className={`fixed bottom-0 left-0 right-0 bg-black rounded-t-3xl overflow-hidden flex flex-col`}
              style={{
                transform: isCategoryDialogOpen ? 'translateY(0)' : 'translateY(100%)',
                transition: 'transform 0.3s ease-in-out',
                height: 'calc(100% - 4rem)'
              }}
            >
              <div className="h-6 w-full bg-black cursor-grab active:cursor-grabbing drag-handle">
                <div className="h-1 w-16 bg-white rounded-full mx-auto mt-2"></div>
              </div>
              <div className="flex-1 overflow-y-auto px-4 py-2 scrollbar-hide">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-2xl font-bold text-white">Categories</h2>
                  <button
                    onClick={() => setIsCategoryDialogOpen(false)}
                    className="text-white cursor-pointer focus:outline-none"
                  >
                    <X size={20} />
                  </button>
                </div>
                <div className="relative pb-24">
                  <div className="space-y-[-60px]">
                    {Object.entries(categories)
                      .sort(([, a], [, b]) => a.order - b.order)
                      .map(([categoryId, category]) => {
                        const isExpanded = selectedMainCategory === category._id;  // Изменено здесь
                        return (
                          <div
                            key={category._id}  // Используем _id вместо categoryId
                            className="relative transition-all duration-300 ease-in-out"
                            style={{
                              zIndex: category.order + 1,
                            }}
                          >
                            <div
                              className={`rounded-[30px] transition-all duration-300 ease-in-out cursor-pointer ${isExpanded ? '' : 'pb-12'
                                }`}
                              style={{
                                backgroundColor: category.color,
                                color: category.textColor,
                                boxShadow: '0 -7px 20px 0 rgba(0, 0, 0, 0.3)',
                              }}
                            >
                              <div
                                className="flex justify-between items-center p-6"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleCategorySelect(category);  // Передаем весь объект категории
                                }}
                              >
                                <div className="flex items-center space-x-3">
                                  {(() => {
                                    const IconComponent = category.icon ? LucideIcons[category.icon] : LucideIcons.Folder;
                                    return <IconComponent size={48} />;
                                  })()}
                                  <div>
                                    <h3 className="font-bold text-2xl">{category.name}</h3>
                                    <p className="text-base">
                                      {category.subCategories.length} Subcategories
                                    </p>
                                  </div>
                                </div>
                                <ChevronRight
                                  size={20}
                                  className={`transition-transform duration-300 ${isExpanded ? 'rotate-90' : ''
                                    }`}
                                />
                              </div>
                              <div
                                className={`overflow-hidden transition-all duration-300 ease-in-out ${isExpanded ? 'max-h-[1000px]' : 'max-h-0'
                                  }`}
                              >
                                <div className="space-y-2 p-4 pt-0 pb-20">
                                  {category.subCategories.map((subCategory, subIndex) => (
                                    <div key={subIndex} className="space-y-2">
                                      <div
                                        className="bg-white bg-opacity-50 rounded-[30px] pl-4 pb-2 pr-4 pt-2 text-base cursor-pointer"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setSelectedSubCategory(subCategory.name);
                                          setSelectedSubSubCategory('');
                                        }}
                                      >
                                        <div className="flex justify-between items-center">
                                          <span>{subCategory.name}</span>
                                          {subCategory.subSubCategories &&
                                            subCategory.subSubCategories.length > 0 && (
                                              <ChevronDown
                                                size={16}
                                                className={`transition-transform duration-300 ${selectedSubCategory === subCategory.name ? 'rotate-180' : ''
                                                  }`}
                                              />
                                            )}
                                        </div>
                                      </div>

                                      {/* Под-подкатегории */}
                                      {selectedSubCategory === subCategory.name &&
                                        subCategory.subSubCategories &&
                                        subCategory.subSubCategories.length > 0 && (
                                          <div className="pl-4 space-y-1">
                                            {subCategory.subSubCategories.map((subSubCategory, subSubIndex) => (
                                              <div
                                                key={subSubIndex}
                                                className={`bg-white bg-opacity-30 rounded-[30px] pl-4 pb-2 pr-4 pt-2 text-sm cursor-pointer ${selectedSubSubCategory === subSubCategory.name
                                                  ? 'bg-opacity-70'
                                                  : ''
                                                  }`}
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleSubSubCategorySelect(subSubCategory);
                                                }}
                                              >
                                                {subSubCategory.name}
                                              </div>
                                            ))}
                                          </div>
                                        )}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center mt-0">
            <h2 className="text-2xl font-bold text-white">Battle Brands</h2>
          </div>
          <div className="flex flex-col items-center mt-2">
            <div className="flex justify-center items-center w-full mb-4">
              {selectedBrands.slice(0, 2).map((brand, index) => (
                <React.Fragment key={brand._id}>
                  {index === 1 && (
                    <div className="flex flex-col items-center mx-4" key="vs-container">
                      <img src="/images/general/vs.svg" alt="VS" className="w-10 h-10" />
                      <div className="h-8 flex items-center justify-center">
                        {isTimerRunning && (
                          <div className={`text-lg font-bold text-white bg-black rounded-full w-6 h-6 flex items-center justify-center transition-opacity duration-500 ${showGo ? 'opacity-0' : 'opacity-100'}`}>
                            {timer > 0 ? timer : 'GO!'}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="flex flex-col items-center relative" style={{ width: 'calc(50% - 2rem)' }}>
                    <div className="relative">
                      <BrandAvatar
                        brand={brand}
                        isSelected={selectedBrandIndex === index}
                        onClick={() => handleBrandSelect(brand, index)}
                        remainingBattles={remainingBattles}
                      >
                        <Avatar className="w-24 h-24">
                          <AvatarImage
                            src={brand.logo || `https://logo.clearbit.com/${brand.name.toLowerCase().replace(/\s+/g, '')}.com`}
                            alt={brand.name}
                          />
                          <AvatarFallback>{brand.name.charAt(0)}</AvatarFallback>
                        </Avatar>
                      </BrandAvatar>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleBrandInfo(brand);
                        }}
                        className={`absolute ${index === 0 ? 'left-0' : 'right-0'} bottom-0 bg-zinc-900 rounded-full w-8 h-8 flex items-center justify-center shadow-zinc-100`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 text-white"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </button>
                    </div>
                    <div
                      className="cursor-pointer h-12 flex items-start justify-center w-full mt-2"
                    >
                      <p className="text-center font-medium text-white leading-tight line-clamp-2">
                        {brand.name}
                      </p>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
            <div className="w-full max-w-md">
              <BattleTimer
                remainingBattles={remainingBattles}
                battleLimit={battleLimit}
              />
              <div className="flex items-center mt-6">
                <Button
                  className="flex-grow bg-[#e0ff89] text-black rounded-[30px] px-4 py-2 mr-2"
                  onClick={() => setIsCategoryDialogOpen(true)}
                >
                  {selectedSubSubCategory || selectedSubCategory || selectedCategory || 'Select Category'}
                </Button>
                <Button
                  className="w-10 h-10 bg-white text-black rounded-full w-9 h-9 flex items-center justify-center"
                  onClick={() => setIsCountryDialogOpen(true)}
                >
                  {selectedCountry === 'Global' ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  ) : (
                    <ReactCountryFlag countryCode={selectedCountry} svg style={{ width: '1.5em', height: '1.5em' }} />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </CardContent>
        <Dialog open={isReferralDialogOpen} onOpenChange={setIsReferralDialogOpen}>
          <DialogContent className="bg-gray-900 text-white">
            <DialogHeader>
              <DialogTitle>Invite a Friend</DialogTitle>
              <DialogDescription className="text-gray-400">
                Share this link with your friends to invite them to Battle Brands!
              </DialogDescription>
            </DialogHeader>
            <div className="flex items-center space-x-2">
              <Input value={userData?.referralLink} readOnly className="bg-gray-800 text-white" />
              <Button onClick={copyReferralLink} className="flex items-center space-x-2 bg-gray-700 text-white hover:bg-gray-600">
                <Copy size={16} />
                <span>Copy</span>
              </Button>
            </div>
            <Button
              className="w-full mt-4 bg-white text-black hover:bg-gray-200"
              onClick={() => {
                // Логика для отправки в Telegram
              }}
            >
              <Share2 className="mr-2" size={16} />
              Share on Telegram
            </Button>
          </DialogContent>
        </Dialog>
        {showUpgradeAnimation && <UpgradeAnimation />}
      </div>
      <div
        className={`fixed inset-0 z-40 ${activeMenu ? '' : 'pointer-events-none'}`}
      >
        <div
          className={`fixed bottom-0 left-0 right-0 ${activeMenu === 'Game' ? 'bg-black top-0' : 'bg-[#e1ff8a] rounded-t-3xl'} overflow-hidden flex flex-col`}
          style={{
            transform: activeMenu ? (isDragging ? `translateY(${translateY}px)` : 'translateY(0)') : 'translateY(100%)',
            height: activeMenu === 'Game' ? '100%' : 'calc(100% - 4rem)',
            transition: isDragging ? 'none' : 'transform 0.3s ease-in-out'
          }}
        >
          <div
            className={`${activeMenu === 'Game' ? 'h-0' : 'h-6'} w-full ${activeMenu === 'Game' ? 'bg-black' : 'bg-[#e1ff8a]'} cursor-grab active:cursor-grabbing drag-handle`}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            {activeMenu !== 'Game' && <div className="h-1 w-16 bg-black rounded-full mx-auto mt-2"></div>}
          </div>
          <div className="flex-1 flex flex-col overflow-hidden">
            <div className="flex justify-between items-center mb-2 px-2">
              <h2 className={`text-2xl font-bold ${activeMenu === 'Game' ? 'text-white' : 'text-black'}`}>{activeMenu}</h2>
              <button
                onClick={() => setActiveMenu(null)}
                className={`${activeMenu === 'Game' ? 'text-white' : 'text-black'} cursor-pointer focus:outline-none`}
              >
                <X size={20} />
              </button>
            </div>
            <div className={`flex-1 overflow-y-auto px-2 pb-24 ${activeMenu === 'Game' ? 'text-white' : 'text-black'} scrollbar-hide`}>
              {activeMenu === 'Rank' && (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {ranks.map((rank) => {
                    const isActivated = rank.name === userData.rank || rank.level < userData.level;
                    const canUpgrade = !isActivated && canActivateRank(rank);
                    return (
                      <div
                        key={rank.level}
                        className="relative overflow-hidden rounded-[20px] bg-gray-800"
                        style={{
                          boxShadow: '0 10px 10px rgba(0, 0, 0, 0.5)',
                          height: '150px',
                          backgroundColor: '#1a1a1a'
                        }}
                      >
                        {/* Контейнер для изображения */}
                        <div className="absolute inset-0 w-full h-full overflow-hidden">
                          <OptimizedImage
                            src={rank.backgroundImage}
                            alt={rank.name}
                            className="absolute inset-0 w-full h-full object-cover"
                          />
                        </div>

                        {/* Темный оверлей */}
                        <div className="absolute inset-0 bg-black bg-opacity-50"></div>

                        {/* Контент */}
                        <div className="relative z-10 p-6 flex flex-col h-full">
                          <div className="flex justify-between mb-3">
                            {/* Левая колонка с названием и уровнем */}
                            <div className="flex flex-col">
                              <h3 className="text-xl font-bold text-white mb-0.5">
                                {rank.name}
                              </h3>
                              <span className="text-base text-gray-300">Level {rank.level}</span>
                            </div>

                            {/* Правая колонка с монетами и лимитом */}
                            <div className="flex flex-col items-end">
                              <div className="flex items-start mb-0.5">
                                <span className="text-xl font-bold text-white mr-1">{rank.requiredCoins}</span>
                                <img src="/images/general/coin.svg" alt="coin" className="w-5 h-5 translate-y-[4px]" />
                              </div>
                              <span className="text-base text-gray-300">Limit: {rank.battleLimit}</span>
                            </div>
                          </div>

                          <div className="mt-auto">
                            <div className="w-full bg-zinc-400 rounded-[30px] h-4 relative">
                              <div
                                className="h-4 rounded-[30px] transition-all duration-300 bg-white"
                                style={{ width: `${isActivated ? 100 : Math.min((battlesWon / rank.battlesRequired) * 100, 100)}%` }}
                              ></div>
                              <div className="absolute inset-0 flex items-center justify-center text-xs text-black font-medium">
                                {isActivated ? 'Completed' : `${battlesWon}/${rank.battlesRequired} battles`}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {activeMenu === 'Tasks' && (
                <Tabs defaultValue="referrals" className="w-full">
                  <TabsList className="w-full grid grid-cols-4 bg-black rounded-[30px] p-1">
                    <TabsTrigger
                      value="referrals"
                      onClick={handleWithVibration(() => { })}
                      className="text-white data-[state=active]:bg-[#e0ff89] data-[state=active]:text-black rounded-[30px] transition-colors hover:text-[#e0ff89]"
                    >
                      Referrals
                    </TabsTrigger>
                    <TabsTrigger
                      value="video"
                      onClick={handleWithVibration(() => { })}
                      className="text-white data-[state=active]:bg-[#e0ff89] data-[state=active]:text-black rounded-[30px] transition-colors hover:text-[#e0ff89]"
                    >
                      Video
                    </TabsTrigger>
                    <TabsTrigger
                      value="social"
                      onClick={handleWithVibration(() => { })}
                      className="text-white data-[state=active]:bg-[#e0ff89] data-[state=active]:text-black rounded-[30px] transition-colors hover:text-[#e0ff89]"
                    >
                      Social
                    </TabsTrigger>
                    <TabsTrigger
                      value="special"
                      onClick={handleWithVibration(() => { })}
                      className="text-white data-[state=active]:bg-[#e0ff89] data-[state=active]:text-black rounded-[30px] transition-colors hover:text-[#e0ff89]"
                    >
                      Special
                    </TabsTrigger>
                  </TabsList>
                  <div className="overflow-y-auto scrollbar-hide">
                    <TabsContent value="referrals" className="w-full">
                      <div className="flex justify-between mb-4">
                        <Button
                          className="flex-1 mr-2 bg-black text-white hover:bg-zinc-800 rounded-[30px] shadow-md px-4 py-2 text-sm font-medium"
                          onClick={() => {
                            console.log('Copy Link button clicked');
                            copyReferralLink();
                          }}
                        >
                          <Copy size={16} className="mr-2" />
                          <span>Copy Link</span>
                        </Button>
                        <Button
                          className="flex-1 ml-2 bg-black text-white hover:bg-zinc-800 rounded-[30px] shadow-md px-4 py-2 text-sm font-medium"
                          onClick={() => {
                            if (window.Telegram && window.Telegram.WebApp) {
                              window.Telegram.WebApp.openTelegramLink(`https://t.me/share/url?url=${encodeURIComponent(userData.referralLink)}&text=${encodeURIComponent('Join Battle Brands with my referral link!')}`);
                            } else {
                              toast.error("Telegram Web App is not available", { duration: 3000, position: 'top-center' });
                            }
                          }}
                        >
                          <Users size={16} className="mr-2" />
                          <span>Share</span>
                        </Button>
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {referrals.map((referral) => (
                          <div
                            key={referral._id}
                            className="relative overflow-hidden rounded-[20px] bg-gray-800"
                            style={{
                              boxShadow: '0 10px 10px rgba(0, 0, 0, 0.5)',
                              height: '130px',
                              backgroundColor: '#1a1a1a'
                            }}
                          >
                            {/* Контейнер для изображения */}
                            <div className="absolute inset-0 w-full h-full overflow-hidden">
                              <OptimizedImage
                                src={referral.backgroundImage}
                                alt={referral.name}
                                className="absolute inset-0 w-full h-full object-cover"
                              />
                            </div>

                            {/* Темный оверлей */}
                            <div className="absolute inset-0 bg-black bg-opacity-50"></div>

                            {/* Контент */}
                            <div className="relative z-10 p-6 flex flex-col h-full">
                              <div className="mb-3">
                                <div className="flex justify-between items-start">
                                  <div className="flex items-center">
                                    <div className="mr-2">
                                      <Users className="w-6 h-6 text-white" />
                                    </div>
                                    <h3 className="text-xl font-bold text-white">
                                      {referral.name}
                                    </h3>
                                  </div>
                                  <div className="flex items-center shrink-0 ml-2">
                                    <span className="text-xl font-bold text-white mr-1">{referral.coinsReward}</span>
                                    <img src="/images/general/coin.svg" alt="coin" className="w-5 h-5" />
                                  </div>
                                </div>
                                <p className="text-sm text-gray-300 mt-1 ml-8">
                                  {referral.description}
                                </p>
                              </div>

                              <div className="mt-auto">
                                <div className="w-full bg-zinc-400 rounded-[30px] h-4 relative">
                                  <div
                                    className="h-4 rounded-[30px] transition-all duration-300 bg-white"
                                    style={{ width: `${Math.min((userData.referrals.length || 0) / referral.requiredReferrals, 1) * 100}%` }}
                                  ></div>
                                  <div className="absolute inset-0 flex items-center justify-center text-xs text-black font-medium">
                                    {(userData.referrals.length || 0) >= referral.requiredReferrals ? (
                                      <span className="font-bold">Complete</span>
                                    ) : (
                                      `${userData.referrals.length || 0} / ${referral.requiredReferrals} referrals`
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </TabsContent>
                    <TabsContent value="video" className="w-full">
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {videoTasks.map((task) => (
                          <div
                            key={task._id}
                            className="relative overflow-hidden rounded-[20px] bg-gray-800"
                            style={{
                              boxShadow: '0 10px 10px rgba(0, 0, 0, 0.5)',
                              height: '150px', // Фиксированная высота как у пустого блока
                              backgroundColor: '#1a1a1a'
                            }}
                          >
                            {/* Контейнер для изображения */}
                            <div className="absolute inset-0 w-full h-full overflow-hidden">
                              <OptimizedImage
                                src={task.backgroundImage}
                                alt={task.name}
                                className="absolute inset-0 w-full h-full object-cover"
                              />
                            </div>

                            {/* Темный оверлей */}
                            <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                            {/* Контент */}
                            <div className="relative z-10 p-6 flex flex-col h-full">
                              <div className="mb-3">
                                <div className="flex justify-between items-start">
                                  <div className="flex items-center">
                                    <div className="mr-2">
                                      {socialIcons.YouTube && <socialIcons.YouTube className="w-6 h-6 text-white" />}
                                    </div>
                                    <h3 className="text-xl font-bold text-white">
                                      {task.name}
                                    </h3>
                                  </div>
                                  <div className="flex items-center shrink-0 ml-2">
                                    <span className="text-xl font-bold text-white mr-1">{task.coinsReward}</span>
                                    <img src="/images/general/coin.svg" alt="coin" className="w-5 h-5" />
                                  </div>
                                </div>
                                <p className="text-sm text-gray-300 mt-1 ml-8">
                                  {task.description}
                                </p>
                              </div>
                              <div className="mt-auto">
                                {task.status && !['not_started', 'in_progress', 'ready_to_claim', 'completed'].includes(task.status) && (
                                  <Button
                                    className="w-full bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4"
                                    onClick={() => handleWatchVideo(task._id, task.videoUrl)}
                                  >
                                    Watch Video
                                  </Button>
                                )}
                                {task.status === 'in_progress' && (
                                  <Button
                                    className="w-full bg-[#e0ff89] text-black rounded-[30px] py-2 px-4"
                                    disabled
                                  >
                                    <div className="flex items-center justify-center">
                                      <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-black mr-2"></div>
                                      Watching...
                                    </div>
                                  </Button>
                                )}
                                {task.status === 'ready_to_claim' && (
                                  <div className="flex items-center space-x-2">
                                    <Input
                                      type="text"
                                      placeholder="Enter Code"
                                      className={`flex-grow bg-black text-white placeholder-gray-500 rounded-[30px] py-2 px-4 h-10 ${task.codeError ? 'border-2 border-red-500' : ''}`}
                                      onChange={(e) => handleCodeInput(task._id, e.target.value)}
                                      value={taskCodes[task._id] || ''}
                                    />
                                    <Button
                                      className="bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4 h-10"
                                      onClick={() => handleClaimVideoTask(task._id)}
                                    >
                                      Claim
                                    </Button>
                                  </div>
                                )}
                                {task.status === 'completed' && (
                                  <Button
                                    className="w-full bg-[#e0ff89] text-black rounded-[30px] py-2 px-4"
                                    disabled
                                  >
                                    Completed
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </TabsContent>
                    <TabsContent value="social" className="w-full">
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {socialTasks.map((task) => (
                          <div
                            key={task._id}
                            className="relative overflow-hidden rounded-[20px] bg-gray-800"
                            style={{
                              boxShadow: '0 10px 10px rgba(0, 0, 0, 0.5)',
                              height: '150px', // Фиксированная высота как у пустого блока
                              backgroundColor: '#1a1a1a'
                            }}
                          >
                            {/* Контейнер для изображения */}
                            <div className="absolute inset-0 w-full h-full overflow-hidden">
                              <OptimizedImage
                                src={task.backgroundImage}
                                alt={task.name}
                                className="absolute inset-0 w-full h-full object-cover"
                              />
                            </div>

                            {/* Темный оверлей */}
                            <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                            {/* Контент */}
                            <div className="relative z-10 p-6 flex flex-col h-full">
                              <div className="mb-3">
                                <div className="flex justify-between items-start">
                                  <div className="flex items-center">
                                    <div className="mr-2">
                                      {getSocialIcon(task.socialPlatform) && (
                                        <div className="w-6 h-6 text-white">
                                          {getSocialIcon(task.socialPlatform)}
                                        </div>
                                      )}
                                    </div>
                                    <h3 className="text-xl font-bold text-white">
                                      {task.name}
                                    </h3>
                                  </div>
                                  <div className="flex items-center shrink-0 ml-2">
                                    <span className="text-xl font-bold text-white mr-1">{task.coinsReward}</span>
                                    <img src="/images/general/coin.svg" alt="coin" className="w-5 h-5" />
                                  </div>
                                </div>
                                <p className="text-sm text-gray-300 mt-1 ml-8">
                                  {task.description}
                                </p>
                              </div>
                              <div className="mt-auto">
                                {task.status && !['not_started', 'subscribed', 'completed'].includes(task.status) && (
                                  <Button
                                    className="w-full bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4"
                                    onClick={() => handleSubscribe(task._id)}
                                  >
                                    Subscribe to {task.socialPlatform}
                                  </Button>
                                )}
                                {task.status === 'subscribed' && (
                                  <Button
                                    className="w-full bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4"
                                    onClick={() => handleClaimSocialTask(task._id)}
                                  >
                                    Claim
                                  </Button>
                                )}
                                {task.status === 'completed' && (
                                  <Button
                                    className="w-full bg-[#e0ff89] text-black rounded-[30px] py-2 px-4"
                                    disabled
                                  >
                                    Completed
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </TabsContent>
                    <TabsContent value="special" className="w-full">
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {specialTasks.map((task) => (
                          <div
                            key={task._id}
                            className="relative overflow-hidden rounded-[20px] bg-gray-800"
                            style={{
                              boxShadow: '0 10px 10px rgba(0, 0, 0, 0.5)',
                              height: '150px', // Фиксированная высота как у пустого блока
                              backgroundColor: '#1a1a1a'
                            }}
                          >
                            {/* Контейнер для изображения */}
                            <div className="absolute inset-0 w-full h-full overflow-hidden">
                              <OptimizedImage
                                src={task.backgroundImage}
                                alt={task.name}
                                className="absolute inset-0 w-full h-full object-cover"
                              />
                            </div>

                            {/* Темный оверлей */}
                            <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                            {/* Контент */}
                            <div className="relative z-10 p-6 flex flex-col h-full">
                              <div className="mb-3">
                                <div className="flex justify-between items-start">
                                  <div className="flex items-center">
                                    <div className="mr-2">
                                      {task.taskType === 'app' ? (
                                        <Smartphone className="w-6 h-6 text-white" />
                                      ) : (
                                        <Globe className="w-6 h-6 text-white" />
                                      )}
                                    </div>
                                    <h3 className="text-xl font-bold text-white">
                                      {task.name}
                                    </h3>
                                  </div>
                                  <div className="flex items-center shrink-0 ml-2">
                                    <span className="text-xl font-bold text-white mr-1">{task.coinsReward}</span>
                                    <img src="/images/general/coin.svg" alt="coin" className="w-5 h-5" />
                                  </div>
                                </div>
                                <p className="text-sm text-gray-300 mt-1 ml-8">
                                  {task.description}
                                </p>
                              </div>
                              <div className="mt-auto">
                                {task.status === 'completed' ? (
                                  <Button
                                    className="w-full bg-[#e0ff89] text-black rounded-[30px] py-2 px-4"
                                    disabled
                                  >
                                    Completed
                                  </Button>
                                ) : task.status === 'in_progress' && task.taskType === 'web' ? (
                                  <div className="flex items-center space-x-2">
                                    <Input
                                      type="text"
                                      placeholder="Enter Code"
                                      className={`flex-grow bg-black text-white placeholder-gray-500 rounded-[30px] py-2 px-4 h-10 ${task.codeError ? 'border-2 border-red-500' : ''}`}
                                      onChange={(e) => handleCodeInput(task._id, e.target.value)}
                                      value={taskCodes[task._id] || ''}
                                    />
                                    <Button
                                      className="bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4 h-10"
                                      onClick={() => handleClaimSpecialTask(task._id)}
                                    >
                                      Claim
                                    </Button>
                                  </div>
                                ) : task.status === 'in_progress' && task.taskType === 'app' ? (
                                  <Button
                                    className="w-full bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4"
                                    onClick={() => handleClaimSpecialTask(task._id)}
                                  >
                                    Claim
                                  </Button>
                                ) : (
                                  <Button
                                    className="w-full bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4"
                                    onClick={() => handleSpecialTask(task._id)}
                                  >
                                    {task.taskType === 'app' ? 'Open App' : 'Go to Website'}
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </TabsContent>
                  </div>
                </Tabs>
              )}
              {activeMenu === 'Account' && (
                <div className="space-y-4 text-black">
                  <p className="font-medium">Your Statistics:</p>
                  <ul className="text-sm space-y-1">
                    <li>Battles won: {stats.battlesWon}</li>
                    <li>Coins earned: {score}</li>
                    <li>Referrals invited: {userData?.referralCount || 0}</li>
                  </ul>
                  <div className="h-60">
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={getTopBrands(5)}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#888" />
                        <XAxis dataKey="brand" stroke="#000" />
                        <YAxis stroke="#000" />
                        <Tooltip
                          contentStyle={{ backgroundColor: '#f0f0f0', border: 'none' }}
                          labelStyle={{ color: '#000' }}
                          itemStyle={{ color: '#000' }}
                        />
                        <Legend wrapperStyle={{ color: '#000' }} />
                        <Bar dataKey="count" fill="#000000" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              )}
              {activeMenu === 'Game' && (
                <div className="fixed inset-0 z-50 bg-black">
                  <div className="h-full flex flex-col">
                    <div className="flex justify-between items-center p-4">
                      <h2 className="text-2xl font-bold text-white">Brand Growth</h2>
                      <button onClick={() => setActiveMenu(null)} className="text-white">
                        <X size={24} />
                      </button>
                    </div>
                    <div className="flex-grow px-4 pb-4">
                      <BrandGrowthGame
                        user={userData}
                        setUser={setUserData}
                        setGlobalUser={setGlobalUser}
                        onClose={() => setActiveMenu(null)}
                        updateUserData={updateUserDataCallback}
                      />
                    </div>
                  </div>
                </div>
              )}
              {activeMenu === 'Pass' && (
                <div className="relative overflow-hidden rounded-[30px]">
                  {/* Контейнер для фона */}
                  <div className="absolute inset-0 w-full h-full overflow-hidden">
                    <OptimizedImage
                      src="/images/backgrounds/pass-background.jpg" // Здесь укажите путь к вашему фоновому изображению
                      alt="Pass background"
                      className="absolute inset-0 w-full h-full object-cover"
                      priority={false}
                    />
                    {/* Добавляем оверлей для лучшей читаемости контента */}
                    <div className="absolute inset-0 bg-[#e0ff89] bg-opacity-90"></div>
                  </div>

                  {/* Контент */}
                  <div className="relative z-10">
                    <TicketDisplay
                      user={userData}
                      tickets={tickets}
                      onPurchase={handleTicketPurchase}
                    />
                  </div>
                </div>
              )}
              {activeMenu === 'Top' && (
                <div className="bg-[#e0ff89] rounded-[30px]">
                  <LeaderboardTable
                    data={leaderboardData}
                    period={leaderboardPeriod}
                    handlePeriodChange={handlePeriodChange}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={`fixed bottom-4 left-4 right-4 ${activeMenu === 'Game' ? 'z-40' : 'z-50'}`}>
        <div className="max-w-screen-xl mx-auto main-menu rounded-[20px] p-1 flex items-center justify-between relative overflow-hidden">
          {[
            { icon: Home, label: 'Ranks', menu: 'Rank' },
            { icon: Gift, label: 'Tasks', menu: 'Tasks' },
            { icon: BarChart2, label: 'Game', menu: 'Game' },
            { icon: Ticket, label: 'Pass', menu: 'Pass' },
            { icon: Star, label: 'Top', menu: 'Top' }
          ].map(({ icon: Icon, label, menu }) => (
            <div key={menu} className="relative flex-1">
              <Button
                variant="ghost"
                onClick={() => handleMenuClick(activeMenu === menu ? null : menu)}
                className={`
      main-menu-item w-full flex flex-col items-center justify-center py-2 px-4 transition-all
      ${activeMenu === menu ? 'active' : ''}
    `}
              >
                <Icon size={24} className="menu-icon" />
                <span className="text-xs menu-text">{label}</span>
              </Button>
            </div>
          ))}
        </div>
      </div>
      <Dialog
        open={!!selectedBrandInfo}
        onOpenChange={() => setSelectedBrandInfo(null)}
      >
        <DialogContent className="bg-black rounded-[30px] p-6">
          <DialogHeader>
            <DialogTitle className="text-white flex items-center justify-between">
              <div className="flex items-center">
                <Avatar className="w-12 h-12 mr-4">
                  <AvatarImage src={selectedBrandInfo?.logo} alt={selectedBrandInfo?.name} />
                  <AvatarFallback>{selectedBrandInfo?.name[0]}</AvatarFallback>
                </Avatar>
                <div>
                  <h2 className="text-2xl font-bold text-left">{selectedBrandInfo?.name}</h2>
                  <div className="flex items-center mt-1">
                    {[1, 2, 3, 4, 5].map((star) => {
                      const rating = selectedBrandInfo?.rating || 0;
                      const fillPercentage = Math.min(100, Math.max(0, (rating - (star - 1)) * 100));
                      return (
                        <div key={star} className="relative w-4 h-4">
                          <Star className="w-4 h-4 text-gray-400" />
                          <div className="absolute top-0 left-0 overflow-hidden" style={{ width: `${fillPercentage}%` }}>
                            <Star className="w-4 h-4 text-yellow-400 fill-current" />
                          </div>
                        </div>
                      );
                    })}
                    <span className="ml-2 text-sm text-gray-400">
                      ({selectedBrandInfo?.rating?.toFixed(1) || '0.0'})
                    </span>
                  </div>
                </div>
              </div>
            </DialogTitle>
          </DialogHeader>
          <div className="text-white mt-2 space-y-4">
            <div className="flex items-center">
              <span className="font-semibold mr-2">Country:</span>
              {selectedBrandInfo?.countries && selectedBrandInfo.countries.length > 0 && (
                <span>
                  {selectedBrandInfo.countries.map((country, index) => (
                    <React.Fragment key={country.code}>
                      <ReactCountryFlag countryCode={country.code} svg className="mr-1" />
                      <span>{country.name}</span>
                      {index < selectedBrandInfo.countries.length - 1 && ', '}
                    </React.Fragment>
                  ))}
                </span>
              )}
            </div>
            <div className="space-y-1">
              {selectedBrandInfo?.categories && selectedBrandInfo.categories.length > 0 && (
                selectedBrandInfo.categories.map((cat, index) => (
                  <div key={cat.category._id} className="flex items-center space-x-2">
                    <span className="font-semibold min-w-24">Category:</span>
                    <div className="flex items-center">
                      <span className="text-[#e0ff89]">{cat.category.name}</span>
                      {cat.subCategory && (
                        <>
                          <ChevronRight className="w-4 h-4 mx-1 text-gray-400" />
                          <span className="text-[#e0ff89]">{cat.subCategory}</span>
                        </>
                      )}
                      {cat.subSubCategory && (
                        <>
                          <ChevronRight className="w-4 h-4 mx-1 text-gray-400" />
                          <span className="text-[#e0ff89]">{cat.subSubCategory}</span>
                        </>
                      )}
                    </div>
                  </div>
                ))
              )}
            </div>
            <div>
              <span className="font-semibold">Description: </span>
              <p className="text-gray-300">{selectedBrandInfo?.description}</p>
            </div>
            {selectedBrandInfo?.socialMedia && selectedBrandInfo.socialMedia.length > 0 && (
              <div>
                <span className="font-semibold">Social: </span>
                <div className="flex space-x-4 mt-2">
                  {selectedBrandInfo.socialMedia.map(social => {
                    const Icon = socialIcons[social.platform];
                    return Icon ? (
                      <Button
                        key={social.platform}
                        onClick={() => handleSocialMediaClick(selectedBrandInfo._id, social.platform, social.url)}
                        className="text-gray-400 hover:text-white transition-colors"
                      >
                        <Icon size={24} />
                      </Button>
                    ) : null;
                  })}
                </div>
              </div>
            )}
            {selectedBrandInfo?.website && (
              <div className="mt-4">
                <Button
                  onClick={() => handleWebsiteClick(selectedBrandInfo._id, selectedBrandInfo.website)}
                  className="w-full bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4 text-center font-bold transition-colors"
                >
                  Go to {selectedBrandInfo.name}
                </Button>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default BrandBattle;