import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, CardContent } from "../ui/card";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "../ui/dialog";
import { Pencil, Trash2, GripVertical } from "lucide-react";
import axios from 'axios';

const TaskForm = ({ task, onSave, onCancel }) => {
  const [formData, setFormData] = useState(task || {
    name: '',
    category: '',
    description: '',
    reward: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <Label htmlFor="name" className="text-[#d3d3d3]">Task Name</Label>
        <Input
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          className="bg-[#15171c] text-[#d3d3d3]"
        />
      </div>
      <div>
        <Label htmlFor="category" className="text-[#d3d3d3]">Category</Label>
        <Input
          id="category"
          name="category"
          value={formData.category}
          onChange={handleChange}
          required
          className="bg-[#15171c] text-[#d3d3d3]"
        />
      </div>
      <div>
        <Label htmlFor="description" className="text-[#d3d3d3]">Description</Label>
        <Input
          id="description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          className="bg-[#15171c] text-[#d3d3d3]"
        />
      </div>
      <div>
        <Label htmlFor="reward" className="text-[#d3d3d3]">Reward</Label>
        <Input
          id="reward"
          name="reward"
          value={formData.reward}
          onChange={handleChange}
          required
          type="number"
          className="bg-[#15171c] text-[#d3d3d3]"
        />
      </div>
      <div className="flex justify-end space-x-2">
        <Button type="button" onClick={onCancel} variant="outline" className="bg-black text-white rounded-[30px] py-1 px-4">Cancel</Button>
        <Button type="submit" className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4">Save Task</Button>
      </div>
    </form>
  );
};

const TasksManagement = () => {
  const { category } = useParams();
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editingTask, setEditingTask] = useState(null);
  const [tabName, setTabName] = useState('Tasks');

  useEffect(() => {
    fetchTasks();
  }, [category]);

  const fetchTasks = async () => {
  try {
    const response = await axios.get('/api/tasks', { params: { category } });
    setTasks(response.data);
  } catch (error) {
    console.error('Error fetching tasks:', error);
  }
};

useEffect(() => {
    if (category === 'referrals') {
      navigate('/admin/tasks/referrals', { replace: true });
      return;
    }

    fetchTasks();
    const storedTabName = localStorage.getItem('tasksTabName') || 'Tasks';
    setTabName(storedTabName);
  }, [category, navigate]);

  if (category === 'referrals') {
    return null;
  }

  const handleSave = async (taskData) => {
    try {
      let response;
      if (editingTask) {
        response = await axios.put(`/api/tasks/${editingTask._id}`, taskData);
      } else {
        response = await axios.post('/api/tasks', taskData);
      }
      console.log('Task saved:', response.data);
      fetchTasks();
      setIsDialogOpen(false);
      setEditingTask(null);
    } catch (error) {
      console.error('Error saving task:', error.response ? error.response.data : error.message);
    }
  };

  const handleDelete = async (taskId) => {
    try {
      await axios.delete(`/api/tasks/${taskId}`);
      fetchTasks();
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  const handleTabNameChange = (e) => {
    const newTabName = e.target.value;
    setTabName(newTabName);
    localStorage.setItem('tasksTabName', newTabName);
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(tasks);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setTasks(items);

    try {
      await axios.put('/api/tasks/reorder', { tasks: items.map(task => task._id) });
    } catch (error) {
      console.error('Error reordering tasks:', error);
      fetchTasks(); // Revert to original order if there's an error
    }
  };

  return (
    <Card className="bg-transparent">
      <CardContent className="p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-[#eff0f2]">Tasks</h2>
          {category ? `${category.charAt(0).toUpperCase() + category.slice(1)} Tasks` : 'All Tasks'}
          <Button onClick={() => setIsDialogOpen(true)} className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4">Add New Task</Button>
        </div>
        <div className="mb-4">
          <Label htmlFor="tabName" className="text-[#d3d3d3]">Tab Name on Main Page</Label>
          <Input
            id="tabName"
            value={tabName}
            onChange={handleTabNameChange}
            className="bg-[#15171c] text-[#d3d3d3]"
            placeholder="Enter tab name"
          />
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="tasks">
            {(provided) => (
              <Table {...provided.droppableProps} ref={provided.innerRef}>
                <TableHeader>
                  <TableRow>
                    <TableHead className="text-[#d3d3d3]">Order</TableHead>
                    <TableHead className="text-[#d3d3d3]">Name</TableHead>
                    <TableHead className="text-[#d3d3d3]">Category</TableHead>
                    <TableHead className="text-[#d3d3d3]">Reward</TableHead>
                    <TableHead className="text-[#d3d3d3]">Actions</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {tasks.map((task, index) => (
                    <Draggable key={task._id} draggableId={task._id} index={index}>
                      {(provided) => (
                        <TableRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="hover:bg-black"
                        >
                          <TableCell><GripVertical size={16} className="text-[#d3d3d3]" /></TableCell>
                          <TableCell className="text-[#d3d3d3]">{task.name}</TableCell>
                          <TableCell className="text-[#d3d3d3]">{task.category}</TableCell>
                          <TableCell className="text-[#d3d3d3]">{task.reward}</TableCell>
                          <TableCell>
                            <div className="flex space-x-2">
                              <Button onClick={() => { setEditingTask(task); setIsDialogOpen(true); }} className="p-2 bg-black text-white rounded-[30px]">
                                <Pencil size={16} />
                              </Button>
                              <Button onClick={() => handleDelete(task._id)} className="p-2 bg-black text-white rounded-[30px]">
                                <Trash2 size={16} />
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                </TableBody>
              </Table>
            )}
          </Droppable>
        </DragDropContext>
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogContent className="bg-[#15171c]">
            <DialogHeader>
              <DialogTitle className="text-[#eff0f2]">{editingTask ? 'Edit Task' : 'Add New Task'}</DialogTitle>
              <DialogDescription className="text-[#d3d3d3]">
                {editingTask ? 'Edit the details of the task.' : 'Enter the details of the new task.'}
              </DialogDescription>
            </DialogHeader>
            <TaskForm
              task={editingTask}
              onSave={handleSave}
              onCancel={() => setIsDialogOpen(false)}
            />
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default TasksManagement;