import React, { useState, useEffect } from 'react';
import axios from 'axios';

const DrawResults = ({ ticketId }) => {
    const [results, setResults] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchResults = async () => {
            try {
                const response = await axios.get(`/api/draws/${ticketId}`);
                setResults(response.data);
            } catch (err) {
                setError('Failed to fetch draw results');
            } finally {
                setLoading(false);
            }
        };

        fetchResults();
    }, [ticketId]);

    if (loading) return <div className="text-center py-4">Loading results...</div>;
    if (error) return <div className="text-center py-4 text-red-500">{error}</div>;
    if (!results || results.status !== 'completed') return <div className="text-center py-4">Results will be available soon</div>;

    return (
        <div className="mt-4 bg-black bg-opacity-50 rounded-[20px] p-4">
            <h3 className="text-xl font-bold text-white mb-4">Draw Results</h3>
            <ul className="space-y-2">
                {results.winners.map((winner, index) => (
                    <li key={index} className="flex justify-between items-center text-white">
                        <span>{winner.user.username}</span>
                        <span>
                            {winner.prize.type === 'money'
                                ? `${winner.prize.value} USDT`
                                : `${winner.prize.quantity}x ${winner.prize.value}`
                            }
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default DrawResults;