import React, { useEffect, useState } from 'react';
import { TonConnectUIProvider, TonConnectButton, useTonAddress, useTonWallet } from '@tonconnect/ui-react';
import { Card, CardHeader, CardTitle, CardContent } from "./ui/card";
import { Wallet } from 'lucide-react'; // Добавляем иконку кошелька
import axios from 'axios';

const WalletContent = ({ userId }) => {
  const userAddress = useTonAddress();
  const wallet = useTonWallet();
  const [balance, setBalance] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  // Функция для очистки данных кошелька при отключении
  const handleDisconnect = async () => {
    try {
      await axios.post('/api/users/disconnect');
      setBalance(null);
      setLastUpdate(null);
      console.log('Wallet disconnected and data cleared');
    } catch (error) {
      console.error('Error disconnecting wallet:', error);
    }
  };

  // Функция проверки необходимости обновления
  const shouldUpdate = () => {
    if (!lastUpdate) return true;
    const timeSinceLastUpdate = Date.now() - new Date(lastUpdate).getTime();
    const oneDayInMs = 24 * 60 * 60 * 1000;
    return timeSinceLastUpdate > oneDayInMs;
  };

  // Обновляем информацию о кошельке
  const updateWallet = async (force = false) => {
    if (!userAddress || !userId || !wallet || (isUpdating && !force)) return;

    if (!force && !shouldUpdate()) {
      console.log('Skipping update - less than 24 hours passed');
      return;
    }

    try {
      setIsUpdating(true);
      const response = await axios.post('/api/users/update-wallet', {
        userId,
        walletAddress: userAddress
      });

      if (response.data?.tonWallet?.balance) {
        setBalance(response.data.tonWallet.balance);
        setLastUpdate(new Date().toISOString());
      }
    } catch (error) {
      console.error('Error updating wallet:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  // Следим за изменением состояния подключения
  useEffect(() => {
    if (!wallet && balance !== null) {
      handleDisconnect();
    }
  }, [wallet]);

  // Обновляем информацию о кошельке только когда он подключен
  useEffect(() => {
    if (wallet && userAddress && userId) {
      updateWallet();
    }
  }, [wallet, userAddress, userId]);

  return (
    <Card className="bg-[#1a1a1a] text-white rounded-[20px] overflow-hidden">
      <CardHeader className="bg-[#282828] px-6 py-4">
        <CardTitle className="text-xl font-bold flex items-center gap-2">
          <Wallet className="w-5 h-5 text-[#e0ff89]" />
          Connect TON Wallet
        </CardTitle>
      </CardHeader>
      <CardContent className="p-6">
        <p className="text-gray-400 text-sm mb-4">
          Connect your TON wallet to receive rewards and pay for products
        </p>

        {wallet && balance !== null && (
          <div className="mb-4">
            <p className="text-[#e0ff89] text-sm">Balance:</p>
            <div className="flex items-center gap-2 mt-1">
              <p className="text-xl font-bold text-white">
                {balance.toFixed(2)} TON
              </p>
              <button
                onClick={() => updateWallet(true)}
                className="bg-white text-black px-4 py-1 h-[34px] hover:bg-gray-200 disabled:opacity-50 text-sm rounded-[30px]"
                disabled={isUpdating}
              >
                {isUpdating ? 'Updating...' : 'Update'}
              </button>
            </div>
            {lastUpdate && (
              <p className="text-gray-500 text-sm mt-2">
                Last updated: {new Date(lastUpdate).toLocaleString()}
              </p>
            )}
          </div>
        )}

        <div className="w-full inline-flex">
          <TonConnectButton />
        </div>
      </CardContent>
    </Card>
  );
};

const TonWallet = ({ userId }) => {
  return (
    <TonConnectUIProvider manifestUrl="https://battlebrands.app/tonconnect-manifest.json">
      <WalletContent userId={userId} />
    </TonConnectUIProvider>
  );
};

export default TonWallet;